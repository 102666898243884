Controller = ($rootScope, $uibModal, $translate, $window, DriverCard, DriverNew) ->
  "ngInject"

  consumer = null
  self = this

  @prevPage = ->
    self.filters.page = self.pagination.prev_page
    self.load()

  @nextPage = ->
    self.filters.page = self.pagination.next_page
    self.load()

  @load = ->
    DriverCard.query self.filters, (driver_cards, responseHeaders) ->
      self.driver_cards = driver_cards

      self.filters.page = parseInt(responseHeaders()['x-current-page']) if responseHeaders()['x-current-page']?

      self.pagination = {
        total_count: parseInt(responseHeaders()['x-total-count']),
        next_page: responseHeaders()['x-next-page'],
        prev_page: responseHeaders()['x-prev-page'],
      }
      self.pagination.first = (self.filters.page - 1) * self.filters.limit + 1
      self.pagination.last = self.pagination.first + driver_cards.length - 1

  @$onInit = ->
    self.filters = {
      page: 1
      limit: 25
    }
    self.load()

  @edit = (driver_card) ->
    modalInstance = $uibModal.open
      animation: true,
      component: 'driverCardModal'
      size: 'md',
      resolve:
        driver_card: -> DriverCard.get(id: driver_card.id).$promise

    modalInstance.result.then ->
      self.load()

  @getDrivers = (q) ->
    DriverNew.query('q[fullname_cont]': q).$promise

  @remove = (index) ->
    $translate("Sure?").then (title) =>
      if $window.confirm(title)
        DriverCard.delete id: self.driver_cards[index].id, ->
          self.driver_cards.splice(index, 1)
 
  this
 
angular.module 'logisticsApp'
  .component 'driverCardsList',
    template: require('app/driver_cards/list/list.html')
    controller: Controller

  .config ($stateProvider, $urlRouterProvider) ->
    'ngInject'
    $stateProvider
      .state 'app.driverCards',
        url: '/driverCards'
        component: 'driverCardsList'
        data:
          permissions:
            only: 'listDriverCards'
