TimelineController = (Comment, ActionCableChannel, Upload, $filter, $attrs) ->
  "ngInject"

  self = this

  @showGallery = (comment_index, index) ->
    Comment.get id: self.comments[comment_index].id, (comment) ->
      self.preview_slides = comment.attachments.map (a) ->
        item =
          id: a.id
          title: a.title

        unless a.deleted
          if a.print_url
            item.printUrl = a.print_url

          if a.download_url
            item.downloadUrl = a.download_url

          if a.pdf_url
            item.html = "<div class='text-center'><iframe src='#{a.pdf_url}' style='margin-top: 44px; width: 90vw; height: 100vh;'></iframe></div>"
          else if a.office_url
            item.html = "<div class='text-center'><iframe style='margin-top: 44px; width: 90vw; height: 100vh;' src='https://docs.google.com/viewer?url=#{a.office_url}&w=800&embedded=true'></iframe></div>"
          else if a.preview_url
            item.src = a.preview_url
            item.w = a.width
            item.h = a.height
        else
          item.title += " (#{$filter('translate')('File was deleted')})"

        item

      self.preview_opts.index = index
      self.preview_opened = true
    false

  @closeGallery = ->
    self.preview_opened = false

  @$onInit = ->
    self.body = ''
    self.files = []
    self.preview_opened = false
    self.preview_slides = []
    self.preview_opts =
      index: 0
      history: false
      pinchToClose: false
      closeOnScroll: false

    if $attrs.holder

      if self.holder.$promise
        self.holder.$promise.then ->
          if self.holder.comments
            self.total = self.holder.comments.total
            self.comments = self.holder.comments.items
            self.nextPage = 2 if self.total > self.comments.length
          else
            self.comments = []
            self.loadNext()
      else
        if self.holder.comments
          self.comments = self.holder.comments.items
        else
          self.comments = []
          self.loadNext()

      self.consumer = new ActionCableChannel("CommentsChannel", holder_type: self.holderType, holder_id: self.holder.id)
      self.consumer.subscribe(self.cableCallback)

    else
      Comment.query (comments, responseHeaders) ->
        self.total = parseInt(responseHeaders()['x-total-items'])
        self.comments = comments
        self.nextPage = 2 if self.total > self.comments.length

        self.consumer = new ActionCableChannel("CommentsChannel")
        self.consumer.subscribe(self.cableCallback)

  @$onDestroy = ->
    self.consumer.unsubscribe() if self.consumer

  @loadNext = ->
    holder_id = if self.holder then self.holder.id else null
    Comment.query page: self.nextPage, holder_type: self.holderType,  holder_id: holder_id, (comments) ->
      self.comments = self.comments.concat(comments)
      if self.total > self.comments.length
        self.nextPage += 1
      else
        self.nextPage = null

  @cableCallback = (message) ->
    if message.event == 'create'
      Comment.get id: message.id, (comment) ->
        self.comments.unshift(comment)
        self.total += 1
    else if message.event == 'update'
      comment = $filter('filter')(self.comments, id: message.id)[0]
      if comment
        comment.$get()

  @download = (comment, index) ->
    attachment = comment.attachments[index]
    el = angular.element(document.querySelector('#download-frame'))
    el.prop('src', attachment.download_url)
    false

  @save = ->
    self.uploading = true

    Upload.upload(
      url: '/api/comments.json',
      data:
        note: self.body,
        comment_holders_attributes: [
          {holder_type: self.holderType,  holder_id: self.holder.id}
        ]
        comment_attachments_attributes: self.files.map (f) -> { attachment_attributes: { file: f, attachmentable_type: self.holderType, attachmentable_id: self.holder.id } }
    ).then((resp) ->
      self.body = ''
      self.files = []
    , (err) ->
      self.errors = err.data.errors['comment_attachments.attachment.file']
    ).finally ->
      self.uploading = false

  this

angular.module 'logisticsApp'
  .component 'timeline',
    template: require('app/common/components/timeline/timeline.html')
    controller: TimelineController
    bindings:
      holder: '<?'
      holderType: '@?'
