import "app/settings/permissions/permissions.component";
import "app/settings/notification_subscriptions/notification_subscriptions.component";
import "app/settings/templates/templates.component";
import "app/settings/templates/new/new.component";
import "app/settings/templates/table-field/table-field.component";
import "app/settings/info_contacts/list/list.component";
import "app/settings/info_contacts/modal/modal.component";
import "app/settings/exchange_rates/list/list.component";

Controller = ->
  "ngInject"

  @groups = [
    {
      sref: 'app.settings.permissions',
      name: 'Permissions'
    },
    {
      sref: 'app.settings.garages',
      name: 'Garages'
    }
  ]

  this
 
angular.module 'logisticsApp'
  .config ($stateProvider, $urlRouterProvider) ->
    'ngInject'
    $stateProvider
      .state 'app.settings',
        url: '/settings',
        abstract: true
        data:
          permissions:
            only: 'readSettings'

  .component 'settings',
    template: require('app/settings/settings.html')
    controller: Controller
