Controller = ($rootScope, $uibModal, $translate, $window, $http, $auth, $httpParamSerializer, $filter, PhoneCall, ActionCableChannel) ->
  "ngInject"

  consumer = null
  self = this

  @prevPage = ->
    self.filters.page = self.pagination.prev_page
    self.load()

  @nextPage = ->
    self.filters.page = self.pagination.next_page
    self.load()

  @load = ->
    PhoneCall.query self.filters, (phone_calls, responseHeaders) ->
      self.phone_calls = phone_calls
      self.pagination = {
        total_count: parseInt(responseHeaders()['x-total-count']),
        next_page: responseHeaders()['x-next-page'],
        prev_page: responseHeaders()['x-prev-page'],
      }
      self.pagination.first = (self.filters.page - 1) * self.filters.limit + 1
      self.pagination.last = self.pagination.first + phone_calls.length - 1

  callback = (message) ->
    if message.event == 'create'
      return unless self.filters.page == 1

      PhoneCall.get id: message.resource_id, (phone_call) ->
        self.phone_calls.unshift(phone_call)

    else if message.event == 'update'
      phone_call = $filter('filter')(self.phone_calls, id: message.resource_id)[0]

      if phone_call
        phone_call.$get()

  @$onInit = ->
    self.kinds = ['income', 'outcome', 'finished', 'only_online', 'missed']
    self.filter_by_kind = 'income'
    self.filters = {
      page: 1,
      limit: 25,
      'q[income]': true
    }
    self.load()

    consumer = new ActionCableChannel("PhoneCallsChannel")
    consumer.subscribe(callback)

  @$onDestroy = ->
    consumer.unsubscribe() if consumer

  @listen = (index) ->
    phone_call = self.phone_calls[index]

    PhoneCall.recording id: phone_call.id, (recording) ->
      phone_call.recording_url = recording.link

  @kindChanged = ->
    for k in self.kinds
      delete self.filters["q[#{k}]"]

    if self.filter_by_kind
      self.filters["q[#{self.filter_by_kind}]"] = true

    self.load()

  this
 
angular.module 'logisticsApp'
  .component 'phoneCallsList',
    template: require('app/phone_calls/list/list.html')
    controller: Controller

  .config ($stateProvider, $urlRouterProvider) ->
    'ngInject'
    $stateProvider
      .state 'app.phone_calls',
        url: '/phone_calls'
        component: 'phoneCallsList'
        data:
          permissions:
            only: 'readPhoneCalls'

