angular.module 'logisticsApp'
  .factory "Partner", (RailsResource, railsSerializer, $translate) ->
    class Partner extends RailsResource
      @configure
        url: "/api/partners"
        name: "partner"
        serializer: railsSerializer ->
          @exclude('attachments')
          @nestedAttribute('attachments')
          @resource('attachments', 'Attachment')
          @add 'attachment_ids', (partner) ->
            if partner.attachments
              partner.attachments.map (attachment) -> attachment.id
