angular.module 'logisticsApp'
  .controller 'ShowDocumentController', ($scope, $stateParams, $state, $filter, $uibModal, $translate, $window, toaster, Document, Client, Driver, Vehicle) ->
    'ngInject'

    id = $stateParams.id

    $scope.document = {attachments: []}

    Document.get(id, type: $stateParams.type).then (document) ->
      $scope.document = document

    $scope.removeDocument = ->
      $translate("Sure?").then (title) ->
        if $window.confirm(title)
          $scope.document.remove().then ->
            $scope.goBack()

    $scope.print = ->
      windowUrl = $scope.document.printUrl()
      windowName = 'Print' + $scope.document.title

      printWindow = window.open(windowUrl, windowName, 'left=50000,top=50000,width=0,height=0')

      printWindow.document.close()
      printWindow.focus()
      printWindow.print()
      false

    $scope.sendmail = ->
      modalInstance = $uibModal.open
        animation: true,
        template: require('app/components/emails/send.html'),
        controller: 'SendEmailController',
        size: 'lg',
        resolve:
          attachments: -> $scope.document.attachments

      modalInstance.result.then ->
        $translate('Sent successfuly').then (t) ->
          toaster.pop('success', t, 'OK')

      false

  .config ($stateProvider, $urlRouterProvider) ->
    'ngInject'
    $stateProvider
      .state 'app.documents.show',
        url: '/show/:id?type'
        template: require('app/documents/show.html')
        controller: 'ShowDocumentController'

