angular.module('logisticsApp').directive('star', function () {
	return {
		template: '<ul class="rating" ng-mouseleave="leave()">' +
		'<li ng-repeat="star in stars" ng-class="star" ng-click="click($index + 1)" ng-mouseover="over($index + 1)">' +
		'\u2605' +
		'</li>' +
		'</ul>',
		scope: {
			ratingValue: '=',
			max: '=',
			readonly: '@',
			onHover: '=?',
			onLeave: '=?',
			onChange: '&'
		},
		controller: function($scope){
			$scope.ratingValue = $scope.ratingValue || 0;
			$scope.max = $scope.max || 5;
			$scope.click = function(val){
				if ($scope.readonly && $scope.readonly === 'true') {
					return;
				}
				$scope.ratingValue = val;
				$scope.onChange({ratingValue: val});
			};
			$scope.over = function(val){
				if ($scope.onHover) {
					$scope.onHover(val);
				}
			};
			$scope.leave = function(){
				if ($scope.onLeave) {
					$scope.onLeave();
				}
			}
		},
		link: function (scope, elem, attrs) {
			elem.css("text-align", "center");
			var updateStars = function () {
				scope.stars = [];
				for (var i = 0; i < scope.max; i++) {
					scope.stars.push({
						filled: i < scope.ratingValue
					});
				}
			};
			updateStars();

			scope.$watch('ratingValue', function (oldVal, newVal) {
				if (newVal) {
					updateStars();
				}
			});
			scope.$watch('max', function (oldVal, newVal) {
				if (newVal) {
					updateStars();
				}
			});
		}
	};
});
