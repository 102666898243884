angular.module 'logisticsApp'
  .factory "Attachment", (RailsResource) ->
    class Attachment extends RailsResource
      @configure
        url: "/api/attachments"
        name: 'attachment'

      url: ->
        "/api/attachments/#{@id}/download"
      view: ->
        "/api/attachments/#{@id}/view"
      thumbnail: ->
        "/api/attachments/#{@id}/thumbnail"

