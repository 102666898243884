angular.module 'logisticsApp'
  .controller 'RefillsController', ($scope, $filter, $http, $translate, toaster, $q, $auth, Refill, FuelCard, Vehicle, FuelProvider, FileUploader) ->
    'ngInject'

    $scope.fuel_providers = FuelProvider.query()

    $scope.vehicles = []
    $scope.end_at = moment().toDate()
    $scope.start_at = moment([moment().year(), moment().month()]).toDate()
    $scope.status =
      opened1: false
      opened2: false
      formOpened: false
    $scope.openInForm = ($event) ->
      $scope.status.formOpened = true

    Vehicle.query().then (vehicles) ->
      $scope.vehicles = vehicles

    $scope.selectManufacturer = (item_id) ->
      $scope.fuel_provider_id = item_id

      $scope.load()

    $scope.selectVehicle = (item, id) ->
      $scope.vehicle_id = id
      load()
      true

    $scope.editRefill = (index, item) ->
      $scope.refillIndex = index
      unless item.fuelCardNumber?
        $scope.refill = new Refill(item)
        $scope.refill.at = moment(item.at).toDate()
      else
        closeForm()

    newRefill = $scope.newRefill = ->
      unless $scope.refill?
        $scope.refill = new Refill(at: new Date(), total: 0.0)
      else
        $scope.refill.id = null
        $scope.refill.vehicleId = null
        $scope.refill.amount = null
        $scope.refill.at = moment($scope.refill.at).toDate()

    $scope.setRefillVehicle = (item, model) ->
      $scope.refill.vehicleId = model

    $scope.saveRefill = ->
      updating = $scope.refill.id?

      created = ->
        if updating
          $translate(['Updated successfuly',"Refill updated"]).then (t) ->
            toaster.pop('success', t['Updated successfuly'], t['Refill updated'])

        else
          newRefill()
          $translate(['Created successfuly',"You can add next one"]).then (t) ->
            toaster.pop('success', t['Created successfuly'], t['You can add next one'])

        for fuel_provider in $scope.fuel_providers
          if fuel_provider.balance
            fuel_provider.$get()

        load()

      failed = ->
        $translate(['Error',"Can't save refill"]).then (t) ->
          toaster.pop('error', t['Error'], t["Can't save refill"])

      $scope.refill.save().then created, failed

    closeForm = $scope.closeForm = ->
      $scope.refill = null

    $scope.removeRefill = ->
      $scope.refill.remove().then ->
        load()

        $translate('Deleted successfuly').then (t) ->
          toaster.pop('sucess', t)

    load = $scope.load = ->
      Refill.query(
        vehicle_id: $scope.vehicle_id
        fuel_provider_id: $scope.fuel_provider_id
        start_at: moment($scope.start_at).format('DD/MM/YYYY')
        end_at: moment($scope.end_at).format('DD/MM/YYYY')
      ).then (resp) ->
        $scope.refills = resp.data.map (attrs) -> new Refill(attrs)
        $scope.summary = resp.summary

    load()
