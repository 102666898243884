Controller = ($rootScope, $uibModal, $translate, $http, $window) ->
  "ngInject"

  self = this

  @$onInit = ->
    self.purse_balances = []

    $http.get('/api/vehicles.json', params: { 'q[free]': true }).then (response) ->
      self.vehicles = response.data

  this
 
angular.module 'logisticsApp'
  .component 'dashboardVehicles',
    template: require('app/dashboard/vehicles/vehicles.html')
    controller: Controller
