Controller = ($translate, $window, VehicleNew, NgMap, ActionCableChannel) ->
  "ngInject"

  self = this
  consumer = null

  @load = ->
    VehicleNew.map self.params, (items) ->
      self.trackers = items
      self.select(items[0])

  @$onInit = ->
    self.params = {}

    NgMap.getMap().then (m) ->
      self.map = m
      self.load()

    consumer = new ActionCableChannel("TrackersChannel")
    consumer.subscribe (data) ->
      VehicleNew.map self.params, (items) ->
        self.trackers = items

  @$onDestroy = ->
    consumer.unsubscribe() if consumer

  @select = (tracker, center = true) ->
    angular.forEach self.trackers, (t) ->
      t.selected = false

    tracker.selected = true
    self.selected = tracker

    if self.map and center
      self.map.setCenter(new google.maps.LatLng(tracker.latitude, tracker.longitude))
      self.map.setZoom(5)

  @mouseover = ->
    this.style.zIndex = 1000
    $(this).addClass('hover')

  @mouseout = ->
    this.style.zIndex = null
    $(this).removeClass('hover')

  @selectMarker = ->
    for t in self.trackers
      if t.id == $(this).find('.tracker').data().trackerId
        self.select(t, false)

  this

angular.module 'logisticsApp'
  .component 'vehicleTrackingMap',
    template: require('app/vehicle_tracking_map/vehicle_tracking_map.html')
    controller: Controller

  .config ($stateProvider) ->
    "ngInject"

    $stateProvider.state 'app.map',
      url: '/map'
      component: 'vehicleTrackingMap'
      data:
        permissions:
          only: 'seeMap'

