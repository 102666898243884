import "app/common/services/exchange_rate.service";
import "app/settings/exchange_rates/modal/modal.component";

Controller = ($rootScope, $uibModal, $translate, $window, ExchangeRate) ->
  "ngInject"

  consumer = null
  self = this

  @prevPage = ->
    self.filters.page = self.pagination.prev_page
    self.load()

  @nextPage = ->
    self.filters.page = self.pagination.next_page
    self.load()

  @load = ->
    params = angular.copy(self.filters)
    if params['q[date_eq]']?
      params['q[date_eq]'] = moment(params['q[date_eq]']).format('YYYY-MM-DD')

    ExchangeRate.query params, (exchange_rates, responseHeaders) ->
      self.exchange_rates = exchange_rates

      self.filters.page = parseInt(responseHeaders()['x-current-page']) if responseHeaders()['x-current-page']?

      self.pagination = {
        total_count: parseInt(responseHeaders()['x-total-count']),
        next_page: responseHeaders()['x-next-page'],
        prev_page: responseHeaders()['x-prev-page'],
      }
      self.pagination.first = (self.filters.page - 1) * self.filters.limit + 1
      self.pagination.last = self.pagination.first + exchange_rates.length - 1

  @$onInit = ->
    self.filters = {
      page: 1
      limit: 25
    }
    self.load()

  @edit = (exchange_rate) ->
    modalInstance = $uibModal.open
      animation: true,
      component: 'exchangeRateModal'
      size: 'md',
      resolve:
        exchange_rate: -> ExchangeRate.get(id: exchange_rate.id).$promise

    modalInstance.result.then ->
      self.load()

  this
 
angular.module 'logisticsApp'
  .component 'exchangeRatesList',
    template: require('app/settings/exchange_rates/list/list.html')
    controller: Controller

  .config ($stateProvider, $urlRouterProvider) ->
    'ngInject'

    $stateProvider
      .state 'app.settings.exchange_rates',
        url: '/exchange_rates'
        component: 'exchangeRatesList'
