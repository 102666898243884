Controller = ($rootScope, $translate, $window, $http, VehicleNew) ->
  "ngInject"

  self = this

  @$onInit = ->
    self.vehicle = self.resolve.vehicle

    VehicleNew.query (vehicles) ->
      self.vehicles = vehicles

  @cancel = ->
    self.dismiss({$value: 'cancel'})

  @save = ->
    VehicleNew.delete id: self.vehicle.id, target_vehicle_id: self.target_vehicle_id, ->
      self.close({$value: self.target_vehicle_id})

  this
 
angular.module 'logisticsApp'
  .component 'removeVehicleModal',
    template: require('app/vehicles/remove/remove.html')
    controller: Controller
    bindings:
      resolve: '<'
      close: '&'
      dismiss: '&'
