angular.module 'logisticsApp'
  .controller 'IncomeController', ($scope, $uibModalInstance, Operation, spare) ->
    'ngInject'

    $scope.spare = spare
    $scope.item = new Operation(kind: 'income', spareId: spare.id)

    $scope.save = ->
      $scope.item.save().then ->
        $uibModalInstance.close($scope.item)

    $scope.cancel = ->
      $uibModalInstance.dismiss('cancel')
