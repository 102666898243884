Controller = ($rootScope, $uibModal, $translate, $window, CemtLicence, ActionCableChannel) ->
  "ngInject"

  consumer = null
  self = this

  @load = ->
    self.licences = CemtLicence.query(self.filters)

  callback = (message) ->
    self.load()

  @$onInit = ->
    self.filters = {}
    self.load()

    consumer = new ActionCableChannel("CemtLicencesChannel")
    consumer.subscribe(callback)

  @edit = (licence) ->
    modalInstance = $uibModal.open
      animation: true,
      component: 'cemtLicenceModal'
      size: 'lg',
      resolve:
        licence: -> CemtLicence.get(id: licence.id).$promise

  @transfer = (licence, event) ->
    event.stopPropagation()

    modalInstance = $uibModal.open
      animation: true,
      component: 'transferCemtLicenceModal'
      size: 'sm',
      resolve:
        licence: -> CemtLicence.get(id: licence.id).$promise

    false

  @free = (licence, event) ->
    event.stopPropagation()

    $translate("Sure?").then (title) =>
      if $window.confirm(title)
        CemtLicence.update(id: licence.id, vehicle_id: null)

    false



  this
 
angular.module 'logisticsApp'
  .component 'cemtLicences',
    template: require('app/cemt_licences/list/list.html')
    controller: Controller
    bindings:
      staticParams: '<'

  .config ($stateProvider, $urlRouterProvider) ->
    'ngInject'
    $stateProvider
      .state 'app.cemtLicences',
        url: '/cemt_licences'
        component: 'cemtLicences'
        data:
          permissions:
            only: 'listCemtLicences'
