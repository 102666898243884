Controller = (PartnerNew, Trip, ActionCableChannel) ->
  "ngInject"

  self = this

  @update = (field_name) ->
    attrs = {}
    attrs[field_name] = self.partner[field_name]

    PartnerNew.update id: self.partner.id, attrs

  @loadTrips = (params) ->
    params['q[partner_id_eq]'] = self.partner.id
    Trip.query(params)

  this

angular.module 'logisticsApp'
  .config ($stateProvider) ->
    "ngInject"

    $stateProvider.state 'app.partners.show',
      url: '/{id}/show'
      component: 'partnersShow'
      resolve:
        partner: ($stateParams, PartnerNew) ->
          "ngInject"
          PartnerNew.get(id: $stateParams.id).$promise


  .component 'partnersShow',
    template: require('app/partners/show/show.html')
    controller: Controller
    bindings:
      partner: '<'
