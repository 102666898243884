Controller = ($rootScope, $translate, $window, $filter, toaster, Account) ->
  "ngInject"

  self = this

  @$onInit = ->
    self.params = {
      trip_id: self.resolve.tripId
    }

  @cancel = ->
    self.dismiss({$value: 'cancel'})

  @save = ->
    self.inProgress = true
    Account.update id: self.params.id, trip_id: self.params.trip_id, (updated_account) ->
      toaster.pop('info', $filter('translate')('Account attached'), 'OK')

      self.close({$value: updated_account.id})
      self.inProgress = false
    , (response) ->
      self.inProgress = false
      toaster.pop('error', $filter('translate')('Account updating failed'), 'OK')

  @refreshAccounts = (q) ->
    Account.query 'q[number_cont]': q, (accounts) ->
      self.accounts = accounts

  this
 
angular.module 'logisticsApp'
  .component 'attachAccountModal',
    template: require('app/trips/trip/attach-account/attach-account.html')
    controller: Controller
    bindings:
      resolve: '<'
      close: '&'
      dismiss: '&'
