import "app/dashboard/purses/purses.component";
import "app/dashboard/vehicles/vehicles.component";

angular.module 'logisticsApp'
  .component 'dashboard',
    template: require('app/dashboard/dashboard.html')

  .config ($stateProvider, $urlRouterProvider) ->
    'ngInject'
    $stateProvider
      .state 'app.dashboard',
        url: '/dashboard'
        component: 'dashboard'
        data:
          permissions:
            only: 'seeDashboard'
