angular.module 'logisticsApp'
  .factory "Refill", (RailsResource, railsSerializer, $translate) ->
    class Refill extends RailsResource
      @configure
        url: "/api/refills"
        name: "refill"
        serializer: railsSerializer ->
          @exclude('totalKopiykas', 'totalCurrency','fuelCard','vehicle')
          @add 'at', (document) ->
            moment(document.at).format("YYYY-MM-DD")

      @checkAt = (data, id) ->
        if data == "" or !data?
          return $translate("validators.blank")
        if moment(data) <= moment()
          return $translate("validators.in_the_past")
        null
