import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";

import PhotoSwipe from "photoswipe/dist/photoswipe";
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default";

angular.module('ngPhotoswipe', [])
	.directive('ngPhotoswipe', ngPhotoswipeDirective);

function ngPhotoswipeDirective($compile, $http, $templateCache, $state, $auth, $httpParamSerializer) {
	return {
		restrict: 'AE',
		replace: true,
		scope: {
			slides: '=',
			options: '=',
			template: '@',
			open: '=',
			onClose: '&',
			extensions:'=?'
		},
		link: linkFn
	};

	function linkFn(scope, iElement, iAttrs) {
		var template = angular.element(require('app/components/attachments/slide.html'));
		iElement.append($compile(template)(scope));

		scope.start = function () {
			scope.open = true;
			startGallery();
		};

		scope.print = function() {
			var windowUrl = scope.item.printUrl;
			var windowName = scope.item.title;

			var printWindow = window.open(windowUrl, windowName, 'left=50000,top=50000,width=0,height=0');

			printWindow.document.close();
			printWindow.focus();
			printWindow.print();
		};

		scope.sign = function() {
			$state.go('app.sign', {id: scope.item.id});
		};


		scope.download = function() {
			//$http.post("/api/attachments/" + scope.item.id + "/download");
			var params = angular.copy($auth.retrieveData('auth_headers'));
			params['_'] = (new Date).getTime();

			var el = iElement.find("#download-frame");
			el.prop('src', "/api/attachments/" + scope.item.id + "/download?" + $httpParamSerializer(params));
		};

		var startGallery = function () {
			var pswpElement = document.querySelectorAll('.pswp')[0];

			scope.options.shareButtons = [
				{id:'download', label:'Download', url:'{{raw_image_url}}', download:true}
			];

			scope.options.getImageURLForShare = function( shareButtonData ) {
				return scope.item.original || '';
			};

			var gallery = scope.gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default || false, scope.slides, scope.options);
			scope.gallery.init();
			scope.item = scope.gallery.currItem;

			scope.gallery.listen('destroy', function () {
				scope.safeApply(function () {
					scope.onClose();
				});
			});

			scope.gallery.listen('afterChange', function () {
				scope.safeApply(function () {
					scope.item = scope.gallery.currItem;
				});
			});

			/*

				// create variable that will store real size of viewport
					var realViewportWidth = scope.realViewportWidth = null,
					useLargeImages = scope.useLargeImages = false,
					firstResize = scope.firstResize = true,
					imageSrcWillChange = scope.imageSrcWillChange = null;

			// beforeResize event fires each time size of gallery viewport updates
					gallery.listen('beforeResize', function() {
						// gallery.viewportSize.x - width of PhotoSwipe viewport
						// gallery.viewportSize.y - height of PhotoSwipe viewport
						// window.devicePixelRatio - ratio between physical pixels and device independent pixels (Number)
						//                          1 (regular display), 2 (@2x, retina) ...


						// calculate real pixels when size changes
						realViewportWidth = gallery.viewportSize.x * window.devicePixelRatio;

						// Code below is needed if you want image to switch dynamically on window.resize

						// Find out if current images need to be changed
						if(useLargeImages && realViewportWidth < 1000) {
							useLargeImages = false;
							imageSrcWillChange = true;
						} else if(!useLargeImages && realViewportWidth >= 1000) {
							useLargeImages = true;
							imageSrcWillChange = true;
						}

						// Invalidate items only when source is changed and when it's not the first update
						if(imageSrcWillChange && !firstResize) {
							// invalidateCurrItems sets a flag on slides that are in DOM,
							// which will force update of content (image) on window.resize.
							gallery.invalidateCurrItems();
						}

						if(firstResize) {
							firstResize = false;
						}

						imageSrcWillChange = false;

					});


// gettingData event fires each time PhotoSwipe retrieves image source & size
					gallery.listen('gettingData', function(index, item) {

						// Set image source & size based on real viewport width
						if( useLargeImages ) {
							item.src = item.originalImage.src;
							item.w = item.originalImage.w;
							item.h = item.originalImage.h;
						} else {
							item.src = item.mediumImage.src;
							item.w = item.mediumImage.w;
							item.h = item.mediumImage.h;
						}

						// It doesn't really matter what will you do here, 
						// as long as item.src, item.w and item.h have valid values.
						// 
						// Just avoid http requests in this listener, as it fires quite often

					}); */
					};

		scope.$watch('open', function (nVal, oVal) {
			if (nVal != oVal) {
				if (nVal) {
					startGallery();
				}
			} else if (!nVal && scope.gallery) {
				scope.gallery.close();
				scope.gallery = null;
			}
		});

		scope.safeApply = function(fn) {
			var phase = this.$root.$$phase;
			if(phase == '$apply' || phase == '$digest') {
				if(fn && (typeof(fn) === 'function')) {
					fn();
				}
			} else {
				this.$apply(fn);
			}
		};

		scope.$on('destroy', function () {
			scope.gallery = null;
		});
	}
}
