angular.module 'logisticsApp'
  .factory "MaintenanceInvoice", ($resource) ->

    transformAttributes = (invoice) ->
      attrs = {}
      angular.copy invoice, attrs

      if invoice.stock_transactions
        attrs.stock_transactions_attributes = {}
        for stock_transaction, index in invoice.stock_transactions
          attrs.stock_transactions_attributes[index] = {
            id: stock_transaction.id,
            material_id: stock_transaction.material_id,
            amount: stock_transaction.amount,
            cost: stock_transaction.cost,
            cost_currency: stock_transaction.cost_currency,
            _destroy: stock_transaction._destroy
          }
        delete invoice.stock_transactions

      angular.toJson(attrs)

    transformResponseAttributes = (data) ->
      attrs = angular.fromJson(data)
      if attrs.performed_at
        attrs.performed_at = moment(attrs.performed_at).toDate()

      attrs

    $resource('/api/maintenance/invoices/:id.json', {id: '@id'}, {
      get: { method: 'GET', transformResponse: transformResponseAttributes },
      save: { method: 'POST', transformRequest: transformAttributes, transformResponse: transformResponseAttributes },
      update: { method: 'PATCH', transformRequest: transformAttributes, transformResponse: transformResponseAttributes }
    })

