Controller = ($rootScope, $translate, $window, Template) ->
  "ngInject"

  self = this

  @$onInit = ->
    self.template = new Template

  @cancel = ->
    self.dismiss({$value: 'cancel'})

  @save = ->
    self.template.$save ->
      self.close({$value: self.template})

  this
 
angular.module 'logisticsApp'
  .component 'newTemplateModal',
    template: require('app/settings/templates/new/new.html')
    controller: Controller
    bindings:
      resolve: '<'
      close: '&'
      dismiss: '&'
