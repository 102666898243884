angular.module 'logisticsApp'
  .factory "InfoContact", ($resource) ->

    transformAttributes = (info_contact) ->
      attrs = {}
      angular.copy info_contact, attrs

      if attrs.countries
        attrs.countries = attrs.countries.map (c) -> c.iso
    
      angular.toJson(attrs)

    $resource('/api/info_contacts/:id.json', {id: '@id'}, {
      save: { method: 'POST', transformRequest: transformAttributes },
      update: { method: 'PATCH', transformRequest: transformAttributes }
    })

