Controller = ($rootScope, $translate, $window, $filter, toaster, TripNew, Vehicle) ->
  "ngInject"

  self = this

  @$onInit = ->
    self.new_trip = {
      id: self.resolve.tripId
    }

    Vehicle.query().then (vehicles) ->
      self.vehicles = vehicles

  @cancel = ->
    self.dismiss({$value: 'cancel'})

  @save = ->
    self.inProgress = true
    TripNew.copy id: self.new_trip.id, vehicle_id: self.new_trip.vehicle_id, (trip) ->
      toaster.pop('info', $filter('translate')('Trip copied'), 'OK')

      self.close({$value: trip.id})
      self.inProgress = false
    , (response) ->
      self.inProgress = false
      toaster.pop('error', $filter('translate')('Trip coping failed'), 'OK')

  this
 
angular.module 'logisticsApp'
  .component 'copyTripModal',
    template: require('app/trips/trip/copy/copy.html')
    controller: Controller
    bindings:
      resolve: '<'
      close: '&'
      dismiss: '&'
