(function () {
  'use strict';
  angular.module('logisticsApp').directive('clipboard', ['$document', '$filter', '$parse', function ($document, $filter, $parse) {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        element.bind('click', function(e) {
		  e.stopPropagation();

          element = element[0];
          var textContent = element.textContent;
          var input = $document[0].createElement("input");

          $document[0].body.appendChild(input);
          input.value = attrs.clipboard || textContent;
          input.select();

          try {
            $document[0].execCommand("copy");
            element.textContent = $filter('translate')('Copied');

            setTimeout(function() {
              element.textContent = textContent;
            }, 1200);
          } catch(err) {
            console.log('Unable to copy');
          }

          input.remove();

		  return false;
        });
      }
    };
  }]);
})();
