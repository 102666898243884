angular.module 'logisticsApp'
  .controller 'ShellTransactionsController', ($scope, $http, $filter, ShellTransaction, Upload, toaster) ->
    'ngInject'

    $scope.account = null
    $scope.start_at = new Date()
    $scope.end_at = new Date()
    $scope.filter = 'account'
    $scope.accounts = []

    $scope.open = ($event) ->
      $scope.status.opened = true

    $scope.status =
      opened: false

    $http.get('/api/shell_transactions/accounts').then (response) ->
      angular.copy(response.data, $scope.accounts)

    $scope.import = (file) ->
      Upload.upload(
        url: '/api/shell_transactions'
        data: {
          file: file
        }
      ).then (resp) ->
        toaster.pop('success', $filter('translate')('Shell report successfuly uploaded. Parsing started...'))
      , (resp) ->
        toaster.pop('error', $filter('translate')('Failed to load Shell report'))
      
