angular.module 'logisticsApp'
  .factory "Client", (RailsResource, railsSerializer, $translate) ->
    class Client extends RailsResource
      @configure
        url: "/api/clients"
        name: "client"
        serializer: railsSerializer ->
          @exclude('attachments')
          @nestedAttribute('attachments')
          @resource('attachments', 'Attachment')
          @add 'attachment_ids', (client) ->
            if client.attachments
              client.attachments.map (attachment) -> attachment.id
