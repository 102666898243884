angular.module 'logisticsApp'
  .controller 'SelectAttachmentsController', ($scope, $uibModalInstance, attachmentable, Email, Mailbox) ->

    $scope.attachmentable = attachmentable
    $scope.selectedAttachments = []

    $scope.folds = [
      {name: 'INBOX', kind: 'inbox' }
      {name: 'Drafts', kind: 'drafts' }
      {name: 'Sent', kind: 'sent' }
      {name: 'Spam', kind: 'spam' }
      {name: 'Trash', kind: 'trash' }
    ]

    $scope.mailboxes =
      inbox: []
      drafts: []
      sent: []
      spam: []
      trash: []

    $scope.filter_kind = 'inbox'

    Mailbox.query().then (mailboxes) ->
      for i in mailboxes
        unless i.kind == 'custom'
          i.title = i.account
          $scope.mailboxes[i.kind].push(i)
        else
          i.title = i.name
          if $scope.mailboxes[i.account]?
            $scope.mailboxes[i.account].push(i)
          else
            $scope.folds.push(name: i.account, kind: i.account, custom: true)
            $scope.mailboxes[i.account] = []
            $scope.mailboxes[i.account].push(i)

    $scope.showEmail = (index) ->
      $scope.displayedCollection[index].get()

    $scope.callServer = (tableState) ->
      $scope.isLoading = true

      pagination = tableState.pagination
      search = tableState.search.predicateObject or {}

      console.log search

      params =
        kind: search.kind
        mailbox_id: search.mailbox_id
        page: (pagination.start || 0) / pagination.number + 1
        number: pagination.number
        q: search['$']

      Email.query(params).then (resp) ->
        $scope.displayedCollection = resp.emails.map (attrs) -> new Email(attrs)
        tableState.pagination.numberOfPages = resp.numberOfPages
        $scope.isLoading = false

    $scope.attach = ->
      $uibModalInstance.close($scope.selectedAttachments)

    $scope.dissmiss = ->
      $uibModalInstance.dismiss('cancel')
