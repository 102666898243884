Controller = (VehicleNew, $filter, $http, ActionCableChannel, $auth, $httpParamSerializer, $uibModal) ->
  "ngInject"

  self = this

  consumer = null

  callback = (message) ->
    if message.event == 'create'
      #if $rootScope.user.id != message.user_id
      #  toaster.pop('info', $filter('translate')('Trip created'), 'OK')
      load()

    else if message.event == 'sync'
      load()

    else if message.event == 'update'
      for item in self.gridOptions.data
        if item.id == message.resource_id
          item.get()
          return

    else if message.event == 'destroy'
      item = $filter('filter')(self.gridOptions.data, id: message.resource_id)[0]

      if item
        self.displayedCollection.splice(self.gridOptions.data.indexOf(item), 1)
        #if $rootScope.user.id != message.user_id
        #  toaster.pop('info', $filter('translate')('Trip deleted'), 'OK')

  @$onInit = ->
    self.selected = []

    $http.get('/api/vehicles/countries.json').then (resp) ->
      self.target_countries = resp.data.target_countries
      self.pending_countries = resp.data.pending_countries

    consumer = new ActionCableChannel("VehiclesChannel")
    consumer.subscribe(callback)

  @$onDestroy = ->
    consumer.unsubscribe()

  @export = ->
    params = angular.extend($auth.retrieveData('auth_headers'), self.performedParams)
    params['_'] = (new Date).getTime()

    self.downloadUrl = "/api/vehicles.xlsx?#{$httpParamSerializer(params)}"

  load = ->
    self.callServer(self.tableState)

  @callServer = (tableState) =>
    @isLoading = true
    @tableState = tableState

    pagination = tableState.pagination
    search = tableState.search.predicateObject or {}
    sort = tableState.sort or {}

    @startIndex = pagination.start

    params =
      page: (pagination.start || 0) / pagination.number + 1
      number: pagination.number

    params["q[own]"] = true
    params["q[#{self.queryScope}]"] = true if self.queryScope

    params['q[s]'] = "#{sort.predicate} #{if sort.reverse then 'desc' else 'asc'}"  if sort.predicate
    params['q[number_cont]'] = search.number if search.number
    params['q[trailers_title_cont]'] = search.trailer_title if search.trailer_title
    params['q[follow_comment_cont]'] = search.follow_comment if search.follow_comment
    params['q[current_trip_country_code_eq]'] = search.current_trip_country_code_eq if search.current_trip_country_code_eq
    params['q[last_trip_country_code_eq]'] = search.last_trip_country_code_eq if search.last_trip_country_code_eq

    VehicleNew.query params, (vehicles, responseHeaders) =>
      self.performedParams = angular.copy(params)
      @displayedCollection = vehicles
      @isLoading = false
      tableState.pagination.numberOfPages = parseInt(responseHeaders()['x-total-pages'])

  @requestLocation = (vehicle) ->
    VehicleNew.requestLocation id: vehicle.id, (location) ->
      vehicle.tracker.location = location

  @checkAll = ->
    for vehicle in self.displayedCollection
      vehicle.selected = self.allSelected

    if self.allSelected
      self.selected = self.displayedCollection.map (d) -> d.id
    else
      self.selected = []

  @checkItem = (vehicle) ->
    if vehicle.selected
      self.selected.push(vehicle.id)
    else
      if (i = self.selected.indexOf(vehicle.id)) > -1
        self.selected.splice(i, 1)

    self.allSelected = (self.selected.length == self.displayedCollection.length)

  @assignResponsibles = ->
    modalInstance = $uibModal.open
      component: 'transferVehicleModal'
      size: 'md',
      resolve:
        vehicleIds: -> self.selected

    modalInstance.result.then ->
      load()
      self.selected = []

  this
 
angular.module 'logisticsApp'
  .component 'vehicles',
    template: require('app/vehicles/list/list.html')
    controller: Controller
    bindings:
      queryScope: '<?'
