angular.module 'logisticsApp'
  .controller 'TripsController', ($scope, $stateParams, $filter, $translate, $window, Label, Client, User, Direction, Cargo) ->
    'ngInject'

    $scope.statuses = [
      {name: '', text: 'trips.statuses.active'},
      {name: 'pending', text: 'Pending'},
      {name: 'waiting_account', text: 'trips.statuses.waiting_account'},
      {name: 'on_the_way', text: 'trips.statuses.on_the_way'},
      {name: 'waiting_act', text: 'Waiting Act'},
      {name: 'paid', text: 'Paid'},
      {name: 'not_paid', text: 'Not Paid'},
    ]

    $scope.dates =
      opened: false
      opened1: false

    $scope.filter =
      clientId: null
      userId: null

    $scope.clients = []
    $scope.users = []
    $scope.directions = []

    $scope.refreshClients = (q) ->
      Client.query( 'q[organization_cont]': q, only: 'organization').then (clients) ->
        $scope.clients = clients

    $scope.refreshCargos = (q) ->
      $scope.cargos = Cargo.query('q[title_cont]': q)

    $scope.refreshUsers = (user) ->
      User.query(q: user, page: 1, for: 'trip').then (users) ->
        $scope.users = users

    $scope.refreshDirections = (q) ->
      Direction.query(q: q, page: 1).then (items) ->
        $scope.directions = items

