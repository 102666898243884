angular.module 'logisticsApp'
  .controller 'MotorwayTollsController', ($scope, $http, Vehicle) ->
    'ngInject'

    $scope.systems = [
      {name: 'HU-GO'},
      {name: 'Shell'},
      {name: 'Italian Motorways'},
      {name: 'E100'}
    ]

    $scope.selectSystem = (item) ->
      angular.forEach $scope.systems, (item) ->
        item.selected = false

      $scope.item = item
      $scope.item.selected = true
      $scope.system = item.name

    $scope.system = ''
    $scope.vehicle = null
    $scope.finish = moment().toDate()
    $scope.start = moment([moment().year(), moment().month()]).toDate()

    $scope.vehicles = []
    $scope.item = null
    $scope.steps = []

    $scope.status =
      opened1: false
      opened2: false

    $scope.selectVehicle = (item, model) ->
      $scope.vehicle = model

    Vehicle.query().then (items) ->
      angular.copy(items, $scope.vehicles)

    $http.get('/api/motorway_tolls/balance.json').then (response) ->
      $scope.systems[0].balance = response.data.hu_go.amount
      $scope.systems[0].updated_at = response.data.hu_go.updated_at
