angular.module 'logisticsApp'
  .controller 'RemoveClientsCtrl', ($scope, $uibModalInstance, selectedIds, Client, $http) ->
    "ngInject"

    $scope.opts = {}

    $scope.refreshClients = (q) ->
      Client.query( 'q[organization_cont]': q, only: 'organization').then (clients) ->
        $scope.clients = clients

    $scope.ok = ->
      $http.post('/api/clients/batch_destroy.json', ids: selectedIds, transfer_client_id: $scope.opts.transferClientId).then ->
        $uibModalInstance.close($scope.transferClientId)

    $scope.cancel = ->
      $uibModalInstance.dismiss('cancel')
