Controller = ($rootScope, $translate, $window, Template) ->
  "ngInject"

  consumer = null
  self = this

  @$onInit = ->
    self.document = self.resolve.document
    Template.get id: self.document.template_id, (template) ->
      self.template = template
      self.document.custom_fields = template.default_custom_fields

      for custom_field in template.table_fields
        if custom_field.title == 'amount'
          self.document.custom_fields[ custom_field.accessor ] = self.document.trip.total
        else if custom_field.title == 'currency'
          self.document.custom_fields[ custom_field.accessor ] = self.document.trip.currency
        else if custom_field.title == 'sendersReferences'
          self.document.custom_fields[ custom_field.accessor ] = self.document.trip.client.organization
        else if custom_field.title == 'tripTitle'
          title = ''
          for download in self.document.trip.downloadLocations
            title += "#{download.city}, #{download.countryCode}"
          title += ' - '
          for upload in self.document.trip.deliveryLocations
            title += "#{upload.city}, #{upload.countryCode}"

          self.document.custom_fields[ custom_field.accessor ] = title

  @cancel = ->
    self.dismiss({$value: 'cancel'})

  @save = ->
    self.document.$save ->
      self.close({$value: self.document})

  this

angular.module 'logisticsApp'
.component 'newDocumentModal',
  template: require('app/documents/new/new.html')
  controller: Controller
  bindings:
    resolve: '<'
    close: '&'
    dismiss: '&'
