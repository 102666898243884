import "ng-file-upload";

import "app/common/services/clients_new.service";
import "app/common/services/organization_new.service";
import "app/common/services/user_new.service";
import "app/common/services/vehicle_new.service";

Controller = ($rootScope, $translate, $window, $uibModal, ClientNew, OrganizationNew, UserNew, VehicleNew, Upload) ->
  "ngInject"

  consumer = null
  self = this

  @$onInit = ->
    self.trip = self.resolve.trip

    self.payment_methods = [
      {value: 'cashless', text: 'Cashless'},
      {value: 'cash', text: 'Cash'},
      {value: 'bank_card', text: 'Bank card'},
      {value: 'driver_cash', text: 'Driver cash'}
    ]

    self.organizations = OrganizationNew.query()
    self.users = UserNew.query()
    self.vehicles = VehicleNew.query()

  @cancel = ->
    self.dismiss({$value: 'cancel'})

  @save = ->
    return if self.saving
    self.saving = true

    Upload.upload(
      url: "/api/trips.json",
      method: 'POST'
      data:
        trip: self.trip
    ).then((response) ->
      angular.extend(self.trip, response.data)
      self.close({$value: self.trip})
    , (response) ->
      console.log(response)
    ).finally ->
      self.saving = false

  @searchClients = (q) ->
    ClientNew.query('q[organization_cont]': q, (clients) -> self.clients = clients).$promise

  @createClient = (organization) ->
    modalInstance = $uibModal.open
      animation: true,
      component: 'clientModal'
      size: 'lg',
      resolve:
        client: -> new ClientNew(organization: organization, phones: [''])

    modalInstance.result.then (created_client) ->
      self.clients = [created_client]
      self.trip.client_id = created_client.id

  this
 
angular.module 'logisticsApp'
  .component 'tripModal',
    template: require('app/trips/modal/modal.html')
    controller: Controller
    bindings:
      resolve: '<'
      close: '&'
      dismiss: '&'
