angular.module 'logisticsApp'
  .directive 'acmeshelltransactions', ->
    ShellTransactionsController = ($scope, ShellTransaction) ->
      'ngInject'
      vm = this

      vm.items = []
      vm.summary = {}

      $scope.$watch 'vm.account', ->
        if vm.filter == 'account'
          vm.callServer()
      $scope.$watch 'vm.start', ->
        if vm.filter == 'date'
          vm.callServer()
      $scope.$watch 'vm.finish', ->
        if vm.filter == 'date'
          vm.callServer()
      $scope.$watch 'vm.filter', ->
        vm.callServer()

      vm.callServer = (tableState) ->
        if tableState
          vm.tableState = tableState
        else
          tableState = vm.tableState

        vm.isLoading = true
        pagination = tableState.pagination

        start = pagination.start || 0
        number = pagination.number || 50

        ShellTransaction.query(
          start: start,
          number: number,
          account: if vm.filter == 'account' then vm.account else null,
          start_at: if vm.filter == 'date' then moment(vm.start).format('DD/MM/YYYY') else null,
          end_at: if vm.filter == 'date' then moment(vm.finish).format('DD/MM/YYYY') else null
        ).then (resp) ->
          vm.items = resp.data
          vm.summary = resp.summary
          tableState.pagination.numberOfPages = resp.numberOfPages
          vm.isLoading = false

      return

    directive =
      restrict: 'E'
      template: require('app/components/shell_transactions/shell_transactions.html')
      controller: ShellTransactionsController
      controllerAs: 'vm'
      scope:
        account: '='
        start: '='
        finish: '='
        filter: '='
      bindToController: true
