Controller = ($http, $resource, $auth) ->
  "ngInject"

  self = this

  User = $resource('/api/users/:id.json', {id: '@id'}, {
    headers: $auth.retrieveData('auth_headers'),
    update: { method: 'PUT' }
  })


  @$onInit = ->
    $http(
      method: 'GET'
      url: '/api/settings/permissions.json'
    ).then (response) ->
      self.users = response.data.users
      self.groups = response.data.groups

  @grant = (user, permission) =>
    permission_ids = []
    for perm of user.permissions
      permission_ids.push(parseInt(perm)) if user.permissions[perm]
    
    User.update id: user.id, user: { permission_ids: permission_ids }

  this

angular.module 'logisticsApp'
  .config ($stateProvider, $urlRouterProvider) ->
    'ngInject'
    $stateProvider
      .state 'app.settings.permissions',
        url: '/permissions'
        component: 'permissions'
        data:
          permissions:
            only: 'grantPermissions'

  .component 'permissions',
    template: require('app/settings/permissions/permissions.html')
    controller: Controller
