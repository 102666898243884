import "app/common/services/task.service";

Controller = ($rootScope, $translate, $window, $http, User, TripNew) ->
  "ngInject"

  self = this

  @$onInit = ->
    self.task = self.resolve.task

    User.query().then (users) ->
      self.users = users

    self.trips = [self.task.trip] if self.task

  @cancel = ->
    self.dismiss({$value: 'cancel'})

  @save = ->
    if self.task.id
      self.task.$update ->
        self.close({$value: self.task})
    else
      self.task.$save ->
        self.close({$value: self.task})

  @delete = ->
    $translate("Sure?").then (title) =>
      if $window.confirm(title)
        self.task.$delete ->
          self.dismiss({$value: 'cancel'})

  @refreshTrips = (q) ->
    TripNew.query('q[vehicles_number_cont]': q, (trips) ->
      self.trips = trips
    ).$promise

  this
 
angular.module 'logisticsApp'
  .component 'taskModal',
    template: require('app/tasks/modal/modal.html')
    controller: Controller
    bindings:
      resolve: '<'
      close: '&'
      dismiss: '&'
