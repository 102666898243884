angular.module 'logisticsApp'
  .controller 'OrganizationsController', ($scope, $auth, $filter, $translate, $window, User, Organization, FileUploader) ->
    'ngInject'

    $scope.items = []

    Organization.query().then (items) ->
      $scope.items = items
      $scope.item = items[0]
      $scope.selectItem($scope.item)

    $scope.selectItem = (item) ->
      return if $scope.form and $scope.form.$visible

      $scope.form.$cancel()
      angular.forEach $scope.items, (item) ->
        item.selected = false

      item.get().then ->
        $scope.item = item
        $scope.item.selected = true

        $scope.account_uploader.url = "api/organizations/#{$scope.item.id}.json"
        $scope.act_uploader.url = "api/organizations/#{$scope.item.id}.json"

    $scope.doneEditing = (item) ->
      item.save()

    $scope.deleteItem = (item) ->
      $translate("Sure?").then (title) ->
        if $window.confirm(title)
          item.remove().then ->
            $scope.items.splice($scope.items.indexOf(item), 1)
            $scope.item = $scope.items[0]
            if $scope.item
              $scope.item.selected = true

    $scope.createItem = ->
      item = new Organization
      $scope.items.unshift(item)
      $scope.item = item
      $scope.item.selected = true
      $scope.form.$show()

    $scope.account_uploader = new FileUploader({
      method: 'PATCH'
      alias: 'organization[account_template]'
      headers: $auth.retrieveData('auth_headers'),
      autoUpload: true,
      removeAfterUpload: true
    })

    $scope.act_uploader = new FileUploader({
      method: 'PATCH'
      alias: 'organization[act_template]'
      headers: $auth.retrieveData('auth_headers'),
      autoUpload: true,
      removeAfterUpload: true
    })

