Controller = ($rootScope, $translate, $window, CemtLicence, Vehicle) ->
  "ngInject"

  self = this

  @$onInit = ->
    self.licence = self.resolve.licence

    Vehicle.query('q[eco_gteq]': self.licence.eco_standart).then (vehicles) ->
      self.vehicles = vehicles

  @cancel = ->
    self.dismiss({$value: 'cancel'})

  @save = ->
    CemtLicence.update id: self.licence.id, vehicle_id: self.licence.vehicle_id, ->
      self.close({$value: self.licence})

  this
 
angular.module 'logisticsApp'
  .component 'transferCemtLicenceModal',
    template: require('app/cemt_licences/transfer/transfer.html')
    controller: Controller
    bindings:
      resolve: '<'
      close: '&'
      dismiss: '&'
