Controller = ($rootScope, $translate, $window, $http) ->
  "ngInject"

  consumer = null
  self = this

  @$onInit = ->
    self.client = self.resolve.client
   
  @cancel = ->
    self.dismiss({$value: 'cancel'})
    self.dismiss({$value: 'cancel'})

  @save = ->
    if self.client.id
      self.client.$update ->
        self.close({$value: self.client})
    else
      self.client.$save ->
        self.close({$value: self.client})

  @delete = ->
    $translate("Sure?").then (title) =>
      if $window.confirm(title)
        self.client.$delete ->
          self.dismiss({$value: 'cancel'})

  this
 
angular.module 'logisticsApp'
  .component 'clientModal',
    template: require('app/clients/modal/modal.html')
    controller: Controller
    bindings:
      resolve: '<'
      close: '&'
      dismiss: '&'
