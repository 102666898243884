angular.module 'logisticsApp'
  .factory "FuelCard", (RailsResource, railsSerializer) ->
    class FuelCard extends RailsResource
      @configure
        url: "/api/fuel_cards"
        name: "fuel_card"
        serializer: railsSerializer ->
          @exclude('vehicle','vehicleNumber','selected')

      @manufacturers = ['Shell','WOG','BRSM','Nafta Club', 'E100']

      @checkNumber = (data, id) ->
        if data == "" or !data?
          return $translate("validators.blank")
        null

      @checkPassword = (data, id) ->
        if id
          return null
        if data == "" or !data?
          return $translate("validators.blank")
        null

      @checkPasswordConfirmation = (data, id) ->
        if id
          return null
        if data == "" or !data?
          return $translate("validators.blank")
        null
