Controller = ($rootScope, $uibModal, $translate, $window, $http, $auth, $httpParamSerializer, Task, Organization, ClientNew, VehicleNew, Purse, ActionCableChannel) ->
  "ngInject"

  self = this

  @$onInit = ->
    self.showTrip = true unless self.showTrip?
    self.now_ts = moment().unix()

  @edit = (task) ->
    $uibModal.open({
      animation: true,
      component: 'taskModal'
      size: 'lg',
      resolve:
        task: -> Task.get(id: task.id).$promise
    }).result.then (updated_task) ->
      angular.extend(task, updated_task)

  @finish = (task) ->
    Task.update id: task.id, finished_at: moment(), (updated_task) ->
      angular.extend(task, updated_task)

  @unfinish = (task) ->
    Task.update id: task.id, finished_at: null, (updated_task) ->
      angular.extend(task, updated_task)

  @remove = (index) ->
    $translate("Sure?").then (title) =>
      if $window.confirm(title)
        Task.delete id: self.tasks[index].id, ->
          self.tasks.splice(index, 1)

  this
 
angular.module 'logisticsApp'
  .component 'tasksList',
    template: require('app/tasks/list/list.html')
    controller: Controller
    bindings:
      tasks: '<'
      showTrip: '<'
