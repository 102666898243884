Controller = ($translate, $filter, $window, $uibModal, $http, PartnerNew, User) ->
  "ngInject"

  self = this

  @load = =>
    @callServer(@tableState)

  @$onInit = =>
    self.selected = []
    @displayedCollection = []

  @checkAll = ->
    for client in self.displayedCollection
      client.selected = self.allSelected

    if self.allSelected
      self.selected = self.displayedCollection.map (c) -> c.id
    else
      self.selected = []

  @checkItem = (client) ->
    if client.selected
      self.selected.push(client.id)
    else
      if (i = self.selected.indexOf(client.id)) > -1
        self.selected.splice(i, 1)

    self.allSelected = (self.selected.length == self.displayedCollection.length)

  @removeSelected = ->
    if $window.confirm($filter('translate')('Are you sure?'))
      $http.post('/api/partners/batch_destroy.json', ids: self.selected).then ->
        self.callServer(self.tableState)
        self.selected = []

  @callServer = (tableState) =>
    @isLoading = true
    @tableState = tableState

    pagination = tableState.pagination
    search = tableState.search.predicateObject or {}
    sort = tableState.sort or {}

    @startIndex = pagination.start

    params =
      page: (pagination.start || 0) / pagination.number + 1
      number: pagination.number

    params["q[#{@queryScope}]"] = true if @queryScope

    params['q[s]'] = "#{sort.predicate} #{if sort.reverse then 'desc' else 'asc'}"  if sort.predicate
    params['q[organization_cont]'] = search.organization if search.organization

    PartnerNew.query params, (clients, responseHeaders) =>
      @displayedCollection = clients
      @isLoading = false
      tableState.pagination.numberOfPages = parseInt(responseHeaders()['x-total-pages'])

  this

angular.module 'logisticsApp'
  .config ($stateProvider) ->
    "ngInject"

    $stateProvider.state 'app.partners.list',
      url: '/list'
      component: 'partnersList'
 
  .component 'partnersList',
    template: require('app/partners/list/list.html')
    controller: Controller
    bindings:
      queryScope: '<?'
