angular.module 'logisticsApp'
  .config ($stateProvider) ->
    "ngInject"

    $stateProvider.state 'app.partners',
      url: '/partners'
      abstract: true
      data:
        permissions:
          only: 'readPartners'

