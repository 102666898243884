import "app/common/services/maintenance_material.service";

#import "app/manufacturing/materials/modal/modal.component";

Controller = ($rootScope, $uibModal, $translate, $window, $http, $auth, $httpParamSerializer, MaintenanceMaterial, MaintenanceInvoice, ActionCableChannel) ->
  "ngInject"

  consumer = null
  self = this

  @prevPage = ->
    self.filters.page = self.pagination.prev_page
    self.load()

  @nextPage = ->
    self.filters.page = self.pagination.next_page
    self.load()

  @statusChanged = ->
    for s in self.statuses
      delete self.filters["q[#{s}]"]

    if self.filter_by_status
      self.filters["q[#{self.filter_by_status}]"] = true

    self.load()

  @load = ->
    MaintenanceMaterial.query self.filters, (materials, responseHeaders) ->
      self.materials = materials
      self.summary = { stock_available: responseHeaders()['x-total-stock-available'] }
      self.pagination = {
        total_count: parseInt(responseHeaders()['x-total-count']),
        next_page: responseHeaders()['x-next-page'],
        prev_page: responseHeaders()['x-prev-page'],
      }
      self.pagination.first = (self.filters.page - 1) * self.filters.limit + 1
      self.pagination.last = self.pagination.first + materials.length - 1

  callback = (message) ->
    self.load()

  @$onInit = ->
    self.filters = {
      page: 1
      limit: 25
    }
    self.statuses = ['present', 'ending', 'absence']
    self.load()

    consumer = new ActionCableChannel("PermitsChannel")
    consumer.subscribe(callback)

  @edit = (material) ->
    modalInstance = $uibModal.open(
      animation: true,
      component: 'maintenanceMaterialModal'
      size: 'lg',
      resolve:
        material: -> MaintenanceMaterial.get(id: material.id).$promise
    ).result.then (updated_material) ->
      if updated_material == 'deleted'
        self.materials.splice(self.materials.indexOf(material), 1)
      else
        angular.extend(material, updated_material)

  @remove = (index) ->
    $translate("Sure?").then (title) =>
      if $window.confirm(title)
        MaintenanceMaterial.delete id: self.materials[index].id, ->
          self.materials.splice(index, 1)

  @new = (kind) ->
    MaintenanceInvoice.save kind: kind, (created_invoice) ->
      modalInstance = $uibModal.open(
        animation: true,
        component: 'maintenanceInvoiceModal'
        size: 'lg',
        resolve:
          invoice: -> MaintenanceInvoice.get(id: created_invoice.id).$promise
      ).result.then (updated_invoice) ->
        self.load()

  this
 
angular.module 'logisticsApp'
  .component 'materialsList',
    template: require('app/maintenance/materials/list/list.html')
    controller: Controller
    bindings:
      staticParams: '<'

  .config ($stateProvider, $urlRouterProvider) ->
    'ngInject'
    $stateProvider
      .state 'app.maintenance.materials',
        url: '/materials'
        component: 'materialsList'
