angular.module 'logisticsApp'
  .controller 'DocumentsController', ($scope, $filter, $translate, $stateParams, Template, PermPermissionStore) ->
    'ngInject'

    $scope.types = [
      {value: 'Application', text: 'Application'},
      {value: 'Account', text: 'Account'},
      {value: 'Act', text: 'Act'},
      {value: 'Contract', text: 'Contract'},

      {value: 'Visa', text: 'documents.types.visa'},
      {value: 'UKVisa', text: 'UK Visa'},
      {value: 'Insurance', text: 'documents.types.insurance'},
      {value: 'Certificate', text: 'documents.types.certificate'},

      {value: 'UniversityCertificate', text: 'Univ. Certificate'},
      {value: 'UniversityTrailerCertificate', text: 'Univ. Certificate (Trailer)'},
      {value: 'YellowCertificate', text: 'Yellow Certificate'},
      {value: 'MedicalCertificate', text: 'Medical Certificate'},
      {value: 'InternationalCource', text: 'International Cources'},
      {value: 'Adr', text: 'ADR'}
      {value: 'FireExtinguisher', text: 'Fire Extinguisher'}

      {value: 'DriverLicence', text: 'Driver Licence'}
      {value: 'Passport', text: 'Passport'}
      {value: 'ForeignPassport', text: 'Foreign Passport'}
      {value: 'VehicleRegistrationCertificate', text: 'Veh. Reg. Cert.'}
      {value: 'PublicDocument', text: 'Public Document'}
    ]

    @$onInit = ->
      $scope.selectedType = $stateParams.type

      perm = PermPermissionStore.getPermissionDefinition('listFinanceDocuments')
      unless perm
        $scope.types.splice(1,3)

      Template.query (templates) ->
        for template in templates
          $scope.types.unshift(value: 'Document', text: template.name, template_id: template.id)

    $scope.types.splice(4,15) if $scope.user.configName == 'client'

    $scope.statuses = [
      {value: 'working', text: 'documents.statuses.working'},
      {value: 'pending', text: 'documents.statuses.pending'},
      {value: 'expired', text: 'documents.statuses.expired'}
    ]

    $scope.payment_types = [
      {value: 'cash', text: 'Cash'},
      {value: 'cashless', text: 'Cashless'}
    ]


    $scope.showStatus = (status) ->
      selected = $filter('filter')($scope.statuses, {value: status})
      if selected.length then selected[0].text else ''

    $scope.showType = (type) ->
      selected = $filter('filter')($scope.types, {value: type})
      if selected.length then selected[0].text else ''

    $scope.select = (type) ->
      $scope.selectedType = type

    this

  .config ($stateProvider, $urlRouterProvider) ->
    'ngInject'
    $stateProvider
      .state 'app.documents',
        url: '/documents',
        template: require('app/documents/documents.html')
        controller: 'DocumentsController'


