import "app/common/services/trailer.service";
import "app/common/services/vehicle_new.service";

Controller = ($rootScope, $translate, $window, VehicleNew) ->
  "ngInject"

  consumer = null
  self = this

  @$onInit = ->
    self.trailer = self.resolve.trailer

    self.vehicles = VehicleNew.query()

  @cancel = ->
    self.dismiss({$value: 'cancel'})

  @save = ->
    if self.trailer.id
      self.trailer.$update ->
        self.close({$value: self.trailer})
    else
      self.trailer.$save ->
        self.close({$value: self.trailer})

  @delete = ->
    $translate("Sure?").then (title) =>
      if $window.confirm(title)
        self.trailer.$delete ->
          self.close({$value: 'deleted'})


  this
 
angular.module 'logisticsApp'
  .component 'trailerModal',
    template: require('app/trailers/modal/modal.html')
    controller: Controller
    bindings:
      resolve: '<'
      close: '&'
      dismiss: '&'
