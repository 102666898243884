angular.module 'logisticsApp'
  .factory "Permit", ($resource) ->
    transformAttributes = (permit) ->
      attrs = {}
      angular.copy permit, attrs
      unless attrs.bot_reserve
        if attrs.reservation_date
          attrs.reservation_date = moment(attrs.reservation_date).format('YYYY-MM-DD')
      else
        attrs.reservation_date = null

      if attrs.used_date
        attrs.used_date = moment(attrs.used_date).format('YYYY-MM-DD')
      if attrs.pvd_received_date
        attrs.pvd_received_date = moment(attrs.pvd_received_date).format('YYYY-MM-DD')
      if attrs.office_received_date
        attrs.office_received_date = moment(attrs.office_received_date).format('YYYY-MM-DD')
      if attrs.returned_date
        attrs.returned_date = moment(attrs.returned_date).format('YYYY-MM-DD')
      angular.toJson(attrs)

    transformResponseAttributes = (data) ->
      attrs = angular.fromJson(data)
      if attrs.reservation_date
        attrs.reservation_date = moment(attrs.reservation_date).toDate()
      if attrs.used_date
        attrs.used_date = moment(attrs.used_date).toDate()
      if attrs.pvd_received_date
        attrs.pvd_received_date = moment(attrs.pvd_received_date).toDate()
      if attrs.office_received_date
        attrs.office_received_date = moment(attrs.office_received_date).toDate()
      if attrs.returned_date
        attrs.returned_date = moment(attrs.returned_date).toDate()
      attrs

    $resource('/api/permits/:id.json', {id: '@id'}, {
      get: { method: 'GET', transformResponse: transformResponseAttributes },
      save: { method: 'POST', transformRequest: transformAttributes },
      update: { method: 'PATCH', transformRequest: transformAttributes }
    })

