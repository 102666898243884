angular.module 'logisticsApp'
  .directive 'acmemotorwaytolls', ->

    MotorwayTollsController = ($scope, $http, MotorwayToll) ->
      'ngInject'
      vm = this

      vm.items = []

      $scope.$watch 'vm.vehicle', ->
        vm.callServer()
      $scope.$watch 'vm.system', ->
        vm.callServer()
      $scope.$watch 'vm.start', ->
        vm.callServer()
      $scope.$watch 'vm.finish', ->
        vm.callServer()

      vm.callServer = (tableState) ->
        if tableState
          vm.tableState = tableState
        else
          tableState = vm.tableState

        vm.isLoading = true
        pagination = tableState.pagination

        start = pagination.start || 0
        number = pagination.number || 50

        MotorwayToll.query(
          start: start,
          number: number,
          vehicle_id: vm.vehicle,
          system: vm.system,
          start_at: if vm.start then moment(vm.start).format('DD/MM/YYYY') else null,
          end_at: if vm.finish then moment(vm.finish).format('DD/MM/YYYY') else null
        ).then (resp) ->
          vm.items = resp.data

          vm.item = vm.items[0]
          vm.selectItem(0)

          tableState.pagination.numberOfPages = resp.numberOfPages
          vm.summary = resp.summary
          vm.isLoading = false

      vm.selectItem = (index) ->
        unless item = vm.items[index]
          vm.steps = []
          return

        angular.forEach vm.items, (item) ->
          item.selected = false

        if item.vehicle and item.endedAt
          $http.get('/api/motorway_tolls/steps.json',
            params: { vehicle_id: item.vehicleId, system: vm.system, ended_at: item.endedAt}
          ).then (response) ->
            vm.item = item
            vm.item.selected = true
            angular.copy(response.data, vm.steps)
        else
          vm.steps = []

      return


    directive =
      restrict: 'E'
      template: require('app/components/motorway_tolls/motorway_tolls.html')
      controller: MotorwayTollsController
      controllerAs: 'vm'
      scope:
        vehicle: '='
        system: '='
        start: '='
        finish: '='
        item: '='
        steps: '='
      bindToController: true
