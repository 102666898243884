angular.module 'logisticsApp'
  .controller 'SearchController', ($scope, $translate, $window, $filter, $http, $interval, $timeout, Vehicle, Direction, NgMap) ->
    'ngInject'

    vm = this

    first_direction_changed = false

    $scope.start_at = moment().toDate()
    $scope.end_at = moment().weekday(7).toDate()
    $scope.status =
      opened1: false
      opened2: false

    $scope.directions = []

    $scope.selectDirection = (item) ->
      angular.forEach $scope.directions, (item) ->
        item.selected = false

      $scope.direction = item
      $scope.direction.selected = true

      loadVehicles()

    $scope.selectVehicle = (vehicle) ->
      $scope.vehicle.unsubscribe() if $scope.vehicle
      $scope.vehicle = null
      angular.forEach $scope.vehicles, (v) ->
        v.selected = false

      vehicle.get().then ->
        vehicle.subscribe($scope)
        $scope.vehicle = vehicle
        $scope.vehicle.selected = true

        # TODO: refactor
        first_direction_changed = true

        if vehicle.trackable
          $scope.map.setCenter(new google.maps.LatLng(vehicle.latitude, vehicle.longitude)) if $scope.map

        console.log "selected #{vehicle.number} #{vehicle.location}"

    $scope.clickMarker = (event, vehicle) ->
      $scope.selectVehicle(vehicle)

    $scope.load = ->
      Direction.query(
        start_at: moment($scope.start_at).format('DD/MM/YYYY'),
        end_at: moment($scope.end_at).format('DD/MM/YYYY')
      ).then (directions) ->
        $scope.directions = directions
        $scope.selectDirection(directions[0]) if directions.length > 0


    $scope.vehicles = []
    $scope.map = null
    NgMap.getMap().then (m) ->
      $scope.map = m
      $scope.load()

    loadVehicles = ->
      Vehicle.query(own: true, direction_id: $scope.direction.id, delivered_start_at: moment($scope.start_at).format('DD/MM/YYYY'), delivered_end_at: moment($scope.end_at).format('DD/MM/YYYY')).then (vehicles) ->
        $scope.vehicles = vehicles
        if vehicles.length > 0
          $scope.selectVehicle(vehicles[0])
        else
          $scope.vehicle = null

    $scope.refresh = ->
      $scope.refreshDisabled = true
      $http.post('/api/vehicles/refresh.json').then (response) ->
        $scope.refreshDisabled = false

    $scope.directionChanged = ->

      # TODO: refactor
      if first_direction_changed
        first_direction_changed = false
        return

      way_points = $scope.map.directionsRenderers[0].directions.routes[0].legs[0].via_waypoints.map (wp) ->
        {location: {lat:wp.lat(), lng: wp.lng()}, stopover: false}

      if $scope.vehicle
        new Vehicle(id: $scope.vehicle.id, wayPoints: JSON.stringify(way_points)).update()
        
    vm

