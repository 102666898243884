angular.module 'logisticsApp'
  .factory "Driver", (RailsResource, railsSerializer, $translate) ->
    class Driver extends RailsResource
      @configure
        url: "/api/drivers"
        name: "driver"
        serializer: railsSerializer ->
          @exclude('attachments')
          @nestedAttribute('attachments')
          @resource('attachments', 'Attachment')
          @add 'attachment_ids', (driver) ->
            if driver.attachments
              driver.attachments.map (attachment) -> attachment.id
