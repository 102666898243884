Controller = ($rootScope, $translate, $window, $http, Vehicle, Driver, Pvd, PermitType, Permit) ->
  "ngInject"

  consumer = null
  self = this

  @$onInit = ->
    self.permit = {variations: [{possible_permit_type_ids: [], possible_pvd_ids: []}]}

    Vehicle.query().then (vehicles) ->
      self.vehicles = vehicles

    Driver.query().then (drivers) ->
      self.drivers = drivers

    self.pvds = Pvd.query()
    self.permit_types = PermitType.query()

    $http.get('/api/permits/countries.json').then (response) ->
      self.countries = response.data

  @cancel = ->
    self.dismiss({$value: 'cancel'})

  @save = ->
    $http.post('/api/permits/reserve.json', self.permit).then (response) ->
      self.close({$value: self.permit})

  @delete = ->
    $translate("Sure?").then (title) =>
      if $window.confirm(title)
        self.permit.$delete ->
          self.dismiss({$value: 'cancel'})

  @getPvds = (q) ->
    Pvd.query('q[title_cont]': q).$promise

  @getPermitTypes = (q) ->
    PermitType.query('q[title_cont]': q).$promise

  @add = ->
    self.permit.variations.push({possible_permit_type_ids: [], possible_pvd_ids: []})

  @remove = (index) ->
    self.permit.variations.splice(index, 1)

  this
 
angular.module 'logisticsApp'
  .component 'permitReserve',
    template: require('app/permits/reserve/reserve.html')
    controller: Controller
    bindings:
      resolve: '<'
      close: '&'
      dismiss: '&'
