angular.module 'logisticsApp'
  .controller 'ClientRegistrationsCtrl', ($scope, $auth) ->
    'ngInject'

    $scope.submitRegistration = (form) ->
      $auth.submitRegistration(form, {config: 'client'})
        .then ->
          $auth.submitLogin {
            email: form.email,
            password: form.password
          }, {
            config: 'client'
          }

    $scope.$on 'auth:registration-email-error', (ev, reason) ->
      $scope.authError = reason.errors[0]
