Controller = ($translate, $window, FuelFillUp) ->
  "ngInject"

  self = this

  @$onInit = ->
    @displayedCollection = []

  @remove = (index) ->
    $translate("Sure?").then (title) ->
      if $window.confirm(title)
        fuel_fill_up = self.displayedCollection[index]
        if fuel_fill_up.id
          fuel_fill_up.$delete ->
            self.displayedCollection.splice(index, 1)
        else
          self.displayedCollection.splice(index, 1)

  @save = (fuel_fill_up) ->
    fuel_fill_up.canceled = false unless fuel_fill_up.canceled?
    if fuel_fill_up.id
      fuel_fill_up.$update()
    else
      fuel_fill_up.$save()

  @add = ->
    self.inserted = new FuelFillUp(at: moment().toDate(), amount: 0, total: 0)
    self.displayedCollection.unshift(self.inserted)

  @callServer = (tableState) ->
    self.isLoading = true
    self.tableState = tableState

    pagination = tableState.pagination
    search = tableState.search.predicateObject or {}
    sort = tableState.sort or {}

    self.startIndex = pagination.start

    params =
      page: (pagination.start || 0) / pagination.number + 1
      number: pagination.number

    FuelFillUp.query params, (fuel_fill_ups, responseHeaders) ->
      self.displayedCollection = fuel_fill_ups
      self.isLoading = false
      tableState.pagination.numberOfPages = parseInt(responseHeaders()['x-total-pages'])

  this

angular.module 'logisticsApp'
  .component 'fuelFillUps',
    template: require('app/fuel_fill_ups/fill_ups.html')
    controller: Controller

  .config ($stateProvider) ->
    "ngInject"

    $stateProvider.state 'app.fuel_fill_ups',
      url: '/fuel_fill_ups'
      component: 'fuelFillUps'
      data:
        permissions:
          only: 'listFuelFillUps'

