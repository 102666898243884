Controller = ($rootScope, $uibModal, $translate, $window, $http, $auth, $httpParamSerializer, Account, Organization, ClientNew, VehicleNew, Purse, ActionCableChannel) ->
  "ngInject"

  consumer = null
  self = this

  @load = ->
    Account.query self.filters, (accounts, responseHeaders) ->
      self.accounts = accounts
      self.pagination = {
        total_count: parseInt(responseHeaders()['x-total-count']),
      }

      self.filters.page = responseHeaders()['x-current-page']
      self.pagination.first = (self.filters.page - 1) * self.filters.limit + 1
      self.pagination.last = self.pagination.first + accounts.length - 1

    $http(
      method: 'GET'
      url: '/api/accounts/summary.json'
      params: self.filters
    ).then (response) ->
      self.summary = response.data

  callback = (message) ->
    self.load()

  @$onInit = ->
    Organization.query().then (organizations) ->
      self.organizations = organizations

    self.purses = Purse.query('q[is_visible_on_accounts_true]': true)
    self.initialFilters = {
      page: 1
      limit: 25
    }

    persistedQueryJson = $window.localStorage.getItem(queryKey())

    if persistedQueryJson? and persistedQueryJson != '' and persistedQueryJson != 'undefined'
      self.filters = JSON.parse(persistedQueryJson)
    else
      self.filters = angular.copy(self.initialFilters)

    angular.extend(self.filters, self.staticParams) if self.staticParams
    self.load()

    consumer = new ActionCableChannel("AccountsChannel")
    consumer.subscribe(callback)

  @remove = (index) ->
    $translate("Sure?").then (title) =>
      if $window.confirm(title)
        Account.delete id: self.accounts[index].id, ->
          self.accounts.splice(index, 1)

  @filterChanged = ->
    if self.filters?
      $window.localStorage.setItem(queryKey(), JSON.stringify(self.filters))
    self.load()

  @getClients = (q) ->
    ClientNew.query( 'q[organization_cont]': q, only: 'organization').$promise

  queryKey = ->
    'filters-accounts'

  @export = ->
    params = angular.extend($auth.retrieveData('auth_headers'), self.filters)
    params['_'] = (new Date).getTime()

    self.downloadUrl = "/api/accounts.xlsx?#{$httpParamSerializer(params)}"

  this
 
angular.module 'logisticsApp'
  .component 'accounts',
    template: require('app/documents/accounts/accounts.html')
    controller: Controller
    bindings:
      staticParams: '<'

  .config ($stateProvider, $urlRouterProvider) ->
    'ngInject'
    $stateProvider
      .state 'app.accounts',
        url: '/accounts'
        component: 'accounts'
        data:
          permissions:
            only: 'readAccounts'
