import "app/common/services/track_idle.service";
import "app/common/services/driver_new.service";
import "app/common/services/vehicle_new.service";

Controller = ($translate, $stateParams, $window, $rootScope, $q, $filter, $localStorage, $uibModal, $http, $state, TrackIdle, Document, Client, VehicleNew, DriverNew, Organization, Purse) ->
  "ngInject"

  self = this

  @$onInit = ->
    self.pagination = {
      page: 1
      limit: 25
    }

    self.vehicles = VehicleNew.query()
    self.drivers = DriverNew.query()

    self.persistKey = "main-office-#{$rootScope.user.current_main_office_id}:idles"

    $localStorage.filters ||= {}
    self.filters = $localStorage.filters[self.persistKey]

    if self.filters?
      for filter, value of self.filters
        if (filter.indexOf('created_at') > -1)
          self.filters[filter] = moment(value).toDate() if value? and value.length > 0
    else
      self.filters = angular.copy(self.initialFilters)

    self.load()

  @remove = (index) =>
    $translate("Sure?").then (title) =>
      if $window.confirm(title)
        payment = @displayedCollection[index]
        if payment.id
          #promise = payment.remove()
          #promise.then ->
          #  @displayedCollection.splice(index, 1)
          payment.canceled = true
          payment.save()
        else
          @displayedCollection.splice(index, 1)
 
  @clearFilters = ->
    self.filters = { }
    self.load()

  @load = ->
    self.isLoading = true

    params = angular.copy(self.pagination)
    #params['q[trip_result_null]'] = true
    params.totals = true

    for filter, value of self.filters
      params["q[#{filter}]"] = value

    $localStorage.filters[self.persistKey] = self.filters

    TrackIdle.query params, (items, responseHeaders) ->
      self.trackIdles = items

      self.pagination.total_count = parseInt(responseHeaders()['x-total-count'])
      self.pagination.page = parseInt(responseHeaders()['x-current-page'])
      self.summary = {
        duration: decodeURIComponent(responseHeaders()['x-total-duration'])
      }

      self.isLoading = false

  @remove = (index) ->
    $translate("Sure?").then (title) =>
      if $window.confirm(title)
        TrackIdle.delete id: self.idles[index].id, ->
          self.idles.splice(index, 1)

  this

angular.module 'logisticsApp'
  .component 'trackIdles',
    template: require('app/track-idles/track-idles.html')
    controller: Controller

  .config ($stateProvider, $urlRouterProvider) ->
    'ngInject'
    $stateProvider
      .state 'app.trackIdles',
        url: '/track_idles'
        component: 'trackIdles'
        data:
          permissions:
            only: 'readTrackIdles'
