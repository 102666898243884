import "app/common/services/task.service";
import "app/tasks/list/list.component";

Controller = ($rootScope, $uibModal, $translate, $window, $http, $auth, $httpParamSerializer, $filter, $localStorage, Task, Organization, ClientNew, VehicleNew, Purse, User, ActionCableChannel) ->
  "ngInject"

  consumer = null
  self = this

  @clearFilters = ->
    self.filters = {}
    self.load()

  @load = ->
    params = angular.copy(self.pagination)

    for filter, value of self.filters
      if filter == 'scopes'
        for _,filter_scope of value
          params["q[#{filter_scope}]"] = true if filter_scope? and filter_scope.length > 0
      else
        params["q[#{filter}]"] = value

    $localStorage.filters[self.persistKey] = self.filters

    Task.query params, (tasks, responseHeaders) ->
      self.tasks = tasks

      self.pagination.total_count = parseInt(responseHeaders()['x-total-count'])
      self.pagination.page = parseInt(responseHeaders()['x-current-page'])
      self.pagination.first = parseInt(responseHeaders()['x-first'])
      self.pagination.last = parseInt(responseHeaders()['x-last'])

  callback = (message) ->
    self.load()

  @$onInit = ->
    self.pagination = {
      page: 1
      limit: 25
    }

    consumer = new ActionCableChannel("TasksChannel")
    consumer.subscribe(callback)

    User.query().then (users) ->
      self.users = [id: null, fullname: $filter('translate')('All')].concat(users)

    self.persistKey = "main-office-#{$rootScope.user.current_main_office_id}:payments"

    $localStorage.filters ||= {}
    self.filters = $localStorage.filters[self.persistKey]

    if self.filters?
      for filter, value of self.filters
        if (filter.indexOf('created_at') > -1) or (filter.indexOf('deadline_at') > -1)
          self.filters[filter] = moment(value).toDate() if value? and value.length > 0
    else
      self.filters = angular.copy(self.initialFilters)

    self.load()

  @$onDestroy = ->
    consumer.unsubscribe() if consumer

  queryKey = ->
    'filters-tasks'

  this
 
angular.module 'logisticsApp'
  .component 'tasks',
    template: require('app/tasks/tasks.html')
    controller: Controller
    bindings:
      staticParams: '<'

  .config ($stateProvider, $urlRouterProvider) ->
    'ngInject'
    $stateProvider
      .state 'app.tasks',
        url: '/tasks'
        component: 'tasks'
        data:
          permissions:
            only: 'readTasks'

