Controller = (PaymentNew, $translate, $window, $uibModal) ->
  "ngInject"

  self = this

  @paid = (index) ->
    PaymentNew.update id: self.payments[index].id, paid: self.payments[index].paid, ->
      self.onDone()

  @edit = (index) ->
    payment = self.payments[index]

    modalInstance = $uibModal.open
      animation: true,
      component: 'paymentModal'
      size: 'md',
      resolve:
        payment: -> PaymentNew.get(id: payment.id).$promise
        trip: -> self.trip

  @remove = (index) ->
    $translate("Sure?").then (title) =>
      if $window.confirm(title)
        PaymentNew.delete id: self.payments[index].id, ->
          self.payments.splice(index, 1)

  this

angular.module 'logisticsApp'
  .component 'paymentsList',
    template: require('app/components/payments/payments-list.html')
    controller: Controller
    bindings:
      payments: '<'
      debitPayments: '<'
      trip: '<'
      balance: '<?'
      balanceAmount: '<?'
      onDone: '&'
