import tinymce from 'tinymce/tinymce';

import 'tinymce/icons/default';

import 'tinymce/themes/silver';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/print';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/pagebreak';

import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';

import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/save';
import 'tinymce/plugins/table';
import 'tinymce/plugins/contextmenu';
import 'tinymce/plugins/directionality';

import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/save';
import 'tinymce/plugins/table';
import 'tinymce/plugins/contextmenu';
import 'tinymce/plugins/directionality';

import 'tinymce/plugins/template';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/textcolor';
import 'tinymce/plugins/colorpicker';
import 'tinymce/plugins/textpattern';
import 'tinymce/plugins/imagetools';
import 'tinymce/plugins/toc';


#require.context(
#  'file-loader?name=[path][name].[ext]&context=node_modules/tinymce!tinymce/skins',
#  true,
#  /.*/
#);

import 'angular-ui-tinymce';

Controller = ($http, $resource, $uibModal, $rootScope, $transitions, $translate, $filter, $window, TableField, Template, toaster) ->
  "ngInject"

  self = this

  confirmLeave = ->
    if self.form and self.form.$dirty
      if window.confirm($filter('translate')('Changes not saved! Are you sure to leave this page?')) == false
        return false
    true

  @$onInit = ->
    $transitions.onStart {}, confirmLeave

    self.templates = Template.query (templates) ->
      if templates.length > 0
        self.select(templates[0])

    self.tinymceOptions =
      setup: (editor) ->
        editor.editor = editor

      theme: 'silver'
      language: 'ru'
      language_url : "/tinymce/langs/ru.js"
      skin_url: '/tinymce/skins/ui/oxide'
      height: '400px'
      plugins: [
        'advlist autolink lists link image charmap print preview hr anchor pagebreak',
                    'searchreplace wordcount visualblocks visualchars code fullscreen',
                        'insertdatetime media nonbreaking save table contextmenu directionality',
                            'template paste textcolor colorpicker textpattern imagetools toc'
      ]
      toolbar1: 'undo redo | insert | styleselect | fontselect fontsizeselect |  bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image'
      toolbar2: 'print preview media | forecolor backcolor emoticons | codesample'
      templates: '/api/templates/variables.json'
      image_advtab: true
      content_css: [
        '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
                                                                  '//www.tinymce.com/css/codepen.min.css'
      ]
      images_upload_handler: (blobInfo, success, failure) ->
        formData = new FormData()
        formData.append('file', blobInfo.blob(), blobInfo.filename())
        formData.append('tinymce', true)
        formData.append('type', 'Attachment')
        formData.append('attachmentable_id', self.selected.id)
        formData.append('attachmentable_type', 'Template')

        $http.post("/api/attachments.json", formData, {
          transformRequest: angular.identity,
          headers: {'Content-Type': undefined}
        }).then (response) ->
          success(response.data.location)
        , failure

      file_picker_types: 'image'
      file_picker_callback: (cb, value, meta) ->
        input = document.createElement('input')
        input.setAttribute('type', 'file')
        input.setAttribute('accept', 'image/*')
        
        input.onchange = ->
          file = this.files[0]
          
          id = 'blobid' + (new Date()).getTime()
          blobCache = tinymce.activeEditor.editorUpload.blobCache
          blobInfo = blobCache.create(id, file)
          blobCache.add(blobInfo)
          
          cb(blobInfo.blobUri(), { title: file.name })
        
        input.click()

  @add = ->
    modalInstance = $uibModal.open
      animation: true,
      component: 'newTemplateModal'
      size: 'sm'

    modalInstance.result.then (template) ->
      self.templates.push(template)

  @addField = ->
    modalInstance = $uibModal.open
      animation: true,
      component: 'tableFieldModal'
      size: 'sm'
      resolve:
        table_field: ->
          new TableField(template_id: self.selected.id)
        template: ->
          self.selected

    modalInstance.result.then (field) ->
      self.selected.table_fields.push(field)

  @editField = (index) ->
    field = self.selected.table_fields[index]

    modalInstance = $uibModal.open
      animation: true,
      component: 'tableFieldModal'
      size: 'sm'
      resolve:
        table_field: ->
          TableField.get(template_id: self.selected.id, id: field.id)
        template: ->
          self.selected

    modalInstance.result.then (field) ->
      self.selected.table_fields[index] = field

  @removeField = (index) ->
    field = self.selected.table_fields[index]
    $translate("Sure?").then (title) ->
      if $window.confirm(title)
        TableField.delete template_id: self.selected.id, id: field.id, ->
          self.selected.table_fields.splice(index, 1)

  @select = (template) ->
    return unless confirmLeave()

    self.selected = Template.get(id: template.id)

  @save = ->
    self.selected.$update ->
      self.form.$setPristine()
      toaster.pop('success', "Шаблон збережено")
    , ->
      toaster.pop('error', "При збереженні виникли помилки")

  @delete = ->
    $translate("Sure?").then (title) ->
      if $window.confirm(title)
        self.selected.$delete ->
          self.select(self.templates[0])
          self.templates.splice(self.templates.indexOf(self.selected) - 1, 1)

  this

angular.module 'logisticsApp'
  .config ($stateProvider, $urlRouterProvider) ->
    'ngInject'
    $stateProvider
      .state 'app.settings.templates',
        url: '/templates'
        component: 'templates'
        data:
          permissions:
            only: 'editTemplates'

  .component 'templates',
    template: require('app/settings/templates/templates.html')
    controller: Controller
