Controller = ($translate, $stateParams, $scope, $window, $rootScope, $q, $filter, $localStorage, $uibModal, $http, $state, PaymentNew, Document, Client, Vehicle, Organization, Purse) ->
  "ngInject"

  self = this

  @$onInit = ->
    self.filters = {
      date_gteq: moment().subtract(1, 'weeks').toDate()
    }
    self.load()

  @clearFilters = ->
    self.filters = {}
    self.load()

  @load = ->
    self.isLoading = true

    params = {}

    params['q[at_gteq]'] = self.filters.date_gteq
    params['q[at_lteq]'] = self.filters.date_lteq

    $http.get('/api/reports/refills.json', params: params).then (response) ->
      self.report = response.data

  this

angular.module 'logisticsApp'
  .component 'refillsReport',
    template: require('app/reports/refills/refills.html')
    controller: Controller

  .config ($stateProvider, $urlRouterProvider) ->
    'ngInject'
    $stateProvider
      .state 'app.reports.refills',
        url: '/refills'
        component: 'refillsReport'
        data:
          permissions:
            only: 'listRefills'
