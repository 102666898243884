angular.module 'logisticsApp'
  .config ($stateProvider, $urlRouterProvider) ->
    'ngInject'

    $stateProvider
      .state 'app.trips.new',
        url: '/new'
        template: require('app/trips/form/form.html')
        controller: 'TripFormController'
        params:
          applicationId: null
          clientId: null
        data:
          permissions:
            only: 'createTrips'

