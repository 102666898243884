angular.module 'logisticsApp'
  .directive 'acmecalendar', ->

    CalendarController = ($scope, $timeout, Notification) ->
      'ngInject'
      vm = this


      date = new Date()
      d = date.getDate()
      m = date.getMonth()
      y = date.getFullYear()

      vm.callServer = (start, end, timezone, callback) ->
        q = {
          start: new Date(start).getTime() / 1000,
          end: new Date(end).getTime() / 1000,
          document_owner: vm.owner,
          type: vm.type
        }

        Notification.query(q).then (events) ->
          angular.copy(events, $scope.events)
          callback(events)

      $scope.events = []
      $scope.eventSources = [vm.callServer]

      $scope.changeView = (view, calendar) ->
        $('.calendar').fullCalendar('changeView', view)

      $scope.today = (calendar) ->
        $('.calendar').fullCalendar('today')

      $scope.overlay = $('.fc-overlay').first()

      vm.alertOnMouseOver = ( event, jsEvent, view ) ->
        event.start = moment(event.start).format('DD.MM.YYYY')

        vm.event = event
        $scope.overlay.removeClass('left right top').find('.arrow').removeClass('left right top pull-up')
        wrap = $(jsEvent.target).closest('.fc-event')
        cal = wrap.closest('.calendar')
        left = wrap.offset().left - cal.offset().left
        right = cal.width() - (wrap.offset().left - cal.offset().left + wrap.width())
        top = cal.height() - (wrap.offset().top - cal.offset().top + wrap.height())
        if right > $scope.overlay.width()
          $scope.overlay.addClass('left').find('.arrow').addClass('left pull-up')
        else if left > $scope.overlay.width()
          $scope.overlay.addClass('right').find('.arrow').addClass('right pull-up')
        else
          $scope.overlay.find('.arrow').addClass('top')

        if top < $scope.overlay.height()
          $scope.overlay.addClass('top').find('.arrow').removeClass('pull-up').addClass('pull-down')

        if wrap.find('.fc-overlay').length == 0
          wrap.append( $scope.overlay )
        null


      $scope.uiConfig = {
        calendar:{
          height: 450,
          header:{
            left: 'prev',
            center: 'title',
            right: 'next'
          },
          eventMouseover: vm.alertOnMouseOver,
          allDayDefault: true,
          firstDay: 1,
          monthNames: ['Январь','Февраль','Март','Апрель','Май','οюнь','οюль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь'],
          monthNamesShort: ['Янв.','Фев.','Март','Апр.','Май','Июнь','Июль','Авг.','Сент.','Окт.','Ноя.','Дек.'],
          dayNames: ["Воскресенье","Понедельник","Вторник","Среда","Четверг","Пятница","Суббота"],
          dayNamesShort: ["ВС","ПН","ВТ","СР","ЧТ","ПТ","СБ"],
          buttonText: {
            today: "Сегодня",
            month: "Месяц",
            week: "Неделя",
            day: "День"
          }
        }
      }

      return

    directive =
      restrict: 'E'
      template: require('app/components/notifications/calendar.html')
      scope:
        owner: '='
        type: '@'
      controller: CalendarController
      controllerAs: 'vm'
      bindToController: true
