angular.module 'logisticsApp'
  .factory "Vehicle", (RailsResource, railsSerializer) ->
    class Vehicle extends RailsResource
      @configure
        url: "/api/vehicles"
        name: "vehicle"
        serializer: railsSerializer ->
          @exclude('driver','attachments','title','driverFullname', 'driverPhoneNumbers')
          @nestedAttribute('attachments')
          @resource('attachments', 'Attachment')
          @add 'attachment_ids', (vehicle) ->
            if vehicle.attachments
              vehicle.attachments.map (attachment) -> attachment.id
          @add 'trailer_ids', (vehicle) ->
            [vehicle.trailer_id]

      subscribe: (scope) ->
        #@consumer = new ActionCableChannel("VehicleChannel", {id: @id})

        #callback = (attrs) =>
        #  angular.extend(this, this, attrs)

        #@consumer.subscribe(callback).then =>
        #  scope.$on "$destroy", =>
        #    @unsubscribe()


      unsubscribe: ->
        #@consumer.unsubscribe()
