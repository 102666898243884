angular.module 'logisticsApp'
  .controller 'UserSessionsCtrl', ($scope, $auth, $state) ->
    'ngInject'

    $auth.validateUser().then ->
      $state.go('app.trips.list')

    $scope.$on 'auth:login-error', (ev, reason) ->
      $scope.authError = reason.errors[0]

    $scope.login = ->
      $auth.submitLogin($scope.loginForm)
