angular.module 'logisticsApp'
  .factory "Crew", (RailsResource, railsSerializer) ->
    class Crew extends RailsResource
      @configure
        name: "crew"
        serializer: railsSerializer ->
          @exclude('priceKopiykas')
          @nestedAttribute('$vehicle', 'vehicle', '$driver')
          @resource('$vehicle', 'Vehicle')
          @resource('vehicle', 'Vehicle')
          @resource('$driver', 'Driver')

  .factory "Location", (RailsResource, railsSerializer) ->
    class Location extends RailsResource
      @configure
        name: "location"
        serializer: railsSerializer ->

  .factory "Trip", (RailsResource, railsSerializer, Vehicle, Crew, Location) ->
    class Trip extends RailsResource
      @configure
        url: "/api/trips"
        name: "trip"
        serializer: railsSerializer ->
          @exclude('amountKopiykas', 'amountCurrency','attachments','road')
          @nestedAttribute('attachments','notifications','payments', 'partnerPayments', '$client', 'client', 'crews', 'deliveries', 'downloadLocations', 'deliveryLocations', 'waypointLocations')
          @resource('payments', 'Payment')
          @resource('partnerPayments', 'Payment')
          @resource('attachments', 'Attachment')
          @resource('allAttachments', 'Attachment')
          @resource('document', 'Document')

          @resource('client', 'Client')
          @resource('$client', 'Client')

          @resource('crews', 'Crew')
          @resource('deliveries', 'Delivery')
          @resource('downloadLocations', 'Location')
          @resource('deliveryLocations', 'Location')
          @resource('waypointLocations', 'Location')
          @resource('allDocuments', 'Document')
          @resource('notifications', 'Notification')
          @add 'attachment_ids', (trip) ->
            if trip.attachments
              trip.attachments.map (attachment) -> attachment.id

          @add 'download_at', (trip) ->
            if trip.downloadAt
              moment(trip.downloadAt).format("YYYY-MM-DD")

          @add 'delivered_at', (trip) ->
            if trip.deliveredAt
              moment(trip.deliveredAt).format("YYYY-MM-DD")

      build_crew: ->
        @crews.push(new Crew(price: 0, vehicle: (new Vehicle), '$vehicleType': 'permanent_vehicle'))

      destroy_crew: (index) ->
        if @crews[index].id?
          @crews[index]._destroy = true
        else
          @crews.splice(index, 1)

      addOrigin: ->
        @downloadLocations.push(new Location(kind: 'download'))

      removeOrigin: (index) ->
        if @downloadLocations[index].id
          @downloadLocations[index]._destroy = true
        else
          @downloadLocations.splice(index, 1)

      addTarget: ->
        @deliveryLocations.push(new Location(kind: 'upload'))

      removeTarget: (index) ->
        if @deliveryLocations[index].id
          @deliveryLocations[index]._destroy = true
        else
          @deliveryLocations.splice(index, 1)

      addWaypoint: ->
        @waypointLocations.push(new Location(kind: 'waypoint'))

      removeWaypoint: (index) ->
        if @waypointLocations[index].id
          @waypointLocations[index]._destroy = true
        else
          @waypointLocations.splice(index, 1)

