angular.module 'logisticsApp'
  .run ($log, $rootScope, $state, $auth, $location, $window, editableOptions, $urlRouter, $templateCache, $http, Subscription, PermPermissionStore) ->
    'ngInject'
    $log.debug 'runBlock end'
    editableOptions.theme = 'bs3'
    vapidPublicKey = null

    $rootScope.googleMapsUrl="https://maps.googleapis.com/maps/api/js?libraries=places,geometry&key=AIzaSyD3Tzb4UEMh9FfirqJ5WrQ5tFWYaI_YZTg"

    $rootScope.$on 'auth:validation-success', (ev, user) ->
      console.log "Validation success"
      vapidPublicKey = new Uint8Array(user.vapid_public)
      subscribePushNotifications()

    sendKeys = (subscription) ->
      console.log "Sending keys #{subscription}"
      $http.post '/api/web_push_subscriptions.json', web_push_subscription: subscription

    getKeys = ->
      if navigator.serviceWorker?
        console.log('Service Worker is supported')
        navigator.serviceWorker.register('/serviceworker.js', {scope: './'}).then((registration) ->
          console.log('Service worker change, registered the service worker')
          registration.pushManager.getSubscription().then (subscription) ->
            if subscription
              return subscription

            registration.pushManager.subscribe userVisibleOnly: true, applicationServerKey: vapidPublicKey
          ).then (subscription) ->
            sendKeys(subscription.toJSON())

    subscribePushNotifications = ->
       unless window.Notification
         console.error("This browser does not support desktop notification")
       else if Notification.permission == "granted"
         console.log("Permission to receive notifications has been granted")
         getKeys()
       else if Notification.permission != 'denied'
         Notification.requestPermission (permission) ->
           if permission == "granted"
             console.log("Permission to receive notifications has been granted")
             getKeys()

    reloadPermissions = (permissions) ->
      $http
        .get('/api/permissions')
        .then((response) ->
          permissions = response.data
          PermPermissionStore.defineManyPermissions permissions, (permissionName) ->
            permissions.indexOf(permissionName) > -1
        ).finally ->
          $urlRouter.sync()
          $urlRouter.listen()

    $auth.validateUser()
    reloadPermissions()

    $rootScope.$on 'auth:login-success', (ev, user) ->
      reloadPermissions().then ->
        perm = PermPermissionStore.getPermissionDefinition('readTrips')
        if perm
          $state.go('app.trips.list')
        else
          $state.go('app.maintenance.materials')

    $rootScope.$on 'auth:validation-error', (ev, reason) ->
      $location.path('/user_sign_in')
    $rootScope.$on 'auth:session-expired', (ev, reason) ->
      $location.path('/user_sign_in')
    $rootScope.$on 'auth:invalid', (ev, reason) ->
      $location.path('/user_sign_in')
    $rootScope.$on 'auth:logout-success', (ev, reason) ->
      $location.path('/user_sign_in')

    $rootScope.goBack = ->
      $window.history.back()

    $templateCache.put('app/header/header.html', require('app/header/header.html'))
    $templateCache.put('app/aside/aside.html', require('app/aside/aside.html'))
    $templateCache.put('app/nav/nav.html', require('app/nav/nav.html'))
    $templateCache.put('app/components/attachments/slide.html', require('app/components/attachments/slide.html'))
