angular.module 'logisticsApp'
  .controller 'ListDocumentController', ($scope, $resource, $stateParams, $auth, $timeout, $filter, uiCalendarConfig) ->
    'ngInject'

    Document = $resource('/api/documents/:id.json', {id: '@id'}, {
      headers: $auth.retrieveData('auth_headers'),
      query:
        isArray: true,
        transformResponse: (data) ->
          angular.fromJson(data).documents
    })

    $scope.type = $stateParams.type || $scope.types[0].value
    $scope.displayedCollection = []

    $scope.callServer = (tableState) ->
      $scope.isLoading = true
      $scope.tableState = tableState

      pagination = tableState.pagination
      search = tableState.search.predicateObject or {}
      sort = tableState.sort or {}

      $scope.startIndex = pagination.start

      params =
        page: (pagination.start || 0) / pagination.number + 1
        number: pagination.number
        type: $scope.type
        'q[number_cont]': search.number
        'q[documentable_of_Driver_type_fullname_cont]': search.documentable_of_Driver_type_fullname_cont
        'q[documentable_of_Vehicle_type_number_cont]': search.documentable_of_Vehicle_type_number_cont
        'q[template_id_eq]': $stateParams.template_id

      Document.query params, (documents, responseHeaders) ->
        $scope.displayedCollection = documents
        $scope.isLoading = false
        tableState.pagination.numberOfPages = parseInt(responseHeaders()['x-total-pages'])

    # TODO: refactor
    $scope.$watch 'app.settings.table_mode', (table_mode) ->
      if uiCalendarConfig.calendars['notificationsCalendar']
        $timeout ->
          #uiCalendarConfig.calendars['notificationsCalendar'].fullCalendar('refetchEvents')
          $('.calendar').fullCalendar('changeView', 'month')
        , 200

  .config ($stateProvider, $urlRouterProvider) ->
    'ngInject'
    $stateProvider
      .state 'app.documents.list',
        url: '/list/{type}?template_id'
        template: require('app/documents/list.html')
        controller: 'ListDocumentController'
        params:
          type: 'Passport'
        data:
          permissions:
            only: 'listDocuments'

