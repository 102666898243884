angular.module 'logisticsApp'
  .factory "Task", ($resource) ->
    transformAttributes = (task) ->
      attrs = {}
      angular.copy task, attrs

      delete task.trip
      if attrs.deadline_date
        attrs.deadline_at = moment(attrs.deadline_date).format('YYYY-MM-DD')
      
      angular.toJson(attrs)

    transformResponseAttributes = (data) ->
      attrs = angular.fromJson(data)
      if attrs.deadline_at
        attrs.deadline_date = moment(attrs.deadline_at).toDate()
      
      attrs

    $resource('/api/tasks/:id.json', {id: '@id'}, {
      get: { method: 'GET', transformResponse: transformResponseAttributes },
      save: { method: 'POST', transformRequest: transformAttributes },
      update: { method: 'PATCH', transformRequest: transformAttributes }
    })

