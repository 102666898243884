import "app/common/services/organization_new.service";
import "app/common/services/user_new.service";

Controller = ($rootScope, $translate, $window, $uibModal, $state, OrganizationNew, UserNew) ->
  "ngInject"

  consumer = null
  self = this

  @$onInit = ->
    self.purse = self.resolve.purse

    self.organizations = OrganizationNew.query()
    self.users = UserNew.query()

  @cancel = ->
    self.dismiss({$value: 'cancel'})

  @save = ->
    if self.purse.id
      self.purse.$update ->
        self.close({$value: self.purse})
    else
      self.purse.$save ->
        self.close({$value: self.purse})

  @delete = ->
    $translate("Sure?").then (title) =>
      if $window.confirm(title)
        self.purse.$delete ->
          self.close({$value: null})

  this
 
angular.module 'logisticsApp'
  .component 'purseModal',
    template: require('app/purses/modal/modal.html')
    controller: Controller
    bindings:
      resolve: '<'
      close: '&'
      dismiss: '&'
