angular.module 'logisticsApp'
  .factory "Organization", (RailsResource, railsSerializer) ->
    class Organization extends RailsResource
      @configure
        url: "/api/organizations"
        name: "organization"
        serializer: railsSerializer ->
          @exclude('accountTemplateOriginalName')
          @exclude('actTemplateOriginalName')
          @nestedAttribute('users')
          @resource('users', 'User')
