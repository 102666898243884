angular.module 'logisticsApp'
  .directive 'callToPhoneNumber', (toaster, $http, $parse, $filter, $translate, $window, PermPermissionStore) ->
    "ngInject"

    link = (scope, element, attrs, model) ->
      permissions = PermPermissionStore.getPermissionDefinition('performPhoneCalls')

      element.bind 'click', (e) ->
        if permissions?
          $translate("Sure to call?").then (title) ->
            if $window.confirm(title)
              e.preventDefault()

              target_phone_number = $parse(attrs.callToPhoneNumber)(scope)

              $http.post('/api/phone_calls/request_callback.json', to: target_phone_number).then (response) ->
                toaster.pop('info', $filter('translate')('Calling'))
        else
          toaster.pop('error', $filter('translate')('Calling not allowed'))

        false
        
    directive =
      restrict: 'A',
      link: link
