angular.module 'logisticsApp'
  .controller 'ContactsController', ($scope, $filter, $translate, $window, $q, Driver, User, Partner, Organization) ->
    'ngInject'

    $scope.filter = 'Users'
    $scope.groups = [
      {name: 'Users', model: User, total: 0},
    ]
    $scope.group = $scope.groups[0]
    $scope.roles = [
      'director',
      'logist',
      'logist_ex',
      'bookkeeper',
      'lawyer',
      'admin',
      'stock_manager'
    ]

    $scope.types = [
      'phone',
      'viber',
    ]

    $scope.app.selectedContactGroup ||= 'Users'


    $scope.selectGroup = (item) ->
      return if $scope.form and $scope.form.$visible

      $scope.app.selectedContactGroup = item.name

      angular.forEach $scope.groups, (item) ->
        item.selected = false

      $scope.group = item
      $scope.group.selected = true
      $scope.filter = item.name

    $scope.selectGroup($filter('filter')($scope.groups, name: $scope.app.selectedContactGroup)[0])

    $scope.contact_types = ['tel','skype','e-mail']

    $scope.items = []

    itemsLoaded = (items, group) ->
      $scope.items = $scope.items.concat(items)
      if $scope.app.selectedContactUserId
        if group == $scope.app.selectedContactGroup
          $scope.item = $filter('filter')(items, id: $scope.app.selectedContactUserId)[0]
          $scope.selectItem($scope.item) if $scope.item
      else
        $scope.item = $filter('orderBy')($scope.items, 'name')[0]
        $scope.selectItem($scope.item) if $scope.item


    User.query().then (items) ->
      $scope.groups[0].total = items.length
      itemsLoaded(items, 'Users')

    Organization.query().then (items) ->
      $scope.organizations = items

    $scope.selectItem = (item) ->
      return if $scope.form.$visible

      $scope.app.selectedContactUserId = item.id

      $scope.form.$cancel()
      angular.forEach $scope.items, (item) ->
        item.selected = false

      item.get().then ->
        $scope.item = item
        $scope.item.selected = true

        if item.group == 'Users'
          for org in $scope.organizations
            org.selected = false
          for id in $scope.item.organizationIds
            $filter('filter')($scope.organizations, {id: id})[0].selected = true

    $scope.doneEditing = (item) ->
      item.organizationIds = $filter('filter')($scope.organizations, {selected: true}).map((o) -> o.id)
      item.save()

    $scope.deleteItem = (item) ->
      $translate("Sure?").then (title) ->
        if $window.confirm(title)
          item.remove().then ->
            $scope.items.splice($scope.items.indexOf(item), 1)
            $scope.item = $filter('orderBy')($scope.items, 'name')[0]
            if $scope.item
              $scope.item.selected = true

    $scope.createItem = ->
      item = new $scope.group.model
      item.group = $scope.group.name
      $scope.items.unshift(item)
      $scope.item = item
      $scope.item.selected = true
      $scope.form.$show()

    $scope.addPhoneNumber = ->
      $scope.item.contacts ||= []
      $scope.item.contacts.push({tel: '', comment: '', type: 'phone'})

    $scope.removePhoneNumber = (index) ->
      $scope.item.contacts.splice(index, 1)
