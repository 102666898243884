angular.module 'logisticsApp'
  .factory "Garage", ($resource, $auth) ->

    transformAttributes = (garage) ->
      attrs = {}
      angular.copy garage, attrs
      attrs.bounds = garage.bounds[0].concat(garage.bounds[1]) if garage and garage.bounds and garage.bounds[0].length

      angular.toJson(attrs)
    
    transformResponseAttributes = (data) ->
      angular.fromJson(data).map (attrs) ->
        attrs.bounds = [[attrs.bounds[0], attrs.bounds[1]], [attrs.bounds[2], attrs.bounds[3]]] if attrs.bounds
        attrs

    $resource('/api/garages/:id.json', {id: '@id'}, {
      query: { isArray: true, transformResponse: transformResponseAttributes },
      get: { method: 'GET', transformResponse: transformResponseAttributes },
      update: { method: 'PATCH', transformRequest: transformAttributes },
      save: { method: 'POST', transformRequest: transformAttributes }
    })

