angular.module 'logisticsApp'
  .controller 'TransferController', ($scope, $uibModalInstance, Spare, Vehicle, Operation, spare) ->
    'ngInject'

    $scope.spare = spare
    $scope.item = new Operation(kind: 'transfer', spareId: spare.id)

    Vehicle.query().then (vehicles) ->
      $scope.vehicles = vehicles

    $scope.save = ->
      $scope.item.save().then ->
        $uibModalInstance.close($scope.item)

    $scope.cancel = ->
      $uibModalInstance.dismiss('cancel')

