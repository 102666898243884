Controller = (WayBill, $filter) ->
  "ngInject"

  self = this

  @displayedCollection = []

  @callServer = (tableState) =>
    @isLoading = true
    @tableState = tableState

    pagination = tableState.pagination
    search = tableState.search.predicateObject or {}
    sort = tableState.sort or {}

    @startIndex = pagination.start

    params =
      page: (pagination.start || 0) / pagination.number + 1
      number: pagination.number
      'q[vehicle_number_cont]': search.vehicle_number_cont
      'q[driver_surname_cont]': search.driver_fullname_cont
      'q[date_gteq]': self.date_gteq_filter
      'q[date_lteq]': self.date_lteq_filter
      "q[sorts]": "#{sort.predicate} #{if sort.reverse then 'desc' else 'asc'}"

    WayBill.query params, (items, responseHeaders) =>
      @displayedCollection = items
      @isLoading = false
      tableState.pagination.numberOfPages = parseInt(responseHeaders()['x-total-pages'])

  this
 
angular.module 'logisticsApp'
  .component 'waybills',
    template: require('app/components/way_bills/way_bills.html')
    controller: Controller
