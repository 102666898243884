import "app/common/services/trailer.service";

Controller = ($rootScope, $uibModal, $translate, $window, Trailer, ActionCableChannel) ->
  "ngInject"

  consumer = null
  self = this

  @load = ->
    self.trailers = Trailer.query(self.filters)

  @$onInit = ->
    self.filters = {}
    self.load()

  @edit = (index) ->
    trailer = self.trailers[index]

    modalInstance = $uibModal.open
      animation: true,
      component: 'trailerModal'
      size: 'lg',
      resolve:
        trailer: -> Trailer.get(id: trailer.id).$promise

    modalInstance.result.then (updated_trailer) ->
      if updated_trailer == 'deleted'
        self.trailers.splice(index, 1)
      else
        angular.extend(trailer, updated_trailer)

  this
 
angular.module 'logisticsApp'
  .component 'trailersList',
    template: require('app/trailers/list/list.html')
    controller: Controller
    bindings:
      staticParams: '<'

  .config ($stateProvider, $urlRouterProvider) ->
    'ngInject'
    $stateProvider
      .state 'app.trailers',
        url: '/trailers'
        component: 'trailersList'
        data:
          permissions:
            only: 'listVehicles'
