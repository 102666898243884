Controller = ($rootScope, $translate, $window, TableField) ->
  "ngInject"

  self = this

  @$onInit = ->
    self.types = ['string', 'text', 'number', 'date']
    self.table_field = self.resolve.table_field

  @cancel = ->
    self.dismiss({$value: 'cancel'})

  @save = ->
    self.saving = true

    success = ->
      self.close({$value: self.table_field})
      self.saving = false

    failed = ->
      self.saving = false

    if self.table_field.id
      self.table_field.$update(success, failed)
    else
      self.table_field.$save(success, failed)

  this
 
angular.module 'logisticsApp'
  .component 'tableFieldModal',
    template: require('app/settings/templates/table-field/table-field.html')
    controller: Controller
    bindings:
      resolve: '<'
      close: '&'
      dismiss: '&'
