angular.module 'logisticsApp'
  .config ($translateProvider) ->
    'ngInject'

    $translateProvider.useStaticFilesLoader
      prefix: 'app/l10n/',
      suffix: '.json'

    $translateProvider.preferredLanguage('ru_RU')
    $translateProvider.useLocalStorage()

  .config ($authProvider) ->
    'ngInject'

    $authProvider.configure
      apiUrl:  '/api'
      proxyIf: -> window.isOldIE()
      forceValidateToken: true
      validateOnPageLoad: false
      storage: 'cookies'
        
  .config ($httpProvider) ->
    'ngInject'

    # toggle loader on http requests
    $httpProvider.interceptors.push ($q, $rootScope) ->
      {
        responseError: (rejection) ->
          if rejection.status == 403
            $rootScope.showErrorMessage(rejection.data.errors[0])
          else if rejection.status == 500
            $rootScope.showErrorMessage('Internal server error. Please contact the support')
          else if rejection.status == 422
            if rejection.data.errors.length
              for err in rejection.data.errors
                $rootScope.showErrorMessage(err)
            else
              for err of rejection.data.errors
                $rootScope.showErrorMessage(rejection.data.errors[err][0])

          $q.reject(rejection)
      }

