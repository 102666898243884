import "app/tasks/tasks.component";
import "app/payments/table/table.component";
import "app/trailers/list/list.component";
import "app/planning/planning.component";
import "app/maintenance/maintenance.component";
import "app/clients/table/table.component";
import "app/clients/client/client.component";

angular.module 'logisticsApp'
  .config ($stateProvider, $urlRouterProvider) ->
    'ngInject'
    $stateProvider
      .state 'app',
        abstract: true
        url: '/app'
        template: require('app/app.html')
        resolve:
          auth: ($auth, $state) ->
            'ngInject'
            $auth.validateUser()
        data:
          permissions:
            redirectTo: 'user_sign_in'

      .state 'app.way_bills',
        url: '/way_bills'
        component: 'waybills'
        data:
          permissions:
            only: 'listWaybills'

      .state 'app.waybill',
        url: '/waybill/{id}'
        component: 'waybill'
        resolve:
          waybill: ($stateParams, WayBill) ->
            "ngInject"
            WayBill.get(id: $stateParams.id).$promise


      .state 'app.contacts',
        url: '/contacts'
        template: require('app/contacts/contacts.html')
        controller: 'ContactsController'
        data:
          permissions:
            only: 'listContacts'

      .state 'app.organizations',
        url: '/organizations'
        template: require('app/organizations/organizations.html')
        controller: 'OrganizationsController'
      .state 'app.vehicles',
        url: '/vehicles'
        component: 'vehicles'
        resolve:
          queryScope: -> 'active'
        data:
          permissions:
            only: 'listVehicles'

      .state 'app.vehicle',
        url: '/vehicles/{id}'
        component: 'vehicle'
        resolve:
          vehicle: ($stateParams, Vehicle) ->
            "ngInject"
            Vehicle.get($stateParams.id)

      .state 'app.new_vehicle',
        url: '/vehicles/new'
        component: 'vehicle'
        resolve:
          vehicle: ($stateParams, Vehicle) ->
            "ngInject"
            new Vehicle()

      .state 'app.trips',
        url: '/trips',
        template: require('app/trips/trips.html')
        controller: 'TripsController'
        data:
          permissions:
            only: 'readTrips'
      .state 'app.trips.list',
        url: '/list/?vehicleId'
        component: 'trips'
        resolve:
          staticParams: ->
            {'q[active]': true}
          vehicleId: ($stateParams) ->
            $stateParams.vehicleId

      .state 'app.trips.defect',
        url: '/defect/'
        component: 'trips'
        resolve:
          staticParams: ->
            {'q[defect]': true}

      .state 'app.trips.show',
        url: '/show/:id'
        component: 'trip'
        resolve:
          trip: ($stateParams, Trip) ->
            "ngInject"
            Trip.get($stateParams.id)
      .state 'app.trips.edit',
        url: '/edit/:id'
        template: require('app/trips/form/form.html')
        controller: 'EditTripController'
        data:
          permissions:
            only: 'updateTrips'

      .state 'app.archive',
        url: '/archive'
        abstract: true
        data:
          permissions:
            only: 'readArchive'

      .state 'app.archive.trips',
        url: '/trips'
        component: 'trips'
        resolve:
          staticParams: ->
            {'q[archive]': true}
        data:
          permissions:
            only: 'readTrips'

      .state 'app.archive.defected_trips',
        url: '/trips/defected'
        component: 'trips'
        resolve:
          staticParams: ->
            {'q[scope]': 'defect'}
        data:
          permissions:
            only: 'readTrips'

      .state 'app.archive.clients',
        url: '/clients'
        component: 'clients'
        resolve:
          queryScope: -> 'archived'
        data:
          permissions:
            only: 'readClients'

      .state 'app.archive.vehicles',
        url: '/vehicles'
        component: 'vehicles'
        resolve:
          queryScope: -> 'archived'
        data:
          permissions:
            only: 'listVehicles'


      .state 'app.spares',
        url: '/spares'
        template: require('app/spares/spares.html')
        controller: 'SparesController'

      .state 'app.ckt',
        url: '/ckt'
        template: require('app/ckt/ckt.html')
        controller: 'CKTController'
        data:
          permissions:
            only: 'seeCkt'

      .state 'app.refills',
        url: '/refills'
        template: require('app/refills/refills.html')
        controller: 'RefillsController'
        data:
          permissions:
            only: 'listRefills'

      .state 'app.shell_transactions',
        url: '/shell_transactions'
        template: require('app/shell_transactions/shell_transactions.html')
        controller: 'ShellTransactionsController'
        data:
          permissions:
            only: 'seeShell'

      .state 'app.motorway_tolls',
        url: '/motorway_tolls'
        template: require('app/motorway_tolls/motorway_tolls.html')
        controller: 'MotorwayTollsController'
        data:
          permissions:
            only: 'listMotorwayTolls'

      .state 'app.reports_general',
        url: '/reports_general'
        template: require('app/reports/general.html')
        controller: 'GeneralController'
      .state 'app.reports_vehicles',
        url: '/reports_vehicles'
        template: require('app/reports/vehicles.html')
        controller: 'VehiclesReportController'



      .state 'client_sign_in',
        url: '/sign_in'
        template: require('app/client_sessions/new.html')
        controller: 'ClientSessionsCtrl'
      .state 'client_sign_up',
        url: '/sign_up'
        template: require('app/client_registrations/new.html')
        controller: 'ClientRegistrationsCtrl'
      .state 'client_forgotpwd',
        url: '/forgot_password'
        template: require('app/client_registrations/reset.html')
        controller: 'ClientResetCtrl'

      .state 'user_sign_in',
        url: '/user_sign_in'
        template: require('app/user_sessions/new.html')
        controller: 'UserSessionsCtrl'

      .state 'app.imports',
        url: '/imports'
        template: require('app/imports/imports.html')
        controller: 'ImportsController'

      .state 'app.sign',
        url: '/sign/:id'
        template: require('app/sign/sign.html')
        controller: 'SignController'

      .state 'app.mail',
          abstract: true,
          url: '/mail',
          template: require('app/emails/mail.html')
      .state 'app.mail.list',
          url: '/folder/{kind}/{id}'
          template: require('app/emails/mail.list.html')
          params:
            id: ''
      .state 'app.mail.detail',
          url: '/detail/{id}'
          template: require('app/emails/mail.detail.html')

      .state 'app.history',
        url: '/history'
        template: require('app/history/history.html')

      .state 'app.reports',
        url: '/reports'
        abstract: true

    $urlRouterProvider.otherwise '/user_sign_in'
    $urlRouterProvider.deferIntercept()
