angular.module 'logisticsApp'
  .controller 'MailCtrl', ($scope, $filter, Mailbox, Stat, Vehicle) ->
    'ngInject'

    $scope.folds = [
      {name: 'INBOX', kind: 'inbox' }
      {name: 'Drafts', kind: 'drafts' }
      {name: 'Sent', kind: 'sent' }
      {name: 'Spam', kind: 'spam' }
      {name: 'Trash', kind: 'trash' }
    ]

    $scope.mailboxes =
      inbox: []
      drafts: []
      sent: []
      spam: []
      trash: []

    Mailbox.query().then (mailboxes) ->
      for i in mailboxes
        i.tooltip = if i.messages > 0 and i.unseen > 0
                      "Всего #{i.messages} писем, #{i.unseen} непрочитанных"
                    else if i.message > 0
                      "Всего #{i.messages} писем"
                    else
                      "Нет писем"

        unless i.kind == 'custom'
          i.title = i.account
          $scope.mailboxes[i.kind].push(i)
        else
          i.title = i.name
          if $scope.mailboxes[i.account]?
            $scope.mailboxes[i.account].push(i)
          else
            $scope.folds.push(name: i.account, kind: i.account, custom: true)
            $scope.mailboxes[i.account] = []
            $scope.mailboxes[i.account].push(i)


  .controller 'MailListCtrl', ($scope, Email, $stateParams) ->
    'ngInject'
    $scope.page = 0
    $scope.refresh = ->
      Email.query(page: $scope.page, kind: $stateParams.kind, mailbox_id: $stateParams.id).then (resp) ->
        $scope.mails = resp.emails
        $scope.totalItems = resp.total

    $scope.refresh()

  .controller 'MailDetailCtrl', ($scope, Email, $stateParams, $uibModal, $translate, $sce) ->
    'ngInject'

    Email.get($stateParams.id).then (mail) ->
      $scope.mail = mail
      $scope.htmlContent= $sce.trustAsHtml(mail.content)

    $scope.attachToTrip = ->
      modalInstance = $uibModal.open
        animation: true,
        template: require('app/components/trips/select.html'),
        controller: 'SelectTripController',
        size: 'md',
        resolve:
          attachments: ->
            console.log $scope.mail
            $scope.mail.attachments

      modalInstance.result.then ->
        $translate('Attached successfuly').then (t) ->
          toaster.pop('success', t, 'OK')

