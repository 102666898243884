angular.module('logisticsApp').directive('stCheck', function () {
  return {
    restrict: 'E',
    scope: {
      predicate: '@'
    },
    require: '^stTable',
    template: '<input type="checkbox" data-ng-model="sel" ng-change="stCheckChange()"> ',
    link: function (scope, element, attr, ctrl) {
      scope.stCheckChange = function stCheckChange() {
        if (scope.sel) {
          ctrl.search(scope.sel, scope.predicate);
        } else {
          // SmartTable search mechanism will throw out the search term if it is falsey
          ctrl.search('false', scope.predicate);
        }
      };
    }
  };
});
