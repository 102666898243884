ChatController = (ChatUser, Message, ActionCableChannel, Driver, Vehicle, $anchorScroll, $timeout, $filter, $http) ->
  'ngInject'

  self = this

  beep = ->
    document.getElementById("chat-new-message").play()

  @select = (chat_user, ev) ->
    ev.preventDefault() if ev

    ChatUser.get id: chat_user.id, (user) ->
      self.selected = user
      self.next_page = 1
      self.messages = []

      self.adapter.reload()

    false

  @sendMessage = ->
    Message.save chat_user_id: self.selected.id, body: self.body, ->
      self.body = null

  @consumer = new ActionCableChannel("TruckChatChannel")

  @consumer.subscribe (data) ->
    chat_user = $filter('filter')(self.chat_users, id: data.chat_user_id)[0]
    if data.unseen_messages_count?
      chat_user.unseen_messages_count = data.unseen_messages_count

    if data.event == 'new_message'
      if data.attrs.sender_type == 'Driver'
        beep()

      if data.chat_user_id == self.selected.id
        if self.adapter.isEOF()
          Message.get chat_user_id: data.chat_user_id, id: data.attrs.id, (message) ->
            self.adapter.append([message])
            $timeout ->
              scrollTo(message)
        else
          self.adapter.reload(0)

    else if data.event == 'message_delivered'
      if data.chat_user_id == self.selected.id
        message = $filter('filter')(self.messages, id: data.id)[0]
        if message
          message.delivered = true

    else if data.event == 'chat_user_updated'
      if chat_user
        angular.extend(chat_user, data.attrs)

  loadMessages = (index, count, success) ->
    return unless self.selected?

    console.log('index = ' + index + '; count = ' + count)

    start = index
    end = Math.min(index + count - 1, 1)

    console.log('start = ' + start + '; end = ' + end)

    if start <= end
      Message.query chat_user_id: self.selected.id, start: -1 * end + 1, limit: (end - start + 1), (messages) ->
        success(messages.reverse())
    else
      success([])

  @$onInit = ->
    self.datasource = {
      get: loadMessages
    }

    Driver.query().then (drivers) ->
      self.drivers = drivers
    Vehicle.query().then (vehicles) ->
      self.vehicles = vehicles

    ChatUser.query trip_id: self.tripId, (items) ->
      self.chat_users = items

      if items.length
        if self.chatUserId
          self.select(items.find (i) -> i.id == self.chatUserId)
        else
          self.select(items[0])


  @$onDestroy = ->
    self.consumer.unsubscribe()

  @selectDriver = (driver_id) ->
    ChatUser.update id: self.selected.id, driver_id: driver_id, (chat_user) ->
      self.selected = chat_user

  @selectVehicle = (vehicle_id) ->
    ChatUser.update id: self.selected.id, vehicle_id: vehicle_id, (chat_user) ->
      self.selected = chat_user

  @sendQuickActions = ->
    $http.post("/api/chat_users/#{self.selected.id}/send_quick_actions")

  scrollTo = (message)->
    id_message = "message-#{message.id}"
    $anchorScroll(id_message)

  this

angular.module 'logisticsApp'
  .component 'chat',
    template: require('app/chat/chat.html')
    controller: ChatController
    bindings:
      tripId: '<'
      chatUserId: '<?'

  .config ($stateProvider) ->
    "ngInject";

    $stateProvider
      .state 'app.chat',
        url: '/chat?chat_user_id'
        component: 'chat'
        resolve:
          chatUserId: ($stateParams) ->
            parseInt($stateParams.chat_user_id)
        data:
          permissions:
            only: 'useDriverChat'
