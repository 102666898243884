Controller = (Garage, $filter, $q, $timeout, $window) ->
  "ngInject"

  vm = this

  @$onInit = =>
    @garages = Garage.query()

  @create = =>
    Garage.save title: $filter('translate')('New garage'), bounds: [[48.5784315, 22.2099939], [48.660332, 22.3492813]], (garage) =>
      @garages.push(garage)
      $timeout =>
        @edit(garage)

  @edit = (garage) =>
    @forms[garage.id].$show()

  @updateBounds = ->
    for g in vm.garages
      if g.selected
        ne = this.getBounds().getNorthEast()
        sw = this.getBounds().getSouthWest()
        Garage.update id: g.id, bounds: [[sw.lat(), sw.lng()], [ne.lat(), ne.lng()]]

  @update = (garage, title) =>
    deferred = $q.defer()

    Garage.update id: garage.id, title: title, bounds: garage.bounds, (garage) ->
      deferred.resolve(true)
    , (resp) ->
      deferred.reject(resp)

    deferred.promise

  @delete = (garage) =>
    if $window.confirm($filter('translate')('Sure?'))
      garage.$delete().then =>
        @garages.splice(@garages.indexOf(garage), 1)

  @select = (garage) =>
    for g in @garages
      g.selected = false

    garage.selected = true
 
  this

angular.module 'logisticsApp'
  .config ($stateProvider, $urlRouterProvider) ->
    'ngInject'
    $stateProvider
      .state 'app.settings.garages',
        url: '/garages'
        component: 'garages'
        data:
          permissions:
            only: 'editGarages'

  .component 'garages',
    template: require('app/components/garages/garages.html')
    controller: Controller
