Controller = ($rootScope, $state, $uibModal, $translate, $window, $interval, $filter, $http, $auth, $httpParamSerializer, InterpipeTender, Pvd, VehicleNew, User, ActionCableChannel, PermPermissionStore, toaster) ->
  "ngInject"

  consumer = null
  self = this

  @loadBoards = ->
    params = {}

    for filter, value of self.filters
      params["q[#{filter}]"] = value

    if self.vehicles_scope?
      params["q[#{self.vehicles_scope}]"] = true

    $http.get('/api/vehicles/planning_directions.json', params: params).then (response) ->
      self.boards = response.data
      self.kanban.addBoards(response.data)

      $('.country-toggle').bind 'click', (ev) ->
        ev.preventDefault()

        country = ev.currentTarget.dataset.country
        console.log("toggle #{country}")

        if self.filteredCountry != country
          self.filteredCountry = country

          $(".vehicle").addClass('hide')
          $(".vehicle.target-#{country}").removeClass('hide')
        else
          self.filteredCountry = null
          $(".vehicle").removeClass('hide')

  @resetBoards = ->
    for board in self.boards
      self.kanban.removeBoard(board.id)

  @$onInit = ->
    permissions = PermPermissionStore.getStore()

    self.vehicles_scope = 'only_tents'

    User.query(role: 'logist').then (users) ->
      self.users = [id: null, fullname: $filter('translate')('All')].concat(users)

    $http.get('/api/trips/countries.json').then (response) ->
      self.countries = response.data

    self.kanban = new window.jKanban({
      element: '#jkanban-directions',
      gutter: '0',
      widthBoard: '350px',
      dragItems: permissions.updatePlanning,
      click: (el) ->
        vehicle_id = el.getAttribute('data-eid')
        $state.go('app.trips.list', vehicleId: vehicle_id)

      dropEl: (el, target, source, sibling) ->
        console.log(target.parentElement.getAttribute('data-id'))
        console.log(el, target, source, sibling)

        target_direction_id = target.parentElement.getAttribute('data-id')
        vehicle_id = el.getAttribute('data-eid')

        VehicleNew.update id: vehicle_id, planning_direction_id: target_direction_id, (updated_vehicle) ->
          console.log(updated_vehicle)

      dragendBoard: (el) ->
    })

    self.loadBoards()

  @reload = ->
    self.resetBoards()
    self.loadBoards()

  this
 
angular.module 'logisticsApp'
  .component 'planningDirections',
    template: require('app/planning/planning.html')
    controller: Controller
    bindings:
      staticParams: '<'

  .config ($stateProvider, $urlRouterProvider) ->
    'ngInject'
    $stateProvider
      .state 'app.planning',
        url: '/planning'
        component: 'planningDirections'
        data:
          permissions:
            only: 'readTrips'
