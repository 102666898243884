Controller = ($translate, $window, $uibModal, ClientNew, User, ActionCableChannel) ->
  "ngInject"

  self = this

  callback = (message) =>
    if message.event == 'create'
      #if $rootScope.user.id != message.user_id
      #  toaster.pop('info', $filter('translate')('Trip created'), 'OK')
      @load()

    else if message.event == 'sync'
      @load()

    else if message.event == 'update'
      @load()
      #item = $filter('filter')(@displayedCollection, id: message.resource_id)[0]

      #if item
      #  item.get()
      #  if $rootScope.user.id != message.user_id
      #    toaster.pop('info', $filter('translate')('Trip updated'), 'OK')

    else if message.event == 'destroy'
      item = $filter('filter')(@displayedCollection, id: message.resource_id)[0]

      if item
        @displayedCollection.splice(@displayedCollection.indexOf(item), 1)
        #if $rootScope.user.id != message.user_id
        #  toaster.pop('info', $filter('translate')('Trip deleted'), 'OK')
 
  @load = =>
    @callServer(@tableState)

  @$onInit = =>
    self.selected = []
    @displayedCollection = []

    consumer = new ActionCableChannel("ClientsChannel")
    consumer.subscribe(callback)

  @checkAll = ->
    for client in self.displayedCollection
      client.selected = self.allSelected

    if self.allSelected
      self.selected = self.displayedCollection.map (c) -> c.id
    else
      self.selected = []

  @checkItem = (client) ->
    if client.selected
      self.selected.push(client.id)
    else
      if (i = self.selected.indexOf(client.id)) > -1
        self.selected.splice(i, 1)

    self.allSelected = (self.selected.length == self.displayedCollection.length)

  @removeSelected = ->
    modalInstance = $uibModal.open
      template: require('app/clients/remove/remove.html'),
      controller: 'RemoveClientsCtrl',
      size: 'md',
      resolve:
        selectedIds: -> self.selected

    modalInstance.result.then ->
      self.callServer(self.tableState)
      self.selected = []

  @callServer = (tableState) =>
    @isLoading = true
    @tableState = tableState

    pagination = tableState.pagination
    search = tableState.search.predicateObject or {}
    sort = tableState.sort or {}

    @startIndex = pagination.start

    params =
      page: (pagination.start || 0) / pagination.number + 1
      number: pagination.number
      full: true

    params["q[#{@queryScope}]"] = true if @queryScope

    params['q[s]'] = "#{sort.predicate} #{if sort.reverse then 'desc' else 'asc'}"  if sort.predicate
    params['q[organization_cont]'] = search.organization if search.organization
    params['q[fullname_cont]'] = search.fullname if search.fullname
    params['q[phones_cont]'] = search.phones if search.phones
    params['q[email_cont]'] = search.email if search.email

    ClientNew.query params, (clients, responseHeaders) =>
      @displayedCollection = clients
      @isLoading = false
      tableState.pagination.numberOfPages = parseInt(responseHeaders()['x-total-pages'])

  this

angular.module 'logisticsApp'
  .component 'clients',
    template: require('app/clients/table/table.html')
    controller: Controller
    bindings:
      queryScope: '<?'

  .config ($stateProvider, $urlRouterProvider) ->
    'ngInject'
    $stateProvider
      .state 'app.clients',
        url: '/clients'
        component: 'clients'
        data:
          permissions:
            only: 'readClients'

      .state 'app.client',
        url: '/clients/{id}'
        component: 'client'
        resolve:
          client: ($stateParams, ClientNew) ->
            "ngInject"
            ClientNew.get(id: $stateParams.id).$promise

