Controller = ($http) ->
  "ngInject"

  self = this

  @$onInit = ->
    $http(
      method: 'GET'
      url: '/api/settings/notification_subscriptions.json'
    ).then (response) ->
      self.users = response.data.users
      self.notification_subscriptions = response.data.notification_subscriptions

  @save = (user) ->
    ids = []
    for s of user.notification_subscriptions
      if user.notification_subscriptions[s]
        ids.push(s)

    $http.patch "/api/users/#{user.id}", user: {notification_subscription_ids: ids}

  @update = (subscription) ->
    $http.patch "/api/settings/notification_subscriptions/#{subscription.id}.json", notification_subscription: {only_responsibles: subscription.only_responsibles}

  this

angular.module 'logisticsApp'
  .config ($stateProvider) ->
    "ngInject"

    $stateProvider
      .state 'app.settings.notification_subscriptions',
        url: '/notification_subscriptions'
        component: 'notificationSubscriptions'
        data:
          permissions:
            only: 'editNotificationSubscriptions'
 
  .component 'notificationSubscriptions',
    template: require('app/settings/notification_subscriptions/notification_subscriptions.html')
    controller: Controller
