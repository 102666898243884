angular.module 'logisticsApp'
  .controller 'PreviewController', ($scope, $uibModalInstance, items, index) ->

    $scope.slides = items

    angular.forEach $scope.slides, (slide) ->
      slide.active = false
      slide.image = true if slide.contentType.indexOf('image') > -1
      slide.pdf = true if slide.contentType.indexOf('pdf') > -1
      #slide.office = true if slide.contentType.indexOf('office') > -1
    $scope.slides[index].active = true

    $scope.dissmiss = ->
      $uibModalInstance.dismiss('cancel')
