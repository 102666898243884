import "app/common/services/maintenance_stock_transaction.service";

Controller = ($rootScope, $uibModal, $translate, $window, $http, $auth, $httpParamSerializer, MaintenanceStockTransaction, MaintenanceInvoice, ActionCableChannel) ->
  "ngInject"

  consumer = null
  self = this

  @prevPage = ->
    self.filters.page = self.pagination.prev_page
    self.load()

  @nextPage = ->
    self.filters.page = self.pagination.next_page
    self.load()

  @load = ->
    MaintenanceStockTransaction.query self.filters, (stock_transactions, responseHeaders) ->
      self.stock_transactions = stock_transactions

      self.summary = { amount: responseHeaders()['x-total-amount'] }

      self.pagination = {
        total_count: parseInt(responseHeaders()['x-total-count']),
        next_page: responseHeaders()['x-next-page'],
        prev_page: responseHeaders()['x-prev-page'],
      }
      self.pagination.first = (self.filters.page - 1) * self.filters.limit + 1
      self.pagination.last = self.pagination.first + stock_transactions.length - 1

  callback = (message) ->
    self.load()

  @$onInit = ->
    self.filters = {
      page: 1
      limit: 25
    }
    self.load()

    consumer = new ActionCableChannel("PermitsChannel")
    consumer.subscribe(callback)

  @edit = (stock_transaction) ->
    modalInstance = $uibModal.open(
      animation: true,
      component: 'maintenanceInvoiceModal'
      size: 'lg',
      resolve:
        invoice: -> MaintenanceInvoice.get(id: stock_transaction.invoice_id).$promise
    )


  this
 
angular.module 'logisticsApp'
  .component 'stockTransactionsList',
    template: require('app/maintenance/stock_transactions/list/list.html')
    controller: Controller

  .config ($stateProvider, $urlRouterProvider) ->
    'ngInject'
    $stateProvider
      .state 'app.maintenance.stock_transactions',
        url: '/stock_transactions'
        component: 'stockTransactionsList'
