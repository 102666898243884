angular.module 'logisticsApp'
  .controller 'SelectTripController', ($scope, $uibModalInstance, attachments, Trip) ->

    $scope.trip = new Trip
    $scope.attachments = attachments

    Trip.query().then (trips) ->
      $scope.trips = trips

    $scope.removeAttachment = (index) ->
      $scope.attachments.splice(index, 1)
      false
      
    $scope.attach = ->
      $scope.trip.attachments = attachments
      $scope.trip.$patch("/api/trips/#{$scope.trip.id}").then ->
        $uibModalInstance.close()

    $scope.dissmiss = ->
      $uibModalInstance.dismiss('cancel')
