angular.module 'logisticsApp'
  .controller 'NewPartnerCtrl', ($scope, $uibModalInstance, PartnerNew) ->
    "ngInject"

    $scope.partner = new PartnerNew

    $scope.ok = ->
      $scope.partner.$save ->
        $uibModalInstance.close($scope.partner)


    $scope.cancel = ->
      $uibModalInstance.dismiss('cancel')
