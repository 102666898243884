angular.module 'logisticsApp'
  .factory "Import", (RailsResource, railsSerializer) ->
    class Import extends RailsResource
      @configure
        url: "/api/imports"
        name: "import"
        serializer: railsSerializer ->
          @nestedAttribute('attachment')
          @resource('attachment', 'Attachment')

