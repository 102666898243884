Controller = ($rootScope, $translate, $window, $http, Vehicle, Driver, Pvd, PermitType, Permit) ->
  "ngInject"

  consumer = null
  self = this

  @$onInit = ->
    self.statuses = ['waiting_for_transfer', 'waiting_for_using']

    self.batch = {permits: [{pvd_received_date: new Date(), max_using_times: 1}]}

    Vehicle.query().then (vehicles) ->
      self.vehicles = vehicles

    Driver.query().then (drivers) ->
      self.drivers = drivers

    self.pvds = Pvd.query()
    self.permit_types = PermitType.query()

    $http.get('/api/permits/countries.json').then (response) ->
      self.countries = response.data

  @cancel = ->
    self.dismiss({$value: 'cancel'})

  @save = ->
    $http.post('/api/permits/batch_create.json', self.batch).then (response) ->
      self.close({$value: self.batch})

  @getPvds = (q) ->
    Pvd.query('q[title_cont]': q).$promise

  @getPermitTypes = (q) ->
    PermitType.query('q[title_cont]': q).$promise

  @add = ->
    self.batch.permits.push({pvd_received_date: new Date(), max_using_times: 1})

  @remove = (index) ->
    self.batch.permits.splice(index, 1)

  this
 
angular.module 'logisticsApp'
  .component 'permitBatchCreate',
    template: require('app/permits/batch-create/batch-create.html')
    controller: Controller
    bindings:
      resolve: '<'
      close: '&'
      dismiss: '&'
