Controller = ($rootScope, $auth, $httpParamSerializer, $q, TripNew, toaster, Client, Partner, User, Label, Vehicle, Document, Organization, $filter, $http, $localStorage, ActionCableChannel) ->
  "ngInject"

  consumer = null
  self = this

  callback = (message) ->
    if message.trip_id?
      trip = $filter('filter')(self.displayedCollection, id: message.trip_id)[0]

      if self.filters.organization_id_eq? and self.filters.organization_id_eq != ''
        return unless message.organization_id == self.filters.organization_id_eq

      if trip?
        if message.deleted_at?
          self.displayedCollection.splice(self.displayedCollection.indexOf(trip), 1)
        else
          if message.updated_at != trip.updated_at
            TripNew.get id: message.trip_id, row: true, (updated_trip) ->
              angular.extend(trip, updated_trip)
      #else
        #if self.params.page == 1
        #  if message.is_new and !message.deleted_at?
        #    TripNew.get id: message.trip_id, row: true, (new_trip) ->
        #      self.displayedCollection.unshift(new_trip)

  @$onInit = =>
    self.pagination = {
      page: 1
      limit: 25
    }

    User.query(with_trips: true).then (users) ->
      self.users = [id: null, fullname: $filter('translate')('All')].concat(users)

    Vehicle.query('q[with_trips]': true).then (vehicles) ->
      self.vehicles = [id: null, number: $filter('translate')('All')].concat(vehicles)

    if self.staticParams and self.staticParams['q[active]'] != true
      self.isArchive = true

    self.persistKey = "main-office-#{$rootScope.user.current_main_office_id}:trips-#{if self.isArchive then 'archive' else 'active'}"

    $localStorage.filters ||= {}

    if self.vehicleId?
      self.selectVehicle(parseInt(self.vehicleId))
    else
      self.filters = $localStorage.filters[self.persistKey]

      if self.filters?
        for filter, value of self.filters
          if (filter.indexOf('download_at') > -1) or (filter.indexOf('delivered_at') > -1)
            self.filters[filter] = moment(value).toDate() if value? and value.length > 0
      else
        self.filters = angular.copy(self.initialFilters)

    self.payment_methods = [
      {value: 'cashless', text: 'Cashless'},
      {value: 'cash', text: 'Cash'},
      {value: 'bank_card', text: 'Bank card'},
      {value: 'driver_cash', text: 'Driver cash'}
    ]

    @statuses = [
      {name: 'pending', text: 'Pending'},
      {name: 'waiting_account', text: 'trips.statuses.waiting_account'},
      {name: 'on_the_way', text: 'trips.statuses.on_the_way'},
      {name: 'waiting_act', text: 'Waiting Act'},
      {name: 'paid', text: 'Paid'},
      {name: 'not_paid', text: 'Not Paid'},
    ]

    @results = [
      {name: 'successful', text: 'trips.results.successful'},
        {name: 'failed', text: 'trips.results.failed'},
        {name: 'defect', text: 'trips.results.defect'}
    ]

    @dates =
      opened: false
      opened1: false

    @filter =
      clientId: null
      partnerId: null
      userId: null

    @status =
      opened1: false
      opened2: false
      opened21: false
      opened22: false

    Organization.query().then (organizations) =>
      @organizations = organizations

    consumer = new ActionCableChannel("TripsChannel", main_office_id: $localStorage.main_office_id)
    consumer.subscribe(callback)

    self.load()

  @$onDestroy = ->
    consumer.unsubscribe() if consumer

  @getPartners = (q) ->
    Partner.query('q[organization_cont]': q, 'q[active]': true, only: 'organization').then (partners) ->
      partners.map (p) -> p.organization

  @getLabels = (q) ->
    Label.query(q: q).then (labels) ->
      labels.map (l) ->
        l.title

  @refreshClients = (q) ->
    $q (resolve, reject) ->
      Client.query('q[organization_cont]': q, 'q[active]': true, only: 'organization').then (clients) ->
        self.clients = [id: null, organization: $filter('translate')('All')].concat(clients)
        resolve()
      , reject

  @getAccounts = (q) ->
    Document.query(type: 'Account', q: q, page: 1).then (accounts) ->
      accounts.map (a) ->
        a.title

  @selectVehicle = (vehicleId) ->
    self.filters ||= {}
    self.filters.crews_vehicle_id_eq = vehicleId
    self.load()
    true

  @selectYear = (year) ->
    self.filters ||= {}
    if year
      self.filters.download_at_gteq = moment([year, 0, 1]).toDate()
      self.filters.download_at_lteq = moment([year, 11, 31]).toDate()
    else
      self.filters.download_at_gteq = null
      self.filters.download_at_lteq = null
      
    self.load()
    true

  @clearFilters = ->
    self.filters = {}
    self.selectYear((new Date()).getFullYear())
    self.load()

  @clearFilter = (attr) ->
    delete self.filters[attr]
    self.load()

  @load = ->
    self.isLoading = true

    params = angular.copy(self.pagination)
    params.totals = true

    angular.extend(params, self.staticParams) if self.staticParams

    for filter, value of self.filters
      if filter == 'scopes'
        for _,filter_scope of value
          params["q[#{filter_scope}]"] = true if filter_scope? and filter_scope.length > 0
      else
        params["q[#{filter}]"] = value

    $localStorage.filters[self.persistKey] = self.filters

    TripNew.getList params, (data, responseHeaders) ->
      self.performedParams = angular.copy(params)

      self.trips = data.trips
      self.summary = data.summary
      self.countries = data.countries

      self.pagination.total_count = parseInt(responseHeaders()['x-total-count'])
      self.pagination.page = parseInt(responseHeaders()['x-current-page'])
      self.pagination.first = parseInt(responseHeaders()['x-first'])
      self.pagination.last = parseInt(responseHeaders()['x-last'])
      self.years = data.availableYears

      self.isLoading = false

  @export = ->
    toaster.pop('success', 'Exporting started', self.pagination.total_count.toString() + ' trips')

    params = angular.extend($auth.retrieveData('auth_headers'), self.performedParams)
    params['_'] = (new Date).getTime()

    self.downloadUrl = "/api/trips/export.xlsx?#{$httpParamSerializer(params)}"

  this
 
angular.module 'logisticsApp'
  .component 'trips',
    template: require('app/trips/list/list.html')
    controller: Controller
    bindings:
      staticParams: '<'
      vehicleId: '<?'
