'use strict';

/* Filters */
// need load the moment.js to use this filter. 
angular.module('logisticsApp')
	.filter('decodePolyline', function() {
		return function(encodedPolyline) {
			return google.maps.geometry.encoding.decodePath(encodedPolyline);
		}
	});
