angular.module 'logisticsApp'
  .controller 'DirectionsController', ($scope, $translate, $window, $filter, $http, $interval, $timeout, Vehicle, Direction, NgMap) ->
    'ngInject'

    $scope.show_settings = false
    $scope.new_direction = new Direction()

    $scope.createDirection = ->
      $scope.new_direction.save().then ->
        $scope.directions.push($scope.new_direction)
        $scope.new_direction = new Direction()

    $scope.deleteDirection = (direction) ->
      $translate("Sure?").then (title) ->
        if $window.confirm(title)
          direction.remove().then ->
            $scope.directions.splice($scope.directions.indexOf(direction), 1)

    $scope.load = ->
      $http(
        method: 'GET'
        url: '/api/directions/board.json'
      ).then (response) ->
        $scope.directions = response.data

    $scope.load()
