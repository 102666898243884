Controller = ($rootScope, $translate, $window, $http, Vehicle, Driver, Pvd, PermitType, FuelProvider) ->
  "ngInject"

  consumer = null
  self = this

  @$onInit = ->
    self.fuel_card = self.resolve.fuel_card

    self.fuel_providers = FuelProvider.query()

    Vehicle.query().then (vehicles) ->
      self.vehicles = vehicles

  @cancel = ->
    self.dismiss({$value: 'cancel'})

  @save = ->
    if self.fuel_card.id
      self.fuel_card.$update ->
        self.close({$value: self.fuel_card})
    else
      self.fuel_card.$save ->
        self.close({$value: self.fuel_card})

  @delete = ->
    $translate("Sure?").then (title) =>
      if $window.confirm(title)
        self.fuel_card.$delete ->
          self.close({$value: self.fuel_card})

  this
 
angular.module 'logisticsApp'
  .component 'fuelCardModal',
    template: require('app/fuel_cards/modal/modal.html')
    controller: Controller
    bindings:
      resolve: '<'
      close: '&'
      dismiss: '&'
