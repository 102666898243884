Controller = ($translate, $window, $q, toaster, ClientNew, User, Trip, ActionCableChannel) ->
  "ngInject"

  self = this

  @cableCallback = (message) =>
    return unless message.resource_id == @client.id

    if message.event == 'update'
      @client.$get()

      #if $rootScope.user.id != message.user_id
      #  $mdToast.showSimple($filter('translate')('Item updated'))

    else if message.event == 'destroy'
      $state.go('app.clients')
      #if $rootScope.user.id != message.user_id
      #  $mdToast.showSimple($filter('translate')('Item deleted'))

  @$onInit = =>
    @consumer = new ActionCableChannel("ClientsChannel")
    @consumer.subscribe(@cableCallback)

  @loadUsers = (q) =>
    User.query(q: q).then (users) =>
      @users = users

  @updateUsers = (users) =>
    ClientNew.update(id: @client.id, client: {user_ids: users.map (u) -> u.id})

  @loadTrips = (params) ->
    params['q[client_id_eq]'] = self.client.id
    Trip.query(params)

  @save = ->
    self.client.$update().then ->
      toaster.pop('success', "Зміни клієнта збережено")

  @addPhone = ->
    self.client.phones ||= []
    self.client.phones.push('')

  @removePhone = (index) ->
    $translate("Sure?").then (title) =>
      if $window.confirm(title)
        self.client.phones.splice(index, 1)
        self.save()

  @enableSignIn = ->
    self.client.$enableSignIn()

  @disableSignIn = ->
    self.client.$disableSignIn()

  this

angular.module 'logisticsApp'
  .component 'client',
    template: require('app/clients/client/client.html')
    controller: Controller
    bindings:
      client: '<'
