import moment from  "moment";

angular.module('logisticsApp')
  .constant 'moment', moment
  .constant 'JQ_CONFIG', {
    sparkline:      [   '../libs/jquery/jquery.sparkline/dist/jquery.sparkline.retina.js'],
    plot:           [   '../libs/jquery/flot/jquery.flot.js',
                          '../libs/jquery/flot/jquery.flot.pie.js',
                          '../libs/jquery/flot/jquery.flot.resize.js',
                          '../libs/jquery/flot/jquery.flot.time.js',
                          '../libs/jquery/flot.tooltip/js/jquery.flot.tooltip.min.js',
                          '../libs/jquery/flot.orderbars/js/jquery.flot.orderBars.js',
                          '../libs/jquery/flot-spline/js/jquery.flot.spline.min.js'],
    wysiwyg:        [   '../libs/jquery/bootstrap-wysiwyg/bootstrap-wysiwyg.js',
                          '../libs/jquery/bootstrap-wysiwyg/external/jquery.hotkeys.js'],

    chosen:         [   '../libs/jquery/chosen/chosen.jquery.min.js',
                          '../libs/jquery/chosen/bootstrap-chosen.css'],

    tagsinput:      [   '../libs/jquery/bootstrap-tagsinput/dist/bootstrap-tagsinput.js',
                        '../libs/jquery/bootstrap-tagsinput/dist/bootstrap-tagsinput.css']
  }

