angular.module 'logisticsApp'
  .factory "TripNew", ($resource) ->

    transformAttributes = (trip) ->
      attrs = {}
      angular.copy trip, attrs

      angular.toJson({trip: attrs})

    $resource('/api/trips/:id.json', {id: '@id'}, {
      getList: { method: 'GET' },
      save: { method: 'POST', transformRequest: transformAttributes },
      update: { method: 'PATCH', transformRequest: transformAttributes },
      copy: { method: 'POST', url: '/api/trips/:id/copy.json' },
    })

