import "app/common/services/maintenance_material.service";

Controller = ($rootScope, $translate, $window, MaintenanceMaterial) ->
  "ngInject"

  consumer = null
  self = this

  @$onInit = ->
    self.material = self.resolve.material

  @cancel = ->
    self.dismiss({$value: 'cancel'})

  @save = ->
    if self.material.id
      self.material.$update (updated_material) ->
        self.close({$value: updated_material})
    else
      self.material.$save (created_material) ->
        self.close({$value: created_material})

  @delete = ->
    $translate("Sure?").then (title) =>
      if $window.confirm(title)
        self.material.$delete ->
          self.close({$value: 'deleted'})


  this
 
angular.module 'logisticsApp'
  .component 'maintenanceMaterialModal',
    template: require('app/maintenance/materials/modal/modal.html')
    controller: Controller
    bindings:
      resolve: '<'
      close: '&'
      dismiss: '&'
