angular.module 'logisticsApp'
  .factory "User", (RailsResource, $filter, $translate, $q, railsSerializer) ->
    class User extends RailsResource
      @configure
        url: "/api/users"
        name: "user"
        serializer: railsSerializer ->
          @exclude('attachments')
          @nestedAttribute('attachments')
          @resource('attachments', 'Attachment')
          @add 'attachment_ids', (user) ->
            if user.attachments
              user.attachments.map (attachment) -> attachment.id
          
      @roles = [
        {value: 'director', text: 'users.roles.director'},
        {value: 'logist', text: 'users.roles.logist'},
        {value: 'bookkeeper', text: 'users.roles.bookkeeper'},
        {value: 'client', text: 'users.roles.client'},
        {value: 'driver', text: 'users.roles.driver'}
      ]

      @showRole = (user) ->
        selected = []
        if user and user.role
          selected = $filter('filter')(@roles, {value: user.role})

        if selected.length
          selected[0].text
        else
          'Без ролі'

      @checkName = (data, id) ->
        if data == "" or !data?
          return $translate("validators.blank")
        null

      @checkEmail = (data, id) ->
        if data == "" or !data?
          return $translate("validators.blank")
        null

      @checkRole = (data, id) ->
        if data == "" or !data?
          return $translate("validators.blank")
        null

      @checkPassword = (data, id) ->
        if id
          return null
        if data == "" or !data?
          return $translate("validators.blank")
        null

      @checkPasswordConfirmation = (data, id) ->
        if id
          return null
        if data == "" or !data?
          return $translate("validators.blank")
        null
