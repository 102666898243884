Controller = ($rootScope, $translate, $window, $http, $uibModal, $httpParamSerializer, $auth, DriverNew, VehicleNew) ->
  "ngInject"

  consumer = null
  self = this

  @$onInit = ->
    self.driver = self.resolve.driver

    self.vehicles = VehicleNew.query()

  @cancel = ->
    self.dismiss({$value: 'cancel'})

  @save = ->
    if self.driver.id
      self.driver.$update ->
        self.close({$value: self.driver})
    else
      self.driver.$save ->
        self.close({$value: self.driver})

  @delete = ->
    $translate("Sure?").then (title) =>
      if $window.confirm(title)
        self.driver.$delete ->
          self.close({$value: null})

  @addPhone = ->
    self.driver.phone_numbers.push({})

  @removePhone = (index) ->
    if self.driver.phone_numbers[index].id
      self.driver.phone_numbers[index]._destroy = true
    else
      self.driver.phone_numbers.splice(index, 1)

  @authorize = ->
    event.stopPropagation()

    $http.post("/api/drivers/#{self.driver.id}/tokens.json").then (response) ->
      self.authorization = response.data
      self.authorization.qr_code_url = "#{response.data.qr_code_url}&#{$httpParamSerializer($auth.retrieveData('auth_headers'))}"

    false

  this
 
angular.module 'logisticsApp'
  .component 'driverModal',
    template: require('app/drivers/modal/modal.html')
    controller: Controller
    bindings:
      resolve: '<'
      close: '&'
      dismiss: '&'
