angular.module 'logisticsApp'
  .factory "Notification", (RailsResource, railsSerializer) ->
    class Notification extends RailsResource
      @configure
        url: "/api/notifications"
        name: 'notification'
        serializer: railsSerializer ->
          @exclude('auto','payment','dest','tuned')

          @add 'at', (document) ->
            moment(document.at).format("YYYY-MM-DD")

