Controller = ($httpParamSerializer, $auth) ->
  "ngInject"

  self = this

  @$onInit = ->
    self.auth_params = $httpParamSerializer($auth.retrieveData('auth_headers'))
    self.authorization = self.resolve.authorization.data

  @cancel = ->
    self.dismiss({$value: 'cancel'})

  this
 
angular.module 'logisticsApp'
  .component 'authorizeQrCodeVehicleModal',
    template: require('app/vehicles/authorize-qr-code/authorize-qr-code.html')
    controller: Controller
    bindings:
      resolve: '<'
      close: '&'
      dismiss: '&'
