Controller = ($rootScope, $uibModal, $translate, $window, $http, VehicleNew, Driver, TripNew, PermitType, Purse) ->
  "ngInject"

  consumer = null
  self = this

  loadPurses = (organization_id) ->
    Purse.query 'q[organization_id_eq]': organization_id, (purses) ->
      self.purses = purses

      if !self.payment.id? and purses.length == 1
        self.payment.purse_id = purses[0].id

  @$onInit = ->
    self.payment = self.resolve.payment

    if self.resolve.trip
      loadPurses(self.resolve.trip.organizationId)

      self.trips = [self.payment.trip] if self.payment
      self.crews = self.resolve.trip.crews
      self.payment.crew_id = self.crews[0].id if self.crews.length == 1
    else
      loadPurses()

  @tripChanged = (trip) ->
    loadPurses(trip.organizationId)

  @cancel = ->
    self.dismiss({$value: 'cancel'})

  @save = ->
    if self.payment.id
      self.payment.$update ->
        self.close({$value: self.payment})
    else
      self.payment.$save ->
        self.close({$value: self.payment})

  @delete = ->
    $translate("Sure?").then (title) =>
      if $window.confirm(title)
        self.payment.$delete ->
          self.dismiss({$value: 'cancel'})

  @refreshTrips = (q) ->
    TripNew.query('q[accounts_number_cont]': q, (trips) ->
      self.trips = trips
    ).$promise

  @createPurse = () ->
    modalInstance = $uibModal.open
      animation: true,
      component: 'purseModal'
      size: 'lg',
      resolve:
        purse: -> new Purse()

    modalInstance.result.then (created_purse) ->
      self.purses.push(created_purse)
      self.payment.purses_id = created_purse.id


  this
 
angular.module 'logisticsApp'
  .component 'paymentModal',
    template: require('app/payments/modal/modal.html')
    controller: Controller
    bindings:
      resolve: '<'
      close: '&'
      dismiss: '&'
