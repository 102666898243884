angular.module 'logisticsApp'
  .controller 'EditDocumentController', ($scope, $state, $filter, $stateParams, Document, Notification, Trip, Client, Driver, Vehicle, Bank, Organization, Trailer) ->
    'ngInject'

    if $scope.user.configName == 'client'
      $scope.types = [
        {value: 'Application', text: 'Application'},
      ]

    $scope.document_id = $stateParams.id

    document = null
    Document.get($stateParams.id, type: $stateParams.type).then (doc) ->
      doc.expiredAt = moment(doc.expiredAt).toDate() if doc.expiredAt
      doc.startedAt = moment(doc.startedAt).toDate() if doc.startedAt
      document = $scope.document = doc

    selectTrip = $scope.selectTrip = (item, model) ->
      Trip.get(item.id).then (trip) ->
        if document.type == 'Account'
          document.clientId = trip.clientId
          document.contractId = trip.contractId
          document.vehicleId = trip.vehicleId
          document.origin = trip.origins[0]
          document.destination = trip.targets[trip.targets.length-1]
          document.total = trip.total.toFixed(2)
          document.currency = trip.currency

    selectAccount = $scope.selectAccount = (item, model) ->
      if document.type == 'Act'
        Document.get(item.id).then (account) ->
          document.clientId = account.clientId
          document.contractId = account.contractId


    $scope.save = ->
      if ['Account', 'Act', 'Application', 'DriverLicence', 'Passport', 'ForeignPassport', 'PublicDocument', 'VehicleRegistrationCertificate'].indexOf($scope.document.type) > -1
        delete document.expiredAt
      if ['Visa', 'MedicalCertificate', 'Application', 'DriverLicence', 'Passport', 'ForeignPassport', 'PublicDocument', 'VehicleRegistrationCertificate', 'Adr'].indexOf($scope.document.type) > -1
        delete document.startedAt

      unless ['Application'].indexOf($scope.document.type) > -1
        delete document.downloadAt
        delete document.uploadAt
        
      #TODO: refactor to serializers
      document.startedAt = moment(document.startedAt).format("YYYY-MM-DD") if document.startedAt
      document.expiredAt = moment(document.expiredAt).format("YYYY-MM-DD") if document.expiredAt
      document.uploadAt = moment(document.uploadAt).format("YYYY-MM-DD") if document.uploadAt
      document.downloadAt = moment(document.downloadAt).format("YYYY-MM-DD") if document.downloadAt

      success = ->
        $scope.goBack()
      fail = (response) ->

      $scope.document.save().then success, fail


    $scope.updateExpireNotification = (doc) ->
      if ['Visa', 'Certificate', 'Insurance', 'UniversityCertificate', 'UniversityTrailerCertificate', 'MedicalCertificate', 'YellowCertificate', 'InternationalCources', 'Adr'].indexOf(doc.type) > -1
        doc.notifications ||= []
        doc.notifications = doc.notifications.filter (item) -> !item.auto

        warnDates = [
          moment(doc.expiredAt).subtract(1, 'days').toDate(),
          moment(doc.expiredAt).subtract(7, 'days').toDate(),
          moment(doc.expiredAt).subtract(14, 'days').toDate()
        ]

        for at in warnDates
          exists = $filter('filter') doc.notifications, (value) -> value.auto and moment(value.at).isSame(at)
          unless exists.length > 0
            note = new Notification(at: at, auto: true)
            doc.notifications.unshift(note)
          else
            note = exists[0]

          note.title = "#{doc.type or ''} expired"
          note.info = "#{doc.type or ''} '#{doc.number or ''}' expired on #{$filter('date')(doc.expiredAt)}"

      true
          
    Trip.query(page: 1).then (trips) ->
      $scope.trips = trips

    Document.query(type: 'Account', page: 1).then (accounts) ->
      $scope.accounts = accounts

    Document.query(type: 'Contract', page: 1).then (contracts) ->
      $scope.contracts = contracts

    Client.query(page: 1).then (clients) ->
      $scope.clients = clients

    Driver.query().then (drivers) ->
      $scope.drivers = drivers
      
    Vehicle.query().then (vehicles) ->
      $scope.vehicles = vehicles

    Bank.query().then (banks) ->
      $scope.banks = banks

    Organization.query().then (organizations) ->
      $scope.organizations = organizations

    $scope.trailers = Trailer.query()

    this

  .config ($stateProvider, $urlRouterProvider) ->
    'ngInject'
    $stateProvider
      .state 'app.documents.edit',
        url: '/edit/:id?type'
        template: require('app/documents/form.html')
        controller: 'EditDocumentController'
