angular.module 'logisticsApp'
  .controller 'SparesController', ($scope, $translate, $uibModal, Spare, Operation) ->
    'ngInject'

    $scope.rowCollection = []
    $scope.displayedCollection = []

    Spare.query().then (spares) ->
      angular.copy(spares, $scope.rowCollection)
      $scope.displayedCollection = [].concat($scope.rowCollection)
      $scope.selectSpare($scope.displayedCollection[0])

    $scope.removeSpare = (index) ->
      spare = $scope.displayedCollection[index]
      promise = spare.remove()
      promise.then ->
        $scope.rowCollection.splice(index, 1)
      promise

    $scope.income = (index) ->
      spare = $scope.displayedCollection[index]

      modalInstance = $uibModal.open
        animation: true,
        template: require('app/spares/income.html'),
        controller: 'IncomeController',
        size: 'md',
        resolve:
          spare: -> spare

      modalInstance.result.then (operation) ->
        spare.get()
        selectSpare(spare)

      false

    $scope.transfer = (index) ->
      spare = $scope.displayedCollection[index]

      modalInstance = $uibModal.open
        animation: true,
        template: require('app/spares/transfer.html'),
        controller: 'TransferController',
        size: 'md',
        resolve:
          spare: -> spare
      modalInstance.result.then (operation) ->
        spare.get()
        selectSpare(spare)

      false

    $scope.checkTitle = (data, id) ->
      if data == "" or !data?
        return $translate("validators.blank")
      null

    $scope.saveSpare = (spare) ->
      spare.save()

    selectSpare = $scope.selectSpare = (spare) ->
      angular.forEach $scope.displayedCollection, (s) ->
        s.selected = false

      Operation.query(spare_id: spare.id).then (items) ->
        $scope.operations = items
        spare.selected = true

    # add user
    $scope.addSpare = ->
      $scope.inserted = new Spare()
      $scope.rowCollection.push($scope.inserted)
