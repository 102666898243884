import "../route/route.component";
import "app/common/directives/star";

Controller = ($rootScope, $http, $filter, $stateParams, toaster, $uibModal, $state, $interval, $window, $translate, $localStorage, Trip, Vehicle, Driver, Client, Document, DocumentNew, Delivery, Template, PaymentNew, NgMap, Task, ActionCableChannel) ->
  'ngInject'

  self = this

  @cableCallback = (message) ->
    return unless message.trip_id == self.trip.id

    if message.updated_at != self.trip.updatedAt
      self.trip.get()

      #if $rootScope.user.id != message.user_id
      #  $mdToast.showSimple($filter('translate')('Item updated'))

    else if message.event == 'destroy'
      $state.go('app.trips.list')
      #if $rootScope.user.id != message.user_id
      #  $mdToast.showSimple($filter('translate')('Item deleted'))

  @$onInit = =>
    @results = [
      {name: 'successful', text: 'trips.results.successful'},
      {name: 'failed', text: 'trips.results.failed'},
      {name: 'defect', text: 'trips.results.defect'}
    ]

    @accounts = []

    @locationIcons = {
      download_reached: "https://chart.googleapis.com/chart?chst=d_map_pin_icon_withshadow&chld=truck|00FF00"
      download_not_reached: "https://chart.googleapis.com/chart?chst=d_map_pin_icon_withshadow&chld=truck|FF0000"
      upload_reached: "https://chart.googleapis.com/chart?chst=d_map_pin_icon_withshadow&chld=flag|00FF00"
      upload_not_reached: "https://chart.googleapis.com/chart?chst=d_map_pin_icon_withshadow&chld=flag|FF0000"
    }

    @consumer = new ActionCableChannel("TripsChannel", main_office_id: $localStorage.main_office_id)
    @consumer.subscribe(@cableCallback)

  @$onChanges = (changes) ->
    if changes.trip
      $http(
        method: 'GET'
        url: "/api/trips/#{self.trip.id}/track_points.json"
      ).then (response) ->
        self.track_summary = response.data

  @loadAccounts = =>
    Document.query(type: 'Account', without_trip: true, number: 75).then (accounts) =>
      @accounts = accounts

  @loadTemplates = ->
    self.templates = Template.query()

  @printDocument = (index) ->
    modalInstance = $uibModal.open
      animation: true,
      component: 'newDocumentModal'
      size: 'sm',
      resolve:
        document: -> new DocumentNew(template: self.templates[index], template_id: self.templates[index].id, trip_id: self.trip.id, trip: self.trip, documentable_id: self.trip.id, documentable_type: 'Trip', started_at: new Date())

    modalInstance.result.then ->
      toaster.pop('success', "Документ генерується...")

    null

  @copy = ->
    modalInstance = $uibModal.open
      animation: true,
      component: 'copyTripModal'
      size: 'sm',
      resolve:
        tripId: -> self.trip.id

    modalInstance.result.then (tripId) ->
      $state.go('app.trips.show', id: tripId)

  @removeTrip = =>
    $translate("Sure?").then (title) =>
      if $window.confirm(title)
        @trip.remove().then =>
          $state.go('app.trips.list')

  @finishTrip = (result) =>
    $translate("Sure?").then (title) =>
      if $window.confirm(title)
        new Trip(id: @trip.id, result: result).update().then =>
          $state.go('app.trips.list')
        , (response) =>
          toaster.pop('error', $filter('translate')('Updating failed'), response.data.errors.result[0])

  @userPaid = ->
    new Trip(id: self.trip.id, user_paid: self.trip.userPaid).update()

  @toggleTripRate = () ->
    self.trip.successfulRate = if self.trip.successfulRate == 1 then 0 else 1
    new Trip(id: self.trip.id, successfulRate: self.trip.successfulRate).update().then ->
      toaster.pop(type: 'success', title: $filter('translate')('Trip rate saved'), timeout: 2000)


  @recoverTrip = (result) =>
    $translate("Sure?").then (title) =>
      if $window.confirm(title)
        new Trip(id: @trip.id, result: null).update().then =>
          $state.go('app.trips.list')

  @documentsSended = =>
    $translate("Sure?").then (title) =>
      if $window.confirm(title)
        new Trip(id: @trip.id, documentsSended: @trip.documentsSended).update()

  @documentsSendedAt = (date) =>
    new Trip(id: @trip.id, documentsSendedAt: date).update().then (new_trip) =>
      @trip.get()

  @scansSended = =>
    $translate("Sure?").then (title) =>
      if $window.confirm(title)
        new Trip(id: @trip.id, scansSended: @trip.scansSended).update()

  @scansSendedAt = (date) =>
    new Trip(id: @trip.id, scansSendedAt: date).update().then (new_trip) =>
      @trip.get()

  @updateUserPercent = (percent) =>
    new Trip(id: @trip.id, userPercent: percent).update().then (new_trip) =>
      @trip.get()

  @updateUserPaidComment = (comment) =>
    new Trip(id: @trip.id, userPaidComment: comment).update().then (new_trip) =>
      @trip.get()

  @received = (index) =>
    delivery = @trip.deliveries[index]
    delivery.receivedAt = moment().toDate()
    delivery.save()

  @attachAccount = ->
    modalInstance = $uibModal.open
      animation: true,
      component: 'attachAccountModal',
      size: 'md',
      resolve:
        tripId: -> self.trip.id

    modalInstance.result.then (updated_account) ->
      self.trip.get()

  @detachAccount = (index) =>
    $translate("Sure?").then (title) =>
      if $window.confirm(title)
        account = @trip.accounts[index]

        $http.put("/api/documents/#{account.id}?type=Account", trip_id: null).then =>
          @trip.accounts.splice(index, 1)

  @delivered = =>
    $translate("Sure?").then (title) =>
      if $window.confirm(title)
        new Trip(id: @trip.id, delivered: true).update()

  @notDelivered = =>
    $translate("Sure?").then (title) =>
      if $window.confirm(title)
        new Trip(id: @trip.id, delivered: false).update()

  @downloaded = =>
    $translate("Sure?").then (title) =>
      if $window.confirm(title)
        new Trip(id: @trip.id, downloaded: true, draft: false).update()

  @notDownloaded = =>
    $translate("Sure?").then (title) =>
      if $window.confirm(title)
        new Trip(id: @trip.id, downloaded: false).update()


  @save = =>
    @trip.save()

  @addDelivery = =>
    @inserted = new Delivery sentAt: moment().toDate(), tripId: @trip.id
    @trip.deliveries.push @inserted

  @cancelDelivery = (index, rowform) =>
    rowform.$cancel()
    unless @trip.deliveries[index].id?
      @trip.deliveries.splice(index, 1)


  @saveDelivery = (delivery) =>
    delivery.sentAt = moment(delivery.sentAt).format("YYYY-MM-DD")
    delivery.save()

  @removeDelivery = (index) =>
    $translate("Sure?").then (title) =>
      if $window.confirm(title)
        if @trip.deliveries[index].id?
          @trip.deliveries[index].remove()
        else
          @trip.deliveries.splice(index, 1)

  @addPayment = (attrs) ->
    new_payment = new PaymentNew(angular.extend(attrs, {paid: true, trip: self.trip, trip_id: self.trip.id, amount_currency: self.trip.currency, at: new Date()}))

    modalInstance = $uibModal.open
      animation: true,
      component: 'paymentModal'
      size: 'md',
      resolve:
        payment: -> new_payment
        trip: -> self.trip

  @attachFiles = =>
    modalInstance = $uibModal.open
      animation: true,
      template: require('app/attachments/select.html'),
      controller: 'SelectAttachmentsController',
      size: 'lg',
      resolve:
        attachmentable: => @trip

    modalInstance.result.then (attachments) =>
      new Trip(id: @trip.id, attachments: attachments).$patch("/api/trips/#{@trip.id}").then =>
        $translate('Documents attached').then (t) =>
          toaster.pop('success', t, 'OK')

    false


  @sendmail = =>
    modalInstance = $uibModal.open
      animation: true,
      template: require('app/components/emails/send.html'),
      controller: 'SendEmailController',
      size: 'lg',
      resolve:
        attachments: => @trip.attachments

    modalInstance.result.then =>
      $translate('Sent successfuly').then (t) =>
        toaster.pop('success', t, 'OK')

    false

  @addTask = ->
    $uibModal.open({
      animation: true,
      component: 'taskModal'
      size: 'lg',
      resolve:
        task: -> new Task(trip_id: self.trip.id, trip: self.trip, user_id: self.trip.userId, deadline_date: new Date())
    }).result.then (created_task) ->
      self.trip.tasks.push(created_task)

  @paymentPaid = (index) ->
    PaymentNew.update id: self.trip.payments[index].id, paid: self.trip.payments[index].paid, ->
      self.trip.get()

  @editPayment = (index) ->
    payment = self.trip.payments[index]

    modalInstance = $uibModal.open
      animation: true,
      component: 'paymentModal'
      size: 'md',
      resolve:
        payment: -> PaymentNew.get(id: payment.id).$promise
        trip: -> self.trip

  @removePayment = (index) ->
    $translate("Sure?").then (title) =>
      if $window.confirm(title)
        PaymentNew.delete id: self.trip.payments[index].id, ->
          self.trip.payments.splice(index, 1)

  this
 
angular.module 'logisticsApp'
  .component 'trip',
    template: require('app/trips/trip/trip.html')
    controller: Controller
    bindings:
      trip: '<'

  .filter 'distance', ->
    (input) =>
      if input >= 1000
        return (input/1000).toFixed(2) + ' км'
      else
        return input + ' м'


