Controller = ($rootScope, $translate, $window, $http, Vehicle, Driver, Pvd, PermitType) ->
  "ngInject"

  consumer = null
  self = this

  @$onInit = ->
    self.permit = self.resolve.permit

    Vehicle.query().then (vehicles) ->
      self.vehicles = vehicles

    Driver.query().then (drivers) ->
      self.drivers = drivers

    $http.get('/api/permits/countries.json').then (response) ->
      self.countries = response.data

  @cancel = ->
    self.dismiss({$value: 'cancel'})
    self.dismiss({$value: 'cancel'})

  @save = ->
    if self.permit.id
      self.permit.$update ->
        self.close({$value: self.permit})
    else
      self.permit.$save ->
        self.close({$value: self.permit})

  @delete = ->
    $translate("Sure?").then (title) =>
      if $window.confirm(title)
        self.permit.$delete ->
          self.dismiss({$value: 'cancel'})

  @getPvds = (q) ->
    Pvd.query('q[title_cont]': q).$promise

  @getPermitTypes = (q) ->
    PermitType.query('q[title_cont]': q).$promise

  @loadStatus = ->
    $http.get('/api/permits/status.json', params: {
      'q[country_eq]': self.permit.country,
      'q[permit_type_title_count]': self.permit.permit_type_title,
      'q[pvd_title_count]': self.permit.pvd_title
    }).then (response) ->
      self.available = response.data.available

  this
 
angular.module 'logisticsApp'
  .component 'interpipeTenderModal',
    template: require('app/permits/modal/modal.html')
    controller: Controller
    bindings:
      resolve: '<'
      close: '&'
      dismiss: '&'
