import "app/track-idles/list/list.component";
import "app/common/services/trailer.service";

Controller = ($scope, $translate, $state, $window, $uibModal, Driver, Partner, Tracker, Trip, User, Vehicle, VehicleNew, Trailer, $http) ->
  "ngInject"

  self = this
  @ecos = ['', 'EURO5','EURO6']
  @reserved_statuses = ['free', 'reserved','taken']
  @status =
    opened: false

  @saveVehicle = =>
    if @vehicle.driverFullname == ''
      @vehicle.driverId = null
      @vehicle.driver = null
    @vehicle.save().then =>
      if @adding
        $state.go('app.vehicle', id: @vehicle.id )


  @loadPath = =>
    $http(
      method: 'GET'
      url: "/api/vehicles/#{@vehicle.id}/track_points.json"
      params:
        starts_at: moment(@starts_at).format('DD/MM/YYYY')
        ends_at: moment(@ends_at).format('DD/MM/YYYY')
    ).then (response) =>
      @segments = response.data.segments
      @locations = response.data.locations
      @trips = response.data.trips
      @motorway_tolls = response.data.motorway_tolls
      @refills = response.data.refills
        
  @$onInit = =>
    @locationIcons = {
      download_reached: "https://chart.googleapis.com/chart?chst=d_map_pin_icon_withshadow&chld=truck|00FF00"
      download_not_reached: "https://chart.googleapis.com/chart?chst=d_map_pin_icon_withshadow&chld=truck|FF0000"
      upload_reached: "https://chart.googleapis.com/chart?chst=d_map_pin_icon_withshadow&chld=flag|00FF00"
      upload_not_reached: "https://chart.googleapis.com/chart?chst=d_map_pin_icon_withshadow&chld=flag|FF0000"
    }

    Driver.query().then (drivers) =>
      @drivers = drivers

    Partner.query().then (partners) =>
      @partners = partners

    User.query(role: 'logist').then (users) ->
      self.users = users

    @starts_at = moment().subtract(2, 'days').toDate()
    @ends_at = new Date()
    @loadPath()

  @refreshTrackers = (q) =>
    @trackers = Tracker.query('q[name_cont]': q, fields: 'id,name,type_title')

  @refreshTrailers = (q) ->
    self.trailers = Trailer.query('q[title_cont]': q)

  @remove = ->
    event.stopPropagation()

    modalInstance = $uibModal.open
      animation: true,
      component: 'removeVehicleModal'
      size: 'sm',
      resolve:
        vehicle: -> self.vehicle

    modalInstance.result.then ->
      $state.go('app.vehicles')

    false

  @archive = ->
    $translate("Sure?").then (title) ->
      if $window.confirm(title)
        VehicleNew.update id: self.vehicle.id, is_archived: true, ->
          $state.go('app.archive.vehicles')


  @recover = ->
    $translate("Sure?").then (title) ->
      if $window.confirm(title)
        VehicleNew.update id: self.vehicle.id, is_archived: false, ->
          $state.go('app.vehicles')

  @attach = ->
    event.stopPropagation()

    modalInstance = $uibModal.open
      animation: true,
      component: 'authorizeQrCodeVehicleModal'
      size: 'sm',
      resolve:
        authorization: -> $http.get("/api/vehicles/#{self.vehicle.id}/authorize_qr_code.json")

    false

  @$postLink = =>
    unless @vehicle.id
      @adding = true
      $scope.form.$show()

  @open = ($event) =>
    @status.opened = true

  @loadTrips = (params) =>
    params['q[crews_vehicle_id_eq]'] = @vehicle.id
    params["q[scope]"] = 'active'
    Trip.query(params)

  this

angular.module 'logisticsApp'
  .component 'vehicle',
    template: require('app/vehicles/vehicle/vehicle.html')
    controller: Controller
    bindings:
      vehicle: '<'
