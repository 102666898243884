angular.module 'logisticsApp'
  .controller 'SignController', ($scope, $stateParams, $http, $window, Attachment) ->
    'ngInject'

    $scope.stamp = {height:376, width:436,x:0, y:0, blob_url:"/private/stamp.png"}
    $scope.sign = {height:500, width:502,x:0, y:0, blob_url:"/private/sign.png"}

    $scope.aspect = 0.58
    $scope.page_scale = 1.0
    $scope.viewportWidth = 0
    $scope.marginLeft = 0
    $scope.inProgress = false
    $scope.isLoading = true

    Attachment.get($stateParams.id).then (attachment) ->
      $scope.attachment = attachment

    $scope.save = ->
      $scope.inProgress = true
      $http.post("/api/attachments/#{$stateParams.id}/sign.json", {
        stamp:
          x: ($scope.stamp.x - $scope.marginLeft) / ($scope.aspect * $scope.page_scale)
          y: $scope.stamp.y / ($scope.aspect * $scope.page_scale)
        sign:
          x: ($scope.sign.x - $scope.marginLeft) / ($scope.aspect * $scope.page_scale)
          y: $scope.sign.y / ($scope.aspect * $scope.page_scale)
      }).then (response) ->
        $scope.inProgress = false
        $scope.goBack()

  .directive 'composeDocument', ->
    restrict: 'A'
    controller: ($scope) ->
      'ngInject'
    link: (scope, element, attr) ->
      scope.sourceWidth = null
      scope.viewportWidth = null

  .directive 'source', ->
    require: '^composeDocument',
    restrict: 'A'
    transclude: true
    link: (scope, element, attr, composeCtrl) ->
      element.on 'load', (event) ->
        scope.isLoading = false
        scope.sourceWidth = parseInt(element.prop('naturalWidth'))
        scope.viewportWidth = parseInt(element.css('width'))
        scope.marginLeft = parseInt(element.css('margin-left'))

  .directive 'stamp', ->
    require: '^composeDocument',
    restrict: 'A'
    transclude: true
    link: (scope, element, attr) ->
      scope.$watch 'sourceWidth', ->
        if scope.sourceWidth > 0
          scope.aspect = (scope.viewportWidth / scope.sourceWidth)
          scope.page_scale = scope.sourceWidth / 2479
          element.css('width', "#{parseInt(element.css('width')) * scope.aspect * scope.page_scale}px")
