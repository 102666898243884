Controller = ($rootScope, $translate, $window, $http, $httpParamSerializer, $auth, User) ->
  "ngInject"

  consumer = null
  self = this

  @$onInit = ->
    self.info_contact = self.resolve.info_contact
    self.auth_params = $httpParamSerializer($auth.retrieveData('auth_headers'))

    User.query(role: 'logist').then (users) ->
      self.users = users#.concat([{fullname: 'Not selected'}])

  @refreshCountries = (q) ->
    $http.get('/api/info_contacts/countries.json', params: {field: 'alpha2', q: q}).then (response) ->
      self.countries = response.data

  @fillCountries = (field, value) ->
    $http.get('/api/info_contacts/countries.json', params: {field: field, q: value}).then (response) ->
      self.info_contact.countries = response.data

  @cancel = ->
    self.dismiss({$value: 'cancel'})

  @save = ->
    if self.info_contact.id
      self.info_contact.$update ->
        self.close({$value: self.info_contact})
    else
      self.info_contact.$save ->
        self.close({$value: self.info_contact})

  @delete = ->
    $translate("Sure?").then (title) =>
      if $window.confirm(title)
        self.info_contact.$delete ->
          self.close({$value: self.info_contact})

  @copyCardData = ->
    self.info_contact.card_data = self.info_contact.number

  this
 
angular.module 'logisticsApp'
  .component 'infoContactModal',
    template: require('app/settings/info_contacts/modal/modal.html')
    controller: Controller
    bindings:
      resolve: '<'
      close: '&'
      dismiss: '&'
