Controller = ($rootScope, $uibModal, $translate, $window, FuelCardNew, FuelProvider, Driver, VehicleNew) ->
  "ngInject"

  consumer = null
  self = this

  @prevPage = ->
    self.filters.page = self.pagination.prev_page
    self.load()

  @nextPage = ->
    self.filters.page = self.pagination.next_page
    self.load()

  @load = ->
    FuelCardNew.query self.filters, (fuel_cards, responseHeaders) ->
      self.fuel_cards = fuel_cards

      self.filters.page = parseInt(responseHeaders()['x-current-page']) if responseHeaders()['x-current-page']?

      self.pagination = {
        total_count: parseInt(responseHeaders()['x-total-count']),
        next_page: responseHeaders()['x-next-page'],
        prev_page: responseHeaders()['x-prev-page'],
      }
      self.pagination.first = (self.filters.page - 1) * self.filters.limit + 1
      self.pagination.last = self.pagination.first + fuel_cards.length - 1



  @$onInit = ->
    self.fuel_providers = FuelProvider.query()

    self.filters = {
      page: 1
      limit: 25
    }
    self.load()

  @edit = (fuel_card) ->
    modalInstance = $uibModal.open
      animation: true,
      component: 'fuelCardModal'
      size: 'md',
      resolve:
        fuel_card: -> FuelCardNew.get(id: fuel_card.id).$promise

    modalInstance.result.then ->
      self.load()

  @getVehicles = (q) ->
    VehicleNew.query('q[number_cont]': q).$promise

  @getDrivers = (q) ->
    Driver.query('q[number_cont]': q).$promise

  @remove = (index) ->
    $translate("Sure?").then (title) =>
      if $window.confirm(title)
        FuelCardNew.delete id: self.fuel_cards[index].id, ->
          self.fuel_cards.splice(index, 1)
 
  this
 
angular.module 'logisticsApp'
  .component 'fuelCardsList',
    template: require('app/fuel_cards/list/list.html')
    controller: Controller

  .config ($stateProvider, $urlRouterProvider) ->
    'ngInject'
    $stateProvider
      .state 'app.fuelCards',
        url: '/fuelCards'
        component: 'fuelCardsList'
        data:
          permissions:
            only: 'listFuelCards'
