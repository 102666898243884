Controller = ($rootScope, $uibModal, $translate, $window, $http, $auth, $httpParamSerializer, Permit, Pvd, VehicleNew, PermitType, ActionCableChannel) ->
  "ngInject"

  consumer = null
  self = this

  @prevPage = ->
    self.filters.page = self.pagination.prev_page
    self.load()

  @nextPage = ->
    self.filters.page = self.pagination.next_page
    self.load()

  @statusChanged = ->
    for s in self.statuses
      delete self.filters["q[#{s}]"]

    if self.filter_by_status
      self.filters["q[#{self.filter_by_status}]"] = true

    self.load()

  @load = ->
    Permit.query self.filters, (permits, responseHeaders) ->
      self.permits = permits
      self.pagination = {
        total_count: parseInt(responseHeaders()['x-total-count']),
        next_page: responseHeaders()['x-next-page'],
        prev_page: responseHeaders()['x-prev-page'],
      }
      self.pagination.first = (self.filters.page - 1) * self.filters.limit + 1
      self.pagination.last = self.pagination.first + permits.length - 1

  callback = (message) ->
    self.load()

  @$onInit = ->
    $http.get('/api/permits/countries.json').then (response) ->
      self.countries = response.data

    self.filters = {
      page: 1
      limit: 25
    }
    self.statuses = ['pending', 'reserved', 'waiting_for_transfer', 'waiting_for_using', 'using', 'waiting_for_office', 'waiting_for_returning']
    angular.extend(self.filters, self.staticParams) if self.staticParams
    self.load()

    consumer = new ActionCableChannel("PermitsChannel")
    consumer.subscribe(callback)

  @edit = (permit) ->
    modalInstance = $uibModal.open
      animation: true,
      component: 'permitModal'
      size: 'lg',
      resolve:
        permit: -> Permit.get(id: permit.id).$promise

  @check = (permit, attr, using_index = null) ->
    event.stopPropagation()

    $translate("Sure?").then (title) =>
      if $window.confirm(title)
        unless using_index?
          attrs = {}
          attrs[attr] = new Date()
          Permit.update(id: permit.id, attrs)
        else
          extra_usings = angular.copy(permit.extra_usings)
          extra_usings[using_index][attr] = new Date()
          Permit.update(id: permit.id, extra_usings: extra_usings)

  @use = (permit, using_index = null) ->
    event.stopPropagation()

    $translate("Sure?").then (title) =>
      if $window.confirm(title)
        unless using_index?
          Permit.update(id: permit.id, used: true)
        else
          extra_usings = angular.copy(permit.extra_usings)
          extra_usings[using_index].used = true
          Permit.update(id: permit.id, extra_usings: extra_usings)

  @finish = (permit, using_index = null) ->
    event.stopPropagation()

    $translate("Sure?").then (title) ->
      if $window.confirm(title)
        unless using_index?
          Permit.update(id: permit.id, trip_finished: true)
        else
          extra_usings = angular.copy(permit.extra_usings)
          extra_usings[using_index].trip_finished = true
          Permit.update(id: permit.id, extra_usings: extra_usings)

  @getPvds = (q) ->
    Pvd.query('q[title_cont]': q).$promise

  @getPermitTypes = (q) ->
    PermitType.query('q[title_cont]': q).$promise

  @getVehicles = (q) ->
    VehicleNew.query('q[number_cont]': q).$promise

  @remove = (index) ->
    $translate("Sure?").then (title) =>
      if $window.confirm(title)
        Permit.delete id: self.permits[index].id, ->
          self.permits.splice(index, 1)
 
  @downloadScreenshot = (permit) ->
    url = "#{permit.bot_screenshot_url}?#{$httpParamSerializer($auth.retrieveData('auth_headers'))}&r=#{(new Date).getTime()}"
    printWindow = window.open(url)
    printWindow.document.close()
    printWindow.focus()
 
  @downloadSummaryScreenshot = (permit) ->
    url = "#{permit.bot_summary_screenshot_url}?#{$httpParamSerializer($auth.retrieveData('auth_headers'))}&r=#{(new Date).getTime()}"
    printWindow = window.open(url)
    printWindow.document.close()
    printWindow.focus()

  @print = ->
    params = angular.copy(self.filters)
    delete params.page
    delete params.limit
    windowUrl = "/api/permits.pdf?#{$httpParamSerializer(params)}&#{$httpParamSerializer($auth.retrieveData('auth_headers'))}&r=#{(new Date).getTime()}"
    printWindow = window.open(windowUrl)
    printWindow.document.close()
    printWindow.focus()
    printWindow.print()

  this
 
angular.module 'logisticsApp'
  .component 'permitsList',
    template: require('app/permits/list/list.html')
    controller: Controller
    bindings:
      staticParams: '<'

  .config ($stateProvider, $urlRouterProvider) ->
    'ngInject'
    $stateProvider
      .state 'app.permits',
        url: '/permits'
        component: 'permitsList'
        resolve:
          staticParams: ->
            {'q[active]': true}
        data:
          permissions:
            only: 'listPermits'

      .state 'app.archive.permits',
        url: '/permits'
        component: 'permitsList'
        resolve:
          staticParams: ->
            {'q[returned]': true}
        data:
          permissions:
            only: 'listPermits'
