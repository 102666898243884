angular.module 'logisticsApp'
  .factory 'FuelFillUp', ($resource) ->
    "ngInject"

    transformAttributes = (item) ->
      attrs = {}
      angular.copy item, attrs

      if attrs.total_amount
        attrs.total = attrs.total_amount

      angular.toJson(attrs)

    transformArray = (data) ->
      items = angular.fromJson(data)
      for item in items
        item.at = moment(item.at).toDate()
      items

    $resource('/api/fuel_fill_ups/:id.json', {id: '@id'}, {
      query: { method: 'GET', isArray: true, transformResponse: transformArray  }
      update: { method: 'PATCH', transformRequest: transformAttributes }
    })

