import "app/maintenance/materials/list/list.component";
import "app/maintenance/invoices/list/list.component";
import "app/maintenance/stock_transactions/list/list.component";

angular.module 'logisticsApp'
  .config ($stateProvider, $urlRouterProvider) ->
    'ngInject'
    $stateProvider
      .state 'app.maintenance',
        url: '/maintenance'
        abstract: true
        data:
          permissions:
            only: 'managementMaintenance'
