angular.module 'logisticsApp'
  .controller 'ImportsController', ($scope, $auth, FileUploader, Import) ->
    'ngInject'

    $scope.kind = ''
    $scope.kinds = ['WOG', 'Nafta Club', 'Shell','HU-GO','Gaspasa GK7', 'Anika']
    $scope.items = []

    uploader = $scope.uploader = new FileUploader({
      url: 'api/imports.json',
      headers: $auth.retrieveData('auth_headers'),
      autoUpload: false,
      removeAfterUpload: false
    })

    uploader.onBeforeUploadItem = (item) ->
      item.formData = [{ kind: $scope.kind }]

    uploader.onSuccessItem = (fileItem, response, status, headers) ->
      console.log response
      $scope.kind = ''
      uploader.clearQueue()
      $scope.items.unshift(new Import(response))

    $scope.saveImport = ->
      $scope.uploader.uploadAll()

    $scope.remove = (index) ->
      i = $scope.items[index]
      promise = i.remove()
      promise.then ->
        $scope.items.splice(index, 1)
      promise

    $scope.callServer = (tableState) ->
      if tableState
        $scope.tableState = tableState
      else
        tableState = $scope.tableState

      $scope.isLoading = true
      pagination = tableState.pagination

      start = pagination.start || 0
      number = pagination.number || 10

      Import.query(
        start: start,
        number: number
      ).then (resp) ->
        $scope.items = resp.data.map (attrs) -> new Import(attrs)
        tableState.pagination.numberOfPages = resp.numberOfPages
        $scope.isLoading = false

