angular.module 'logisticsApp'
  .controller 'TripFormController', ($scope, $state, $stateParams, $translate, $timeout, $http, $filter, Trip, Purse, Notification, Vehicle, Trailer, Driver, Client, Partner, Document, Payment, Organization, User, Crew, Label, Cargo, Location, $uibModal, ClientNew) ->
    'ngInject'

    $scope.payment_methods = [
      {value: 'cashless', text: 'Cashless'},
      {value: 'cash', text: 'Cash'},
      {value: 'bank_card', text: 'Bank card'},
      {value: 'driver_cash', text: 'Driver cash'}
    ]

    $scope.kinds = [
      {value: 'download', text: 'Download'},
      {value: 'execute', text: 'Execute'},
      {value: 'customs', text: 'Customs'},
      {value: 'idle', text: 'Idle'},
      {value: 'upload', text: 'Upload'}
    ]
    $scope.labels = []
    $scope.partners = []
    $scope.vehicles = []
    $scope.purses = Purse.query()

    $scope.minDate = new Date()

    $scope.clientType = 'permanent_client'

    trip = $scope.trip = new Trip
      accountOnDelivery: false
      paymentMethod: 'cashless'
      labelIds: []
      attachments: []
      clientId: $stateParams.clientId
      client: new Client contacts: [{type: 'tel'}]
      crews: [
        new Crew('$vehicleType': 'permanent_vehicle')
      ]
      partnerPayments: [
      ]
      cost_amount: 0.0
      cost_currency: 'UAH'
      downloadLocations: [new Location(kind: 'download')]
      deliveryLocations: [new Location(kind: 'upload')]
      waypointLocations: []

    trip.userId = $scope.user.id if $scope.user.role == 'logist'

    $scope.selectTruck = (crew, truck) ->
      crew.driverId = truck.driverIds[0]
      crew.trailerId = truck.trailerIds[0]

    $scope.selectPartner = (item, model) ->
      trip.partnerId = item.id

    if $stateParams.applicationId
      Document.get($stateParams.applicationId).then (app) ->
        trip.payments[0].amount = app.bit
        trip.payments[0].comment = "Полная, #{if app.payment_type == 'cash' then 'наличка' else 'безнал'}"

    Driver.query().then (drivers) ->
      $scope.drivers = drivers
    Vehicle.query().then (vehicles) ->
      $scope.vehicles = vehicles
    Partner.query().then (partners) ->
      $scope.partners = partners
    Organization.query().then (organizations) ->
      $scope.organizations = organizations
      $scope.trip.organizationId = organizations[0].id
    Label.query().then (labels) ->
      $scope.labels = labels
    $scope.trailers = Trailer.query()

    $scope.removeCarrierPayment = (index) ->
      $scope.trip.partnerPayments.splice(index, 1)

    $scope.addCarrierPayment = ->
      $scope.inserted = new Payment()
      $scope.trip.partnerPayments.push($scope.inserted)

    $scope.save = ->
      $scope.trip.labelIds = $filter('filter')($scope.labels, {selected: true}).map((l) -> l.id)
      $scope.trip.cost = $scope.trip.costAmount

      if $scope.clientType != 'new_client'
        delete $scope.trip.client

      for crew in $scope.trip.crews
        if crew.$vehicleType != 'new_vehicle'
          delete crew.vehicle
        else
          crew.vehicle.partnerId = $scope.trip.partnerId

      $scope.trip.save().then ->
        $state.go('app.trips.list')

    newLabel = $scope.newLabel = new Label

    $scope.selectLabel = (label) ->
      trip.directionId = label.directionId
      true

    $scope.addLabel = ->
      newLabel.save().then (label) ->
        $scope.labels.push(label)
        newLabel = $scope.newLabel = new Label

    $scope.removeLabel = (index) ->
      $translate("Sure?").then (title) ->
        if $window.confirm(title)
          $scope.labels[index].remove().then ->
            $scope.labels.splice(index, 1)
      false

    $scope.getCargos = (q) ->
      Cargo.query('q[title_cont]': q).$promise

    $scope.crewPriceChanged = ->
      crews_total_sum = 0.0
      for crew in $scope.trip.crews
        crews_total_sum += crew.price

      $scope.trip.crewsTotalSum = crews_total_sum

    $scope.addCrew = ->
      $scope.trip.build_crew()

    $scope.removeCrew = (index) ->
      $scope.trip.destroy_crew(index)

    $scope.createClient = (organization) ->
      modalInstance = $uibModal.open
        animation: true,
        component: 'clientModal'
        size: 'lg',
        resolve:
          client: -> new ClientNew(organization: organization, phones: [''])

      modalInstance.result.then (created_client) ->
        $scope.clients = [created_client]
        $scope.trip.clientId = created_client.id

