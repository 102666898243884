Controller = ($rootScope, $http, $filter, $stateParams, toaster, $uibModal, $state, $interval, $window, $translate, $localStorage, Trip, Vehicle, Driver, Client, Document, DocumentNew, Delivery, Template, PaymentNew, Task, ActionCableChannel, NgMap, TripNew) ->
  'ngInject'

  self = this

  @$onInit = ->
    self.way_points ||= []

    NgMap.getMap().then (map) ->
      self.map = map

  @remove = (index) ->
    self.way_points.splice(index, 1)

  @$onChanges = (changes) ->
    if changes.trip and self.trip

      self.way_points ||= []
      for l in self.trip.waypoint_locations
         self.way_points.push({ location: { lat: l.coordinate.latitude, lng: l.coordinate.longitude }, stopover: false })

      true
        
  @changed = ->
    if self.map?
      new_way_points = []

      for wp in self.map.directionsRenderers[0].directions.routes[0].legs[0].via_waypoints
        new_way_points.push({location: {lat:wp.lat(), lng: wp.lng()}, stopover: false})

      self.way_points = new_way_points

  @save = ->
    new_waypoint_locations = []
    for old_waypoint in self.trip.waypoint_locations
      new_waypoint_locations.push({id: old_waypoint.id, _destroy: true})

    for wp, index in self.way_points
      new_waypoint_locations.push({kind: 'waypoint', longitude: wp.location.lng, latitude: wp.location.lat, position: index})

    TripNew.update id: self.trip.id, waypoint_locations_attributes: new_waypoint_locations, ->
      $state.go('app.trips.show', id: self.trip.id)

  this
 
angular.module 'logisticsApp'
  .component 'tripRoute',
    template: require('app/trips/route/route.html')
    controller: Controller
    bindings:
      trip: '<'

  .config ($stateProvider, $urlRouterProvider) ->
    'ngInject'

    $stateProvider
      .state 'app.tripRoute',
        url: '/trips/show/:id/route'
        component: 'tripRoute'
        resolve:
          trip: ($stateParams, TripNew) ->
            "ngInject"
            TripNew.get(id: $stateParams.id).$promise

