import "app/common/services/maintenance_invoice.service";
import "app/common/services/maintenance_stock_transaction.service";

import "app/directives/ui-select-open-on-focus.directive";

Controller = ($scope, $rootScope, $timeout, $translate, $window, $filter, $uibModal, MaintenanceInvoice, MaintenanceMaterial, MaintenanceStockTransaction, VehicleNew, Trailer) ->
  "ngInject"

  consumer = null
  self = this

  setFocus = ->
    $timeout ->
      console.log('setfocus')
      $scope.$broadcast('SetFocus')
    , 300

  @$onInit = ->
    self.selectedMaterialId = null
    self.invoice = self.resolve.invoice

    if self.invoice.kind == 'outcome'
      self.vehicles = VehicleNew.query()
      self.trailers = Trailer.query()
    setFocus()

  @cancel = ->
    self.dismiss({$value: 'cancel'})

  @save = ->
    if self.invoice.id
      self.invoice.$update (updated_invoice) ->
        self.close({$value: updated_invoice})
    else
      self.invoice.$save (created_invoice) ->
        self.close({$value: created_invoice})

  @delete = ->
    $translate("Sure?").then (title) =>
      if $window.confirm(title)
        self.invoice.$delete ->
          self.close({$value: 'deleted'})

  @refreshMaterials = (q) ->
    MaintenanceMaterial.query({'q[search_cont]': q}, (materials) ->
      self.materials = materials
    ).$promise

  @add = (material) ->
    exists_transaction = $filter('filter')(self.invoice.stock_transactions, material_id: material.id)[0]

    MaintenanceStockTransaction.save invoice_id: self.invoice.id, material_id: material.id, (created_transaction) ->
      self.selectedMaterialId = null

      if exists_transaction?
        angular.extend(exists_transaction, created_transaction)
      else
        self.invoice.stock_transactions.push(created_transaction)

  @remove = (index) ->
    MaintenanceStockTransaction.delete id: self.invoice.stock_transactions[index].id, ->
      self.invoice.stock_transactions.splice(index)

  @newMaterial = (barcode) ->
    modalInstance = $uibModal.open
      animation: true,
      component: 'maintenanceMaterialModal'
      size: 'lg',
      resolve:
        material: -> new MaintenanceMaterial(barcode: barcode, cost_currency: 'UAH', unit: 'шт')

    modalInstance.result.then (created_material) ->
      self.add(created_material)

  this
 
angular.module 'logisticsApp'
  .component 'maintenanceInvoiceModal',
    template: require('app/maintenance/invoices/modal/modal.html')
    controller: Controller
    bindings:
      resolve: '<'
      close: '&'
      dismiss: '&'
