Controller = ($rootScope, $translate, $window, CemtLicence) ->
  "ngInject"

  consumer = null
  self = this

  @$onInit = ->
    self.eco_standarts = ['EURO5','EURO6']
    self.licence = self.resolve.licence

  @cancel = ->
    self.dismiss({$value: 'cancel'})

  @save = ->
    if self.licence.id
      self.licence.$update ->
        self.close({$value: self.licence})
    else
      self.licence.$save ->
        self.close({$value: self.licence})

  @delete = ->
    $translate("Sure?").then (title) =>
      if $window.confirm(title)
        self.licence.$delete ->
          self.dismiss({$value: 'cancel'})


  this
 
angular.module 'logisticsApp'
  .component 'cemtLicenceModal',
    template: require('app/cemt_licences/modal/modal.html')
    controller: Controller
    bindings:
      resolve: '<'
      close: '&'
      dismiss: '&'
