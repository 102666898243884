Controller = (WayBill, Trip, $auth, $httpParamSerializer, $http) ->
  "ngInject"
  self = this

  @$onInit = ->
    self.locationIcons = {
      download_reached: "https://chart.googleapis.com/chart?chst=d_map_pin_icon_withshadow&chld=truck|00FF00"
      download_not_reached: "https://chart.googleapis.com/chart?chst=d_map_pin_icon_withshadow&chld=truck|FF0000"
      upload_reached: "https://chart.googleapis.com/chart?chst=d_map_pin_icon_withshadow&chld=flag|00FF00"
      upload_not_reached: "https://chart.googleapis.com/chart?chst=d_map_pin_icon_withshadow&chld=flag|FF0000"
    }

  @loadTrips = (params) ->
    params['q[waybill_id_eq]'] = self.waybill.id
    Trip.query(params)

  @$onChanges = (changes) ->
    if changes.waybill
      $http(
        method: 'GET'
        url: "/api/vehicles/#{self.waybill.vehicle.id}/track_points.json"
        params:
          waybill_id: self.waybill.id
      ).then (response) ->
        self.track_summary = response.data

  this

angular.module 'logisticsApp'
  .component 'waybill',
    template: require('app/components/way_bills/waybill.html')
    controller: Controller
    bindings:
      waybill: '<'
