angular.module 'logisticsApp'
  .factory "Payment", (RailsResource, railsSerializer) ->
    class Payment extends RailsResource
      @configure
        url: "/api/payments"
        name: "payment"
        updateMethod: 'PATCH'
        serializer: railsSerializer ->
          @exclude('account')

          @add 'at', (document) ->
            if document.at
              moment(document.at).format("YYYY-MM-DD")
            else
              null

