import { Chart } from 'chart.js';

Controller = ($translate, $stateParams, $scope, $window, $rootScope, $q, $filter, $localStorage, $uibModal, $http, $state, PaymentNew, Document, Client, Vehicle, Organization, Purse) ->
  "ngInject"

  self = this

  labels = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
  ]

  data = {
    labels: labels,
    datasets: [{
      label: 'My First dataset',
      backgroundColor: 'rgb(255, 99, 132)',
      borderColor: 'rgb(255, 99, 132)',
      data: [0, 10, 5, 2, 20, 30, 45],
    }]
  }

  tripsChart = null


  @$onInit = ->
    self.filters = {
      date_gteq: moment().subtract(1, 'months').toDate()
    }
    self.sortField = 'trips_count'
    self.dateField = 'download_at'
    self.load()

  @clearFilters = ->
    self.filters = {}
    self.load()

  @load = ->
    self.isLoading = true

    params = {
      date_column: self.dateField
    }

    if self.dateField == 'download_at'
      params['q[download_at_gteq'] = self.filters.date_gteq
      params['q[download_at_lteq'] = self.filters.date_lteq
    else
      params['q[created_at_gteq'] = self.filters.date_gteq
      params['q[created_at_lteq'] = self.filters.date_lteq

    $http.get('/api/reports/users.json', params: params).then (response) ->
      self.report = response.data

    $http.get('/api/reports/user_trips_chart.json', params: { date_column: self.dateField }).then (response) ->
      tripsChart.destroy() if tripsChart?
      tripsChart = new Chart(document.getElementById('tripsChart'), response.data)

  this

angular.module 'logisticsApp'
  .component 'usersReport',
    template: require('app/reports/users/users.html')
    controller: Controller

  .config ($stateProvider, $urlRouterProvider) ->
    'ngInject'
    $stateProvider
      .state 'app.reports.users',
        url: '/users'
        component: 'usersReport'
        data:
          permissions:
            only: 'readTrips'
