angular.module 'logisticsApp'
  .factory "PaymentNew", ($resource) ->
    transformAttributes = (payment) ->
      attrs = {}
      angular.copy payment, attrs

      delete attrs.trip

      if attrs.attachments
        attrs.attachment_ids = attrs.attachments.map (a) -> a.id
        delete attrs.attachments
      angular.toJson(attrs)

    transformResponseAttributes = (data) ->
      attrs = angular.fromJson(data)
      if attrs.at
        attrs.at = moment(attrs.at).toDate()
      attrs

    $resource('/api/payments/:id.json', {id: '@id'}, {
      getList: { method: 'GET' },
      get: { method: 'GET', transformResponse: transformResponseAttributes },
      save: { method: 'POST', transformRequest: transformAttributes },
      update: { method: 'PATCH', transformRequest: transformAttributes }
    })

