angular.module 'logisticsApp'
  .directive 'acmeattachments', ->

    AttachmentsController = ($filter, $scope, $auth, $uibModal, $window, $translate, Attachment, FileUploader, ActionCableChannel) ->
      'ngInject'
      vm = this

      vm.opts =
        index: 0
        history: false
        pinchToClose: false
        closeOnScroll: false

      vm.showGallery = (i) ->
        if vm.selectable?
          attachment = vm.attachments[i]

          if vm.attachments[i].selected = !vm.attachments[i].selected
            if vm.selectable.indexOf(attachment) < 0
              vm.selectable.push(attachment)
          else
            if (found = vm.selectable.indexOf(attachment)) > -1
              vm.selectable.splice(found, 1)
        else
          if angular.isDefined(i)
            vm.opts.index = i

          vm.open = true

      vm.closeGallery = ->
        vm.open = false

      vm.attachments ||= []

      vm.itemClass = 'col-xs-12 col-sm-6 col-md-4'
      vm.size = 'thumb'

      vm.slides = []

      vm.print = (item) ->
        alert("PRINT")

      callback = (message) ->
        return unless message.attachmentable_id == vm.attachmentableId and message.attachmentable_type == vm.attachmentableType

        else if message.event == 'update' or message.event == 'create'
          attachment = $filter('filter')(vm.attachments, id: message.resource_id)[0]

          if attachment
            attachment.$get ->
              vm.slides[vm.attachments.indexOf(attachment)] = attachmentToSlide(attachment)
          else
            Attachment.get(id: message.resource_id).then (item) ->
              vm.attachments ||= []
              vm.attachments.push(item)

        else if message.event == 'destroy'
          attachment = $filter('filter')(vm.attachments, id: message.resource_id)[0]

          if attachment
            vm.attachments.splice(vm.attachments.indexOf(attachment), 1)
            #$mdToast.showSimple($filter('translate')('Attachment deleted'))

      consumer = new ActionCableChannel("AttachmentsChannel")
      consumer.subscribe(callback)
      $scope.$on "$destroy", ->
        consumer.unsubscribe() if consumer


      $scope.$watchCollection 'vm.attachments', (newList, oldList) ->
        res = []
        if newList and newList.length
          for a in newList
            if a.pdf
              res.push
                id: a.id
                printUrl: a.original
                html: "<div class='text-center'><iframe src='#{a.original}' style='margin-top: 44px; width: 90vw; height: 100vh;'></iframe></div>"
                title: a.title
            else if a.office
              res.push
                id: a.id
                html: "<div class='text-center'><iframe style='margin-top: 44px; width: 90vw; height: 100vh;' src='https://docs.google.com/viewer?url=#{a.officeUrl}&w=800&embedded=true'></iframe></div>"
                title: a.title

            else
              res.push
                id: a.id
                printUrl: a.original
                original: a.original
                src: a.original
                w: a.width
                h: a.height
                title: a.title
          vm.slides = res
      , true

      uploader = vm.uploader = new FileUploader({
        url: 'api/attachments.json',
        headers: $auth.retrieveData('auth_headers'),
        formData: [
          {
            attachmentable_id: vm.attachmentableId || '',
            attachmentable_type: vm.attachmentableType || ''
          }
        ]
        autoUpload: true,
        removeAfterUpload: true
      })

      uploader.onSuccessItem = (item, response) ->
        vm.attachments ||= []
        vm.attachments.push(new Attachment(response))

      vm.removeAttachment = (index) ->
        $translate("Sure?").then (title) ->
          if $window.confirm(title)
            vm.attachments.splice(index, 1)
        false

      vm.open1111 = ($index) ->
        modalInstance = $uibModal.open
          animation: true,
          template: require('app/components/attachments/preview.html'),
          windowTemplateUrl: 'app/components/attachments/fullscreen.html'
          controller: 'PreviewController',
          size: 'lg',
          resolve:
            items: -> vm.attachments
            index: -> $index

        false

      return

    directive =
      restrict: 'E'
      template: require('app/components/attachments/attachments.html')
      scope:
        attachments: '='
        form: '='
        editable: '='
        selectable: '='
        removeable: '='
        #itemClass: '@'
        size: '@'
        attachmentableType: '@'
        attachmentableId: '='
      controller: AttachmentsController
      controllerAs: 'vm'
      bindToController: true

