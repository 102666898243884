Controller = ($rootScope, $uibModal, $translate, $window, $filter, $http, toaster, $httpParamSerializer, $auth, DriverNew, ActionCableChannel) ->
  "ngInject"

  consumer = null
  self = this

  @load = ->
    self.drivers = DriverNew.query self.filters, (drivers) ->
      self.performedParams = angular.copy(self.filters)

  callback = (message) ->
    if message.event == 'create'
      load()

    else if message.event == 'sync'
      load()

    else if message.event == 'update'
      for driver in self.drivers
        if driver.id == message.resource_id
          driver.$get()
          return

    else if message.event == 'destroy'
      driver = $filter('filter')(self.drivers, id: message.resource_id)[0]

      if driver
        self.drivers.splice(self.drivers.indexOf(driver), 1)
        #if $rootScope.user.id != message.user_id
        #  toaster.pop('info', $filter('translate')('Trip deleted'), 'OK')


  @$onInit = ->
    self.filters = {}
    self.load()

    consumer = new ActionCableChannel("DriversChannel")
    consumer.subscribe(callback)

  @$onDestroy = ->
    consumer.unsubscribe()

  @edit = (index) ->
    driver = self.drivers[index]

    modalInstance = $uibModal.open
      animation: true,
      component: 'driverModal'
      size: 'lg',
      resolve:
        driver: -> DriverNew.get(id: driver.id).$promise

    modalInstance.result.then (driver) ->
      if driver?
        self.drivers[index] = driver
      else
        self.drivers.splice(index, 1)

  @requestResponsibleUser = (driver) ->
    DriverNew.requestResponsibleUser id: driver.id, (user) ->
      driver.responsible_user = user

  @export = ->
    params = angular.extend($auth.retrieveData('auth_headers'), self.performedParams)
    params['_'] = (new Date).getTime()

    self.downloadUrl = "/api/drivers.xlsx?#{$httpParamSerializer(params)}"

  this
 
angular.module 'logisticsApp'
  .component 'driversList',
    template: require('app/drivers/list/list.html')
    controller: Controller
    bindings:
      staticParams: '<'

  .config ($stateProvider, $urlRouterProvider) ->
    'ngInject'
    $stateProvider
      .state 'app.drivers',
        url: '/drivers'
        component: 'driversList'
        data:
          permissions:
            only: 'listDrivers'
