angular.module 'logisticsApp'
  .factory "DebitPayment", ($resource, $auth) ->

    transformResponseAttributes = (data) ->
      attrs = angular.fromJson(data)
      if attrs.at
        attrs.at = moment(attrs.at).format('DD.MM.YYYY')
      if attrs.account
        delete attrs.account
      attrs

    $resource('/api/debit_payments/:id.json', {id: '@id'}, {
      get: { method: 'GET', transformResponse: transformResponseAttributes },
      update: { method: 'PATCH' },
    })

