import "app/common/services/driver_transition.service";

Controller = (DriverTransition) ->
  "ngInject"

  self = this

  @$onInit = ->
    self.transitions = DriverTransition.query('q[driver_id_eq]': self.driverId)

  this

angular.module 'logisticsApp'
  .component 'driverTransitions',
    template: require('app/common/components/driver-transitions/driver-transitions.html')
    controller: Controller
    bindings:
      driverId: '<'
