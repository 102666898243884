angular.module 'logisticsApp'
  .factory "WayBill", ($resource, $auth, $httpParamSerializer) ->

    transformResponseAttributes = (data) ->
      attrs = angular.fromJson(data)
      attrs.file_url = "#{attrs.file_url}?#{$httpParamSerializer($auth.retrieveData('auth_headers'))}"
      attrs

    $resource('/api/way_bills/:id.json', {id: '@id'}, {
      get: { method: 'GET', transformResponse: transformResponseAttributes },
    })

