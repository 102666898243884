import "app/common/services/exchange_rate.service";

Controller = ($rootScope, $translate, $window, $http, $httpParamSerializer, $auth, User) ->
  "ngInject"

  consumer = null
  self = this

  @$onInit = ->
    self.exchange_rate = self.resolve.exchange_rate
    self.auth_params = $httpParamSerializer($auth.retrieveData('auth_headers'))

  @cancel = ->
    self.dismiss({$value: 'cancel'})

  @save = ->
    if self.exchange_rate.id
      self.exchange_rate.$update ->
        self.close({$value: self.exchange_rate})
    else
      self.exchange_rate.$save ->
        self.close({$value: self.exchange_rate})

  this
 
angular.module 'logisticsApp'
  .component 'exchangeRateModal',
    template: require('app/settings/exchange_rates/modal/modal.html')
    controller: Controller
    bindings:
      resolve: '<'
      close: '&'
      dismiss: '&'
