angular.module 'logisticsApp'
  .controller 'BanksController', ($scope, Bank, Organization, $translate, $window) ->
    'ngInject'

    $scope.rowCollection = []
    $scope.displayedCollection = []
    $scope.organizations = []

    Bank.query().then (banks) ->
      angular.copy(banks, $scope.rowCollection)
      $scope.displayedCollection = [].concat($scope.rowCollection)

    $scope.removeBank = (index) ->
      $translate("Sure?").then (title) ->
        if $window.confirm(title)
          bank = $scope.displayedCollection[index]
          if bank.id
            promise = bank.remove()
            promise.then ->
              $scope.rowCollection.splice(index, 1)
            promise
          else
            $scope.rowCollection.splice(index, 1)

    $scope.saveBank = (bank) ->
      bank.save()

    $scope.addBank = ->
      $scope.inserted = new Bank()
      $scope.rowCollection.push($scope.inserted)

    $scope.loadOrganizations = ->
      if $scope.organizations.length
        null
      else
        Organization.query().then (data) ->
          $scope.organizations = data
