Controller = ($rootScope, $uibModal, $translate, $http, $window) ->
  "ngInject"

  self = this

  @$onInit = ->
    self.purse_balances = []

    $http.get('/api/purses/balances.json', params: { 'q[is_dashboard_visible_true]': true }).then (response) ->
      for organization in response.data
        self.purse_balances = self.purse_balances.concat(organization.purses)

  this
 
angular.module 'logisticsApp'
  .component 'dashboardPurses',
    template: require('app/dashboard/purses/purses.html')
    controller: Controller
