Controller = ($rootScope, $translate, $window, $http, $httpParamSerializer, $auth, Driver) ->
  "ngInject"

  consumer = null
  self = this

  @$onInit = ->
    self.auth_params = $httpParamSerializer($auth.retrieveData('auth_headers'))
    self.driver_card = self.resolve.driver_card

    Driver.query().then (drivers) ->
      self.drivers = drivers

  @cancel = ->
    self.dismiss({$value: 'cancel'})

  @save = ->
    if self.driver_card.id
      self.driver_card.$update ->
        self.close({$value: self.driver_card})
    else
      self.driver_card.$save ->
        self.close({$value: self.driver_card})

  @delete = ->
    $translate("Sure?").then (title) =>
      if $window.confirm(title)
        self.driver_card.$delete ->
          self.close({$value: self.driver_card})

  @copyCardData = ->
    self.driver_card.card_data = self.driver_card.number

  this
 
angular.module 'logisticsApp'
  .component 'driverCardModal',
    template: require('app/driver_cards/modal/modal.html')
    controller: Controller
    bindings:
      resolve: '<'
      close: '&'
      dismiss: '&'
