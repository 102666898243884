Controller = ($rootScope, $uibModal, $translate, $window, $interval, $filter, $http, $auth, $httpParamSerializer, InterpipeTender, Pvd, VehicleNew, PermitType, ActionCableChannel, toaster) ->
  "ngInject"

  consumer = null
  self = this

  @prevPage = ->
    self.filters.page = self.pagination.prev_page
    self.load()

  @nextPage = ->
    self.filters.page = self.pagination.next_page
    self.load()

  @statusChanged = ->
    for s in self.statuses
      delete self.filters["q[#{s}]"]

    if self.filter_by_status
      self.filters["q[#{self.filter_by_status}]"] = true

    self.load()

  @load = ->
    InterpipeTender.query self.filters, (tenders, responseHeaders) ->
      self.tenders = tenders
      self.pagination = {
        total_count: parseInt(responseHeaders()['x-total-count']),
        next_page: responseHeaders()['x-next-page'],
        prev_page: responseHeaders()['x-prev-page'],
      }
      self.pagination.first = (self.filters.page - 1) * self.filters.limit + 1
      self.pagination.last = self.pagination.first + tenders.length - 1

  callback = (message) ->
    if message.message
      self.last_bot_message = message.message

    if message.id?
      item = $filter('filter')(self.tenders, id: message.id)[0]

      if item?
        if message.updated_at != item.updated_at
          InterpipeTender.get id: message.id, (updated_item) ->
            angular.extend(item, updated_item)
      else
        if self.pagination.next_page == 2
          InterpipeTender.get id: message.id, (new_item) ->
            unless (self.tenders.some (item) -> item.id == new_item.id)
              self.tenders.unshift(new_item)

  @$onInit = ->
    self.now_ts = moment().unix()
    self.ticker = $interval ->
      self.now_ts = moment().unix()
    , 1000

    self.selected = []

    self.filters = {
      page: 1
      limit: 25
    }

    self.filters['q[created_at_gt]'] = moment().startOf('day')

    self.statuses = ['pending', 'reserved', 'waiting_for_transfer', 'waiting_for_using', 'using', 'waiting_for_office', 'waiting_for_returning']
    angular.extend(self.filters, self.staticParams) if self.staticParams
    self.load()

    consumer = new ActionCableChannel("InterpipeChannel")
    consumer.subscribe(callback)

  @update = (tender) ->
    tender.$update min_bid_price: tender.min_bid_price, (updated_tender) ->
      toaster.pop('success', $filter('translate')('Min price saved'))

  @start = ->
    return if self.isStarted

    self.isStarted = true
    $http.post('/api/interpipe/tenders/start').then ->
      toaster.pop('success', $filter('translate')('Bot started'))

  @refresh = ->
    $http.post('/api/interpipe/tenders/refresh').then ->
      toaster.pop('success', $filter('translate')('Refresh scheduled'))

  this
 
angular.module 'logisticsApp'
  .component 'interpipeTendersList',
    template: require('app/interpipe/tenders/list/list.html')
    controller: Controller
    bindings:
      staticParams: '<'

  .config ($stateProvider, $urlRouterProvider) ->
    'ngInject'
    $stateProvider
      .state 'app.interpipeTenders',
        url: '/interpipe/tenders'
        component: 'interpipeTendersList'
        data:
          permissions:
            only: 'interpipeTenders'
