Controller = (Garage) ->
  "ngInject"

  @$onInit = =>
    @garage = @resolve.garage

  @ok = =>
    @close($value: @garage)

  @cancel = =>
    @dismiss($value: 'cancel')

  this

angular.module 'logisticsApp'
  .component 'garageForm',
    template: require('app/components/garages/form.html')
    controller: Controller
    bindings:
      resolve: '<'
      close: '&'
      dismiss: '&'
