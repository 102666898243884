Controller = (PartnerNew, User) ->
  "ngInject"

  self = this

  @update = (field_name) ->
    self.onUpdate name: field_name

  @loadUsers = (q) =>
    User.query(q: q).then (users) =>
      @users = users

  @updateUsers = (users) ->
    PartnerNew.update(id: self.partner.id, partner: {user_ids: users.map (u) -> u.id})

  this

angular.module 'logisticsApp'
  .component 'partnerForm',
    template: require('app/partners/form/form.html')
    controller: Controller
    bindings:
      partner: '<'
      onUpdate: '&'

