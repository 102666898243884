Controller = ($http, $localStorage, Vehicle, ActionCableChannel, toaster) ->
  "ngInject"

  consumer = null

  $localStorage.planing ||= {}
 
  @saveFilters = =>
    $localStorage.planing.starts_at = @starts_at
    $localStorage.planing.ends_at = @ends_at
    $localStorage.planing.vehicle_filter = @vehicle_filter
    $localStorage.planing.address_filter = @address_filter

  @today = =>
    @starts_at = moment().toDate()
    @ends_at = moment().toDate()
    @refresh()

  @currentWeek = =>
    @starts_at = moment().startOf('isoWeek').toDate()
    @ends_at = moment().endOf('isoWeek').toDate()
    @refresh()

  @refresh = (auto=false) =>
    @saveFilters()

    $http(
      method: 'GET'
      url: '/api/trips/planing.json'
      params:
        starts_at: moment(@starts_at).format("YYYY-MM-DD")
        ends_at: moment(@ends_at).format("YYYY-MM-DD")
        auto: auto
    ).then (response) =>
      @countries = response.data.countries
      @days = response.data.days
      @today_index = response.data.days.indexOf(moment().format("YYYY-MM-DD"))
      if auto
        @starts_at = moment(@days[0]).toDate()
        @ends_at = moment(@days[-1]).toDate()

  callback = (message) =>
    @refresh()
    if message.action == 'create'
      toaster.pop('success', "Створено рейс #{message.title}")
    else if message.action == 'update'
      toaster.pop('success', "Змінено рейс #{message.title}")

  @$onInit = =>
    consumer = new ActionCableChannel("TripsChannel")
    consumer.subscribe(callback)
    @vehicle_filter = $localStorage.planing.vehicle_filter
    @address_filter = $localStorage.planing.address_filter
    if $localStorage.planing.starts_at
      @starts_at = moment($localStorage.planing.starts_at)
      @ends_at = moment($localStorage.planing.ends_at)
      @refresh()
    else
      @currentWeek()

 

  @$onDestroy = =>
    consumer.unsubscribe() if consumer


  this

angular.module 'logisticsApp'
  .component 'planing',
    template: require('app/components/planing/planing.html')
    controller: Controller
