angular.module 'logisticsApp'
  .factory "Email", (RailsResource, railsSerializer, $translate) ->
    class Email extends RailsResource
      @configure
        url: "/api/emails"
        rootWrapping: false
        name: "emails"
        serializer: railsSerializer ->
          @exclude('attachments')
          @nestedAttribute('attachments')
          @resource('attachments', 'Attachment')
          @add 'attachment_ids', (email) ->
            if email.attachments
              email.attachments.map (attachment) -> attachment.id
