angular.module 'logisticsApp'
  .directive 'acmerefills', ->

    RefillsController = (Refill, Vehicle, FuelCard) ->
      'ngInject'
      vm = this

      vm.items = []

      vm.callServer = (tableState) ->
        vm.isLoading = true

        console.log tableState
        pagination = tableState.pagination

        start = pagination.start || 0
        number = pagination.number || 10

        Refill.query(start: start, number: number).then (resp) ->
          vm.items = resp.data.map (attrs) -> new Refill(attrs)
          tableState.pagination.numberOfPages = resp.numberOfPages
          vm.isLoading = false

      vm.removeRefill = (index) ->
        refill = vm.items[index]
        promise = refill.remove()
        promise.then ->
          vm.items.splice(index, 1)
        promise

      vm.saveRefill = (refill) ->
        refill.save()

      vm.addRefill = ->
        vm.inserted = new Refill()
        vm.items.unshift(vm.inserted)

      vm.fuelCards = []
      vm.loadFuelCards = ->
        if vm.fuelCards.length
          null
        else
          FuelCard.query().then (fuelCards) ->
            vm.fuelCards = fuelCards

      vm.vehicles = []
      vm.loadVehicles = ->
        if vm.vehicles.length
          null
        else
          Vehicle.query().then (vehicles) ->
            vm.vehicles = vehicles

      vm.loadVehicles()
      vm.loadFuelCards()

      return

    directive =
      restrict: 'E'
      template: require('app/components/refills/refills.html')
      controller: RefillsController
      controllerAs: 'vm'
      bindToController: true
