angular.module 'logisticsApp'
  .controller 'SendEmailController', ($scope, $http, $uibModalInstance, attachments, Email) ->
    $scope.mail = new Email attachments: attachments

    $scope.removeAttachment = (index) ->
      $scope.mail.attachments.splice(index, 1)
      false
      
    $scope.sendMail = ->
      $scope.mail.save().then ->
        $uibModalInstance.close()

    $scope.dissmiss = ->
      $uibModalInstance.dismiss('cancel')

    $scope.getEmailContact = (value) ->
      $http.get('/api/email_contacts.json', {
        params: {
          q: value
        }
      }).then (response) ->
        response.data
        
