angular.module 'logisticsApp'
  .controller 'ClientResetCtrl', ($scope, $auth) ->
    'ngInject'

    $scope.requestPasswordReset = (email) ->
      $auth.requestPasswordReset({email: email}, {config: 'client'})
        .then ->
          $scope.isCollapsed = false

    $scope.$on 'auth:password-reset-request-error', (ev, reason) ->
      $scope.authError = reason.errors[0]
