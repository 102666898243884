angular.module 'logisticsApp'
  .factory "DriverNew", ($resource) ->

    transformAttributes = (driver) ->
      attrs = {}
      angular.copy driver, attrs

      if driver.attachments
        attrs.attachment_ids = driver.attachments.map (attachment) -> attachment.id
        delete driver.attachments

      if driver.phone_numbers
        attrs.phone_numbers_attributes = {}
        for phone, index in driver.phone_numbers
          attrs.phone_numbers_attributes[index] = {
            id: phone.id,
            number: phone.number,
            comment: phone.comment,
            _destroy: phone._destroy
          }
        delete driver.phone_numbers

      angular.toJson(driver: attrs)

    $resource('/api/drivers/:id.json', {id: '@id'}, {
      save: { method: 'POST', transformRequest: transformAttributes },
      requestResponsibleUser: { method: 'GET', url: '/api/drivers/:id/request_responsible_user.json' },
      update: { method: 'PUT', transformRequest: transformAttributes },
    })

