Controller = ($rootScope, $translate, $window, VehicleNew, User) ->
  "ngInject"

  self = this

  @$onInit = ->
    self.attrs =
      vehicle_ids: self.resolve.vehicleIds
      responsible_user_id: null
      follow_comment: ''

    User.query(role: 'logist').then (users) ->
      self.users = users#.concat([{fullname: 'Not selected'}])

  @cancel = ->
    self.dismiss({$value: 'cancel'})

  @save = ->
    VehicleNew.batchUpdate self.attrs, ->
      self.close({$value: self.attrs.responsible_user_id})

  this
 
angular.module 'logisticsApp'
  .component 'transferVehicleModal',
    template: require('app/vehicles/transfer/transfer.html')
    controller: Controller
    bindings:
      resolve: '<'
      close: '&'
      dismiss: '&'
