import "app/common/services/maintenance_invoice.service";

import "app/maintenance/invoices/modal/modal.component";

Controller = ($rootScope, $uibModal, $translate, $window, $http, $auth, $httpParamSerializer, MaintenanceInvoice, ActionCableChannel) ->
  "ngInject"

  consumer = null
  self = this

  @prevPage = ->
    self.filters.page = self.pagination.prev_page
    self.load()

  @nextPage = ->
    self.filters.page = self.pagination.next_page
    self.load()

  @load = ->
    MaintenanceInvoice.query self.filters, (invoices, responseHeaders) ->
      self.invoices = invoices
      self.pagination = {
        total_count: parseInt(responseHeaders()['x-total-count']),
        next_page: responseHeaders()['x-next-page'],
        prev_page: responseHeaders()['x-prev-page'],
      }
      self.pagination.first = (self.filters.page - 1) * self.filters.limit + 1
      self.pagination.last = self.pagination.first + invoices.length - 1

  callback = (message) ->
    self.load()

  @$onInit = ->
    self.filters = {
      page: 1
      limit: 25
    }
    self.load()

    consumer = new ActionCableChannel("PermitsChannel")
    consumer.subscribe(callback)

  @edit = (invoice) ->
    modalInstance = $uibModal.open(
      animation: true,
      component: 'maintenanceInvoiceModal'
      size: 'lg',
      resolve:
        invoice: -> MaintenanceInvoice.get(id: invoice.id).$promise
    ).result.then (updated_invoice) ->
      if updated_invoice == 'deleted'
        self.invoices.splice(self.invoices.indexOf(invoice), 1)
      else
        angular.extend(invoice, updated_invoice)

  @new = (kind) ->
    MaintenanceInvoice.save kind: kind, (created_invoice) ->
      self.invoices.unshift(created_invoice)
      self.edit(created_invoice)

  @remove = (index) ->
    $translate("Sure?").then (title) =>
      if $window.confirm(title)
        MaintenanceInvoice.delete id: self.invoices[index].id, ->
          self.invoices.splice(index, 1)
 
  this
 
angular.module 'logisticsApp'
  .component 'invoicesList',
    template: require('app/maintenance/invoices/list/list.html')
    controller: Controller

  .config ($stateProvider, $urlRouterProvider) ->
    'ngInject'
    $stateProvider
      .state 'app.maintenance.invoices',
        url: '/invoices'
        component: 'invoicesList'
