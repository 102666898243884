Controller = ($rootScope, $uibModal, $translate, $window, InfoContact) ->
  "ngInject"

  consumer = null
  self = this

  @prevPage = ->
    self.filters.page = self.pagination.prev_page
    self.load()

  @nextPage = ->
    self.filters.page = self.pagination.next_page
    self.load()

  @load = ->
    InfoContact.query self.filters, (info_contacts, responseHeaders) ->
      self.info_contacts = info_contacts

      self.filters.page = parseInt(responseHeaders()['x-current-page']) if responseHeaders()['x-current-page']?

      self.pagination = {
        total_count: parseInt(responseHeaders()['x-total-count']),
        next_page: responseHeaders()['x-next-page'],
        prev_page: responseHeaders()['x-prev-page'],
      }
      self.pagination.first = (self.filters.page - 1) * self.filters.limit + 1
      self.pagination.last = self.pagination.first + info_contacts.length - 1

  @$onInit = ->
    self.filters = {
      page: 1
      limit: 25
    }
    self.load()

  @edit = (info_contact) ->
    modalInstance = $uibModal.open
      animation: true,
      component: 'infoContactModal'
      size: 'md',
      resolve:
        info_contact: -> InfoContact.get(id: info_contact.id).$promise

    modalInstance.result.then ->
      self.load()

  @remove = (index) ->
    $translate("Sure?").then (title) =>
      if $window.confirm(title)
        InfoContact.delete id: self.info_contacts[index].id, ->
          self.info_contacts.splice(index, 1)

  @add = ->
    modalInstance = $uibModal.open
      animation: true,
      component: 'infoContactModal'
      size: 'lg',
      resolve:
        info_contact: -> new InfoContact

    modalInstance.result.then (info_contact) ->
      self.info_contacts.unshift(info_contact)
 
  this
 
angular.module 'logisticsApp'
  .component 'infoContactsList',
    template: require('app/settings/info_contacts/list/list.html')
    controller: Controller

  .config ($stateProvider, $urlRouterProvider) ->
    'ngInject'
    $stateProvider
      .state 'app.settings.info_contacts',
        url: '/info_contacts'
        component: 'infoContactsList'
        data:
          permissions:
            only: 'editInfoContacts'
