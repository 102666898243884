angular.module 'logisticsApp'
  .factory "Document", (RailsResource, railsSerializer) ->
    class Document extends RailsResource
      @configure
        url: "/api/documents"
        name: "document"
        serializer: railsSerializer ->
          @exclude('attachments')
          @nestedAttribute('attachments', 'notifications')
          @resource('attachments', 'Attachment')
          @resource('notifications', 'Notification')
          @add 'attachment_ids', (document) ->
            if document.attachments
              document.attachments.map (attachment) -> attachment.id

      printUrl: ->
        "/api/documents/#{@id}/print"
