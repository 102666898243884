angular.module 'logisticsApp'
  .factory "CemtLicence", ($resource) ->


    transformAttributes = (agreement) ->
      attrs = {}
      angular.copy agreement, attrs
      if attrs.issued_date
        attrs.issued_date = moment(attrs.issued_date).format('YYYY-MM-DD')
      angular.toJson(attrs)

    transformResponseAttributes = (data) ->
      attrs = angular.fromJson(data)
      if attrs.issued_date
        attrs.issued_date = moment(attrs.issued_date).toDate()
      attrs

    $resource('/api/cemt_licences/:id.json', {id: '@id'}, {
      get: { method: 'GET', transformResponse: transformResponseAttributes },
      save: { method: 'POST', transformRequest: transformAttributes },
      update: { method: 'PATCH', transformRequest: transformAttributes }
    })

