Controller = (Trip) ->
  "ngInject"

  @$onInit = =>
    @displayedCollection = []

  @callServer = (tableState) =>
    @isLoading = true

    tableState.pagination.numberOfPages = @summary.numberOfPages

    pagination = tableState.pagination
    sort = tableState.sort or {}

    params =
      page: (pagination.start || 0) / pagination.number + 1
      number: pagination.number
      "sort[#{sort.predicate}]": sort.reverse

    @onLoad({params: params}).then (trips) =>
      @displayedCollection = trips
      @isLoading = false

  this
 
angular.module 'logisticsApp'
  .component 'tripsList',
    template: require('app/components/trips/trips-list.html')
    controller: Controller
    bindings:
      summary: '<?'
      onLoad: '&'
