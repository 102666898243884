angular.module 'logisticsApp'
  .controller 'EditTripController', ($scope, $state, $translate, $stateParams, $timeout, $http, $filter, Trip, Purse, Notification, Vehicle, Trailer, Driver, Client, Partner, Payment, Organization, User, Label, Cargo, $uibModal, ClientNew) ->
    'ngInject'

    $scope.payment_methods = [
      {value: 'cashless', text: 'Cashless'},
      {value: 'cash', text: 'Cash'},
      {value: 'bank_card', text: 'Bank card'},
      {value: 'driver_cash', text: 'Driver cash'}
    ]

    $scope.kinds = [
      {value: 'download', text: 'Download'},
      {value: 'execute', text: 'Execute'},
      {value: 'customs', text: 'Customs'},
      {value: 'idle', text: 'Idle'},
      {value: 'upload', text: 'Upload'}
    ]
    $scope.labels = []
    $scope.comments = []
    $scope.partners = []
    $scope.vehicles = []

    $scope.minDate = new Date()
    $scope.purses = Purse.query()

    trip = null
    Trip.get($stateParams.id).then (t) ->
      trip = $scope.trip = t
      trip.downloadAt = moment(trip.downloadAt).toDate() if trip.downloadAt
      trip.deliveredAt = moment(trip.deliveredAt).toDate() if trip.deliveredAt
      trip.scheduledDeliveryDate = moment(trip.scheduledDeliveryDate).toDate() if trip.scheduledDeliveryDate

      $scope.$parent.clients = [trip.client]
      $scope.$parent.users = [trip.user]
      $scope.$parent.directions = [trip.direction]

      Label.query().then (labels) ->
        $scope.labels = labels
        for id in trip.labelIds
          $filter('filter')($scope.labels, {id: id})[0].selected = true

    $scope.selectTruck = (crew, truck) ->
      crew.driverId = truck.driverIds[0]
      crew.trailerId = truck.trailerIds[0]

    $scope.selectDriver = (item, model) ->
      trip.driverId = item.driverId
    $scope.selectPartner = (item, model) ->
      trip.partnerId = item.id

    $http.get('/api/payments/comments').then (response) ->
      angular.copy(response.data, $scope.comments)

    Driver.query().then (drivers) ->
      $scope.drivers = drivers
    Vehicle.query().then (vehicles) ->
      $scope.vehicles = vehicles
    Partner.query().then (partners) ->
      $scope.partners = partners
    Organization.query().then (organizations) ->
      $scope.organizations = organizations

    $scope.trailers = Trailer.query()

    $scope.removePayment = (index) ->
      if $scope.trip.payments[index].id?
        $scope.trip.payments[index]._destroy = true
      else
        $scope.trip.payments.splice(index, 1)

    $scope.addPayment = ->
      $scope.inserted = new Payment()
      $scope.trip.payments.push($scope.inserted)

    $scope.removeCarrierPayment = (index) ->
      $scope.trip.partnerPayments.splice(index, 1)

    $scope.addCarrierPayment = ->
      $scope.inserted = new Payment()
      $scope.trip.partnerPayments.push($scope.inserted)
    
    $scope.save = ->
      $scope.trip.downloadAt = moment($scope.trip.downloadAt).format("YYYY-MM-DD") if $scope.trip.downloadAt
      $scope.trip.deliveredAt = moment($scope.trip.deliveredAt).format("YYYY-MM-DD") if $scope.trip.deliveredAt
      $scope.trip.scheduledDeliveryDate = moment($scope.trip.scheduledDeliveryDate).format("YYYY-MM-DD") if $scope.trip.scheduledDeliveryDate
      $scope.trip.labelIds = $filter('filter')($scope.labels, {selected: true}).map((l) -> l.id)
      $scope.trip.cost = $scope.trip.costAmount

      if $scope.clientType != 'new_client'
        delete $scope.trip.client

      for crew in $scope.trip.crews
        if crew.$vehicleType != 'new_vehicle'
          delete crew.vehicle
        else
          crew.vehicle.partnerId = crew.$partnerId

      $scope.trip.save().then ->
        $scope.goBack()


    newLabel = $scope.newLabel = new Label

    $scope.selectLabel = (label) ->
      trip.directionId = label.directionId
      true

    $scope.addLabel = ->
      newLabel.save().then (label) ->
        $scope.labels.push(label)
        newLabel = $scope.newLabel = new Label

    $scope.removeLabel = (index) ->
      $translate("Sure?").then (title) ->
        if $window.confirm(title)
          $scope.labels[index].remove().then ->
            $scope.labels.splice(index, 1)
      false

    $scope.getCargos = (q) ->
      Cargo.query('q[title_cont]': q).$promise

    $scope.crewPriceChanged = ->
      crews_total_sum = 0.0
      for crew in $scope.trip.crews
        crews_total_sum += crew.price

      $scope.trip.crewsTotalSum = crews_total_sum

    $scope.addCrew = ->
      $scope.trip.build_crew()

    $scope.removeCrew = (index) ->
      $scope.trip.destroy_crew(index)

    $scope.createClient = (organization) ->
      modalInstance = $uibModal.open
        animation: true,
        component: 'clientModal'
        size: 'lg',
        resolve:
          client: -> new ClientNew(organization: organization, phones: [''])

      modalInstance.result.then (created_client) ->
        $scope.clients = [created_client]
        $scope.trip.clientId = created_client.id

