import "app/common/services/payment_new.service";

Controller = ($translate, $stateParams, $window, $rootScope, $q, $filter, $localStorage, $uibModal, $http, $state, PaymentNew, Document, Client, Vehicle, Organization, Purse) ->
  "ngInject"

  self = this

  @$onInit = ->
    self.now_ts = moment().unix()
    self.pagination = {
      page: 1
      limit: 25
    }

    $http.get('/api/purses/balances.json').then (response) ->
      self.organization_balances = response.data

    Vehicle.query('q[with_trips]': true).then (vehicles) ->
      self.vehicles = [id: null, number: $filter('translate')('All')].concat(vehicles)

    Organization.query().then (organizations) ->
      self.organizations = organizations

    self.purses = Purse.query()

    self.persistKey = "main-office-#{$rootScope.user.current_main_office_id}:payments"

    $localStorage.filters ||= {}
    self.filters = $localStorage.filters[self.persistKey]

    if self.filters?
      for filter, value of self.filters
        if (filter.indexOf('download_at') > -1) or (filter.indexOf('delivered_at') > -1)
          self.filters[filter] = moment(value).toDate() if value? and value.length > 0
    else
      self.filters = angular.copy(self.initialFilters)

    if $stateParams.purseId
      self.filters.purse_id_within = $stateParams.purseId

    self.load()

  @refreshClients = (q) ->
    $q (resolve, reject) ->
      Client.query('q[organization_cont]': q, 'q[active]': true, only: 'organization').then (clients) ->
        self.clients = [id: null, organization: $filter('translate')('All')].concat(clients)
        resolve()
      , reject

  @getAccounts = (q) ->
    Document.query(type: 'Account', q: q, page: 1).then (accounts) ->
      accounts.map (a) ->
        a.title

  @selectYear = (year) ->
    self.filters ||= {}
    if year
      self.filters.at_gteq = moment([year, 0, 1]).toDate()
      self.filters.at_lteq = moment([year, 11, 31]).toDate()
    else
      self.filters.at_gteq = null
      self.filters.at_lteq = null
      
    self.load()
    true

  @remove = (index) =>
    $translate("Sure?").then (title) =>
      if $window.confirm(title)
        payment = @displayedCollection[index]
        if payment.id
          #promise = payment.remove()
          #promise.then ->
          #  @displayedCollection.splice(index, 1)
          payment.canceled = true
          payment.save()
        else
          @displayedCollection.splice(index, 1)
 
  @clearFilters = ->
    self.filters = { scopes: { paid: 'only_paid' } }
    self.selectYear((new Date()).getFullYear())
    self.load()

  @load = ->
    self.isLoading = true

    params = angular.copy(self.pagination)
    #params['q[trip_result_null]'] = true
    params.totals = true

    for filter, value of self.filters
      if filter == 'scopes'
        for _,filter_scope of value
          params["q[#{filter_scope}]"] = true if filter_scope? and filter_scope.length > 0
      else
        params["q[#{filter}]"] = value

    $localStorage.filters[self.persistKey] = self.filters

    PaymentNew.getList params, (data, responseHeaders) ->
      self.payments = data.payments
      self.summary = data.summary

      self.pagination.total_count = parseInt(responseHeaders()['x-total-count'])
      self.pagination.page = responseHeaders()['x-current-page']
      self.years = data.availableYears

      self.isLoading = false

  @edit = (index) ->
    payment = self.payments[index]

    modalInstance = $uibModal.open
      animation: true,
      component: 'paymentModal'
      size: 'md',
      resolve:
        payment: -> PaymentNew.get(id: payment.id).$promise
        trip: -> self.trip

  @remove = (index) ->
    $translate("Sure?").then (title) =>
      if $window.confirm(title)
        PaymentNew.delete id: self.payments[index].id, ->
          self.payments.splice(index, 1)

  @editPurse = (purse) ->
    modalInstance = $uibModal.open
      animation: true,
      component: 'purseModal'
      size: 'sm',
      resolve:
        purse: -> Purse.get(id: purse.id).$promise

    modalInstance.result.then ->
      $state.go('app.payments', {}, { reload: 'app.payments' })

  this

angular.module 'logisticsApp'
  .component 'paymentsTable',
    template: require('app/payments/table/table.html')
    controller: Controller

  .config ($stateProvider, $urlRouterProvider) ->
    'ngInject'
    $stateProvider
      .state 'app.payments',
        url: '/payments?purseId'
        component: 'paymentsTable'
        data:
          permissions:
            only: 'readAllPayments'
