angular.module('logisticsApp').controller('AppCtrl', ['$scope', '$rootScope', '$translate', '$localStorage', '$window', 'tmhDynamicLocale', '$uibModal', 'Email', 'ActionCableChannel', 'toaster',
	function(              $scope, $rootScope,   $translate,   $localStorage,   $window, tmhDynamicLocale, $uibModal, Email, ActionCableChannel, toaster ) {
		// add 'ie' classes to html
		var isIE = !!navigator.userAgent.match(/MSIE/i);
		isIE && angular.element($window.document.body).addClass('ie');
		isSmartDevice( $window ) && angular.element($window.document.body).addClass('smart');


		// config
		$scope.app = {
			name: 'ulashyn.com',
			version: '0.0.1',
			// for chart colors
			color: {
				primary: '#7266ba',
				info:    '#23b7e5',
				success: '#27c24c',
				warning: '#fad733',
				danger:  '#f05050',
				light:   '#e8eff0',
				dark:    '#3a3f51',
				black:   '#1c2b36'
			},
			settings: {
				themeID: 1,
				navbarHeaderColor: 'bg-black',
				navbarCollapseColor: 'bg-white-only',
				asideColor: 'bg-black',
				headerFixed: true,
				asideFixed: false,
				asideFolded: false,
				asideDock: false,
				container: false,
				table_mode: true
			},
			dateFormat: {
				startingDay: 1
			},
			attachments: [],
			currencies: [
				'USD',
				'EUR',
				'RUB',
				'UAH'
			]
		}

		$rootScope.app = $scope.app

		// save settings to local storage
		if ( angular.isDefined($localStorage.settings) ) {
			$scope.app.settings = $localStorage.settings;
		} else {
			$localStorage.settings = $scope.app.settings;
		}
		$scope.$watch('app.settings', function(){
			if( $scope.app.settings.asideDock  &&  $scope.app.settings.asideFixed ){
				// aside dock and fixed must set the header fixed.
				$scope.app.settings.headerFixed = true;
			}
			// for box layout, add background image
			$scope.app.settings.container ? angular.element('html').addClass('bg') : angular.element('html').removeClass('bg');
			// save to local storage
			$localStorage.settings = $scope.app.settings;
		}, true);

		// angular translate
		$scope.lang = { isopen: false };
		$scope.langs = {en:'English', ru_RU:'Русский', uk_UK:'Українська'};
		$scope.locales = {en:'en', ru_RU:'ru-ua', uk_UK:'uk-ua'};
		tmhDynamicLocale.set($localStorage.locale);
		$scope.selectLang = $scope.langs[$translate.proposedLanguage()] || "English";
		$scope.setLang = function(langKey, $event) {
			// set the current lang
			$scope.selectLang = $scope.langs[langKey];
			// You can change the language during runtime
			$translate.use(langKey);
			tmhDynamicLocale.set($scope.locales[langKey]).then(function () {
				$localStorage.locale = $scope.locales[langKey];
			});
			$scope.lang.isopen = !$scope.lang.isopen;
		};

		function isSmartDevice( $window )
		{
			// Adapted from http://www.detectmobilebrowsers.com
			var ua = $window['navigator']['userAgent'] || $window['navigator']['vendor'] || $window['opera'];
			// Checks for iOs, Android, Blackberry, Opera Mini, and Windows mobile devices
			return (/iPhone|iPod|iPad|Silk|Android|BlackBerry|Opera Mini|IEMobile/).test(ua);
		}

		if (angular.isDefined($localStorage.attachments)) {
			$scope.app.attachments = $localStorage.attachments;
		} else {
			$localStorage.attachments = $scope.app.attachments;
		}

		$scope.$watch('app.attachments', function(){
			$localStorage.attachments = $scope.app.attachments;
		}, true);

		$scope.attachToEmail = function (attachments, owner) {
			$scope.app.attachments = $scope.app.attachments.concat(attachments.map(function (a) { 
				return {id: a.id, title: a.title, owner: owner};
			}));
		};

		$scope.removeEmailAttachment = function (index) {
			console.log(index);
			$scope.app.attachments.splice(index, 1);
			return false;
		};

		onCableMessage = function (data) {
			if (data.all) {
				$scope.counters = data.all
			} else {
				$scope.counters[data.counter] = $scope.counters[data.counter] + data.delta;
			}
		};

		consumer = new ActionCableChannel("CountersChannel", { main_office_id: $localStorage.main_office_id });
		consumer.subscribe(onCableMessage).then(function () {
			$scope.$on("$destroy", function() {
				consumer.unsubscribe();
			});
		});

		$scope.counters = {
		};

		$scope.newPartner = function() {
			modalInstance = $uibModal.open({
				template: require('app/partners/new/new.html'),
				controller: 'NewPartnerCtrl',
				size: 'md'
			});
		};

		$rootScope.showErrorMessage = function(body) {
			toaster.pop('error', body)
		};
	}
]);
