angular.module 'logisticsApp'
  .directive 'acmenotifications', ->

    NotificationsController = (Notification) ->
      'ngInject'
      vm = this

      vm.addNotification = ->
        vm.inserted = new Notification()
        vm.notifications ||= []
        vm.notifications.unshift(vm.inserted)

      vm.removeNotification = (index) ->
        vm.notifications.splice(index, 1)

      return

    directive =
      restrict: 'E'
      template: require('app/components/notifications/notifications.html')
      scope:
        notifications: '='
      controller: NotificationsController
      controllerAs: 'vm'
      bindToController: true
