import "app/tasks/modal/modal.component";
import "app/trips/modal/modal.component";
import "app/purses/modal/modal.component";
import "app/clients/modal/modal.component";
import "app/trailers/modal/modal.component";
import "app/maintenance/materials/modal/modal.component";
import "app/common/services/purse.service";
import "app/common/services/task.service";
import "app/common/services/trip_new.service";
import "app/common/services/clients_new.service";
import "app/common/services/payment_new.service";
import "app/common/services/trailer.service";
import "app/common/services/maintenance_material.service";

angular.module 'logisticsApp'
  .controller 'HeaderCtrl', ($scope, $rootScope, $auth, $http, $uibModal, $state, $translate, $filter, toaster, $sanitize, $localStorage, PushNotification, CemtLicence, Permit, DriverNew, DriverCard, FuelCardNew, InfoContact, Task, TripNew, ClientNew, PaymentNew, Purse, Trailer, MaintenanceMaterial, ActionCableChannel) ->
    'ngInject'

    self = this

    $http.get('/api/main_offices.json').then (response) ->
      $scope.organizations = response.data.items

      for o in $scope.organizations
        if response.data.current_main_office_id == o.id
          $scope.main_office = o
      false

    $scope.switchMainOffice = (main_office) ->
      $http.post("/api/main_offices/#{main_office.id}/switch").then (response) ->
        $localStorage.main_office_id = main_office.id
        window.location.reload()

    $scope.unseen_count = 0
    $scope.push_notifications = []
    $scope.active_calls = []

    load = ->
      PushNotification.query().then (push_notifications) ->
        $scope.push_notifications = push_notifications
        $scope.unseen_count = $filter('filter')(push_notifications, {readed: false}).length

    consumer = new ActionCableChannel("PushNotificationsChannel")
    onNewPushNotification = (msg) =>
      if msg.action == 'create'
        PushNotification.get(msg.id).then (notification) ->
          $scope.push_notifications.unshift(notification)
          $scope.unseen_count = $filter('filter')($scope.push_notifications, {readed: false}).length

          toaster.pop
            type: notification.tags,
            title: notification.title,
            body: notification.body,
            bodyOutputType: 'html',
            toastId: notification.id + "",
            clickHandler: (toast, isCloseButton) ->
              n = $filter('filter')($scope.push_notifications, id: parseInt(toast.toastId))[0]
              if n
                markReaded(n)
                unless isCloseButton
                  openNotification(n)
              toaster.clear(n.toastId)

      else if msg.action == 'update'
        push_notification = $filter('filter')($scope.push_notifications, {id: msg.attrs.id})[0]
        angular.extend(push_notification, msg.attrs)
        $scope.unseen_count = $filter('filter')($scope.push_notifications, {readed: false}).length

      else if msg.action == 'calling'
        self.active_call_data = msg.data
        self.active_call_toaster = toaster.pop
          type: "phone-call",
          title: msg.title,
          body: msg.body_html,
          bodyOutputType: 'html',
          clickHandler: (toast, isCloseButton) ->
            if self.active_call_data
              if self.active_call_data.trip_id
                $state.go('app.trips.show', id: self.active_call_data.trip_id)
              else if self.active_call_data.vehicle_id
                $state.go('app.vehicle', id: self.active_call_data.vehicle_id)
            else
              $state.go('app.phone_calls')

            toaster.clear(self.active_call_toaster)

      else if msg.action == 'calling_answered'
        unless msg.user_id == $rootScope.user.id
          toaster.clear(self.active_call_toaster)

      else if msg.action == 'calling_finished'
        self.active_call_data = null
        toaster.clear(self.active_call_toaster)

    consumer.subscribe(onNewPushNotification).then ->
      $scope.$on "$destroy", ->
        consumer.unsubscribe()

    load()

    $scope.reload = ->
      $http.post('/api/push_notifications/readed').then ->
        $scope.unseen_count = 0

      unless $scope.push_notifications.length > 0
        load()

    $scope.logout = ->
      $auth.signOut().then( -> $state.go('user_sign_in'))

    notifyDesktop = (notification) ->
      if  Notification.permission == "granted"
        n = new Notification notification.title,
          body: notification.body
          icon: '/assets/images/logo.png'
          data: notification
          tag: notification.tags + '_' + notification.id

        n.onclick = ->
          markReaded(notification)

    $scope.readNotification = (index) ->
      n = $scope.push_notifications[index]
      markReaded(n)
      openNotification(n)

    markReaded = (n) ->
      unless n.readed
        $scope.unseen_count -= 1 if $scope.unseen_count > 0
        n.readed = true
        n.save()

    openNotification = (n) ->
      if n.data.tripId
        $state.go('app.trips.show', id: n.data.tripId)
      if n.data.chatUserId
        $state.go('app.chat', chat_user_id: n.data.chatUserId)
      if n.data.documentId
        $state.go('app.documents.show', id: n.data.documentId)
      if n.data.emailId
        $state.go('app.mail.detail', id: n.data.emailId)

    $scope.sendmail = ->
      modalInstance = $uibModal.open
        animation: true,
        template: require('app/components/emails/send.html'),
        controller: 'SendEmailController',
        size: 'lg',
        resolve:
          attachments: -> $scope.app.attachments

      modalInstance.result.then ->
        $scope.app.attachments = []
        $translate('Sent successfuly').then (t) ->
          toaster.pop('success', t, 'OK')

      false

    $scope.newCemtLicence = ->
      modalInstance = $uibModal.open
        animation: true,
        component: 'cemtLicenceModal'
        size: 'lg',
        resolve:
          licence: -> new CemtLicence

    $scope.newDriver = ->
      modalInstance = $uibModal.open
        animation: true,
        component: 'driverModal'
        size: 'lg',
        resolve:
          driver: -> new DriverNew

      modalInstance.result.then ->
        $state.go('app.drivers', {}, { reload: 'app.drivers' })

    $scope.newDriverCard = ->
      modalInstance = $uibModal.open
        animation: true,
        component: 'driverCardModal'
        size: 'lg',
        resolve:
          driver_card: -> new DriverCard

      modalInstance.result.then ->
        $state.go('app.driverCards', {}, { reload: 'app.driverCards' })

    $scope.newFuelCard = ->
      modalInstance = $uibModal.open
        animation: true,
        component: 'fuelCardModal'
        size: 'lg',
        resolve:
          fuel_card: -> new FuelCardNew

      modalInstance.result.then ->
        $state.go('app.fuelCards', {}, { reload: 'app.fuelCards' })

    $scope.newPermit = ->
      modalInstance = $uibModal.open
        animation: true,
        component: 'permitBatchCreate'
        size: 'lg',

    $scope.reservePermit = ->
      modalInstance = $uibModal.open
        animation: true,
        component: 'permitReserve'
        size: 'lg'

    $scope.newTask = ->
      modalInstance = $uibModal.open
        animation: true,
        component: 'taskModal'
        size: 'lg',
        resolve:
          task: -> new Task

      modalInstance.result.then ->
        $state.go('app.tasks', {}, { reload: 'app.tasks' })

    $scope.newTrip = ->
      modalInstance = $uibModal.open
        animation: true,
        component: 'tripModal'
        size: 'lg',
        resolve:
          trip: -> new TripNew({
            organization_id: $scope.main_office.id,
            user_id: $rootScope.user.id,
            download_at: new Date(),
            payment_method: 'cashless',
            download_locations_attributes: [],
            delivery_locations_attributes: [],
            attachment_attributes: [],
            crews_attributes: [],
          })

      modalInstance.result.then (created_trip) ->
        $state.go('app.trips.show', {id: created_trip.id})

    $scope.newClient = ->
      modalInstance = $uibModal.open
        animation: true,
        component: 'clientModal'
        size: 'lg',
        resolve:
          client: -> new ClientNew phones: ['']

      modalInstance.result.then ->
        $state.go('app.clients', {}, { reload: 'app.clients' })

    $scope.newPayment = ->
      modalInstance = $uibModal.open
        animation: true,
        component: 'paymentModal'
        size: 'md',
        resolve:
          payment: -> new PaymentNew({paid: true, at: new Date()})
          trip: -> null

      modalInstance.result.then ->
        $state.go('app.payments', {}, { reload: 'app.payments' })

    $scope.newPurse = ->
      modalInstance = $uibModal.open
        animation: true,
        component: 'purseModal'
        size: 'sm',
        resolve:
          purse: -> new Purse()

      modalInstance.result.then ->
        $state.go('app.payments', {}, { reload: 'app.payments' })

    $scope.newTrailer = ->
      modalInstance = $uibModal.open
        animation: true,
        component: 'trailerModal'
        size: 'lg',
        resolve:
          trailer: -> new Trailer

      modalInstance.result.then ->
        $state.go('app.trailers', {}, { reload: 'app.trailers' })

    $scope.newMaintenanceMaterial = ->
      modalInstance = $uibModal.open
        animation: true,
        component: 'maintenanceMaterialModal'
        size: 'lg',
        resolve:
          material: -> new MaintenanceMaterial(cost_currency: 'UAH', unit: 'шт')

      modalInstance.result.then ->
        $state.go('app.maintenance.materials', {}, { reload: 'app.maintenance.materials' })




    null
