angular.module 'logisticsApp'
  .directive 'googleplaces', ->
    link = (scope, element, attrs, model) ->
      options =
        types: []

      scope.gPlace = new google.maps.places.Autocomplete(element[0], options)

      google.maps.event.addListener scope.gPlace, 'place_changed', ->
        scope.$apply ->
          model.$setViewValue(element.val())
        
    directive =
      require: 'ngModel',
      link: link
