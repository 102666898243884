angular.module 'logisticsApp'
  .controller 'CKTController', ($scope, $filter, Stat, Vehicle) ->
    'ngInject'

    $scope.items = []
    $scope.query = ''
    $scope.end_at = moment().toDate()
    $scope.start_at = moment([moment().year(), moment().month()]).toDate()
    $scope.status =
      opened1: false
      opened2: false

    Vehicle.query().then (items) ->
      $scope.items = $scope.items.concat(items)
      $scope.item = $filter('orderBy')($scope.items, 'title')[0]
      $scope.selectItem($scope.item) if $scope.item

    $scope.selectItem = (item) ->
      angular.forEach $scope.items, (item) ->
        item.selected = false

      $scope.load(item)

    $scope.load = (item) ->
      Stat.query(
        vehicle_id: item.id,
        start_at: moment($scope.start_at).format('DD/MM/YYYY'),
        end_at: moment($scope.end_at).format('DD/MM/YYYY')
      ).then (resp) ->
        $scope.item = item
        $scope.item.selected = true

        $scope.stats = resp.data
        $scope.summary = resp.summary

